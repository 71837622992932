import React, { useEffect, useState } from 'react'
import '../History/DataTableDemo.css';
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';

const axios = require("axios");



const API_URL = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
const UPLOAD_ENDPOINT = "upload";
function Blog() {


    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [blog, setBlog] = useState([]);
    const [get_blog, getBlog] = useState([]);
    const [description, setdescription] = useState("");
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/blog';

    function uploadAdapter(loader) {
        return {
            upload: async () => {
                const api_header = await Database.Headers();
                // console.log('header = ', api_header);
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("image", file);
                        body.append("image_name", file.name);
                        axios
                            .post(API_URL + "/" + UPLOAD_ENDPOINT,
                                body,
                                {
                                    headers: api_header,
                                }
                            )
                            // .then((res) => res.json())
                            .then((res) => {
                                setdescription(...description, "<img src=" + res.data.data.Location + ">");
                                // resolve({
                                //     default: `${API_URL}/${res.url}`,
                                //     // default: `Hello`,
                                // });
                            })
                    });
                });
            }
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    // get blog data
    const fetchBlogData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setBlog(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }

    }

    // delete
    const deleteBlog = async (id) => {

        enqueueSnackbar(t('delete_comfirmation'), {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(id); closeSnackbar(key); }}>
                        {t('delete')}
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        {t('dismiss')}
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (id) => {
        const response = await Database.delete(apiRoute + '/', id);
        if (response.status) {
            enqueueSnackbar(response.message, { variant: 'success' });
            fetchBlogData();
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchBlogData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchBlogData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchBlogData(perPage, 1, val);
    }
    const setPermissoin = async () => {
        var page_permission = await get_permission('blog');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        fetchBlogData(perPage, page, search);
    }, [])

    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        getBlog({ ...get_blog, [name]: value });
    }


    const datapost = async (e) => {

        e.preventDefault();
        if (get_blog.length < 5 || get_blog.title.trim() == "") {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }
        if (get_blog.length < 5 || get_blog.subtitle.trim() == "") {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }

        if (description.length < 5 || description.trim() == "") {
            return enqueueSnackbar(t('desc_must_be_five'), { variant: 'error' });
        }
        var language = get_blog.language ?? 'english';
        const data = { id: get_blog.id, title: get_blog.title, subtitle: get_blog.subtitle, description: description, language:language };
        if (get_blog.id) {
            const response = await Database.update(apiRoute + '/' + get_blog.id, data);
            if (response.status) {
                fetchBlogData();
                enqueueSnackbar(response.message, { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        else {
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                fetchBlogData();
                enqueueSnackbar(response.message, { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.title,
            sortable: true
        },
        {
            name: t('language'),
            selector: row => row.language ?? 'English',
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {

                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteBlog(row._id)}><i className='bi bi-trash'></i></button> : ''
                    }
                    {

                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { getBlog({ id: row._id, description: row.description, title: row.title, subtitle: row.subtitle, language:row.language }); handleShow(); setdescription(row.description); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i className='bi bi-pencil'></i>
                        </button> : ''
                    }

                </>
        },
    ];

    // Remove action while user don`t have any permissions
    if (!permission.edit && !permission.delete) { columns.pop(columns.length - 1); }

    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('blog')}</h3>

                    {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className='btn btn-dark float-start' onClick={() => { getBlog([]); handleShow(); setdescription("") }}>{t('add')}</button>
                        </div> : ''
                    }
                </div>

                {
                    Loading ? <Loader /> :
                        <>
                            {/* {RoundLoading ? <RoundLoader /> : ''} */}
                            <Datatable columns={columns} data={blog} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch}  RoundLoading={RoundLoading} />
                        </>
                }

            </div>
            <Modal show={show} fullscreen="xxl-down">
                <Modal.Header className='bg-danger text-light custom_close_btn_header' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t('add')} {t('blog')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>
                        <div className='form-group mb-3'>
                            <input type="text" name="title" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('title')} onChange={inputHandle} value={get_blog.title} />
                            <span className='invalid-feedback'>{t('title')}</span>
                        </div>

                        <div className='form-group mb-3'>
                            <input type="text" name="subtitle" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('subtitle')} onChange={inputHandle} value={get_blog.subtitle} />
                            <span className='invalid-feedback'>{t('suntitle')}</span>
                        </div>

                        <div className='form-group mb-3'>
                            <select name="language" className='form-control mt-3 border-0 rounded-0 border-bottom' onChange={inputHandle} value={get_blog.language}>
                                <option value='English'>English</option>
                                <option value='Arabic'>Arabic</option>
                            </select>
                            <span className='invalid-feedback'>{t('Language')}</span>
                        </div>


                        <CKEditor
                            config={{
                                extraPlugins: [uploadPlugin]
                            }}
                            editor={ClassicEditor}
                            data={get_blog.description}


                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setdescription(data);
                            }}
                        />
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default Blog