import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import { Link } from 'react-router-dom';
import Database from '../../Database/Index'
import 'react-select-search/style.css'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';


function Index() {
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const { t } = useTranslation()
    const [teamData, setTeamData] = useState([]);
    const [diwaniyaData, setDiwaniyaData] = useState([]);
    const [team, setTeam] = useState({ name: '', diwaniyaid: '', id: '' });
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchDiwaniya, setSearchDiwaniya] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;

    // Database Columns
    // console.log(teamData);

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true
        },
        {
            name: t('diwaniya'),
            selector: row => row.diwaniyas ? row.diwaniyas.name : '',
            sortable: true
        },
        // {
        //     name: 'Diwaniya',
        //     selector: row => row.diwaniya ? row.diwaniya.name : 'Diwaniya Not Found',
        //     sortable: true
        // },
        {
            name: t('players'),
            selector: row => row.players.length,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {
                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteTeam(row._id)}>
                            <i className='bi bi-trash'></i>
                        </button> : ''
                    }

                    {
                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { setTeam({ name: row.name, id: row._id }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i className='bi bi-pencil'></i>
                        </button> : ''
                    }

                    <Link to={`/admin/teams/players/${row._id}`} className='btn btn-primary btn-sm mx-1'><i className='bi bi-eye'></i></Link>

                    {
                        permission.add ? <Link to={`/admin/teams/add/${row._id}&-_${row.diwaniya_id}`} className='btn btn-dark btn-sm mx-1'><i className='bi bi-person'></i></Link> : ''
                    }
                </>
        },
    ];
    // Database Data

    const fetchTeamData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '/team?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setTeamData(response.data);
            console.log(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }
    }

    const fetchDiwaniyaData = async () => {
        const response = await Database.get(apiRoute + '/diwaniya');
        if (response.status) {
            setDiwaniyaData(response.data);
            var searchData = [];
            response.data.forEach((element, index) => {
                searchData.push({ label: element.name, id: element._id });
            })
            setSearchOptions(searchData);
        }
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('teams');
        setPermission(page_permission);
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchTeamData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchTeamData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchTeamData(perPage, 1, val);
    }

    useEffect(() => {
        setPermissoin();
        fetchTeamData();
        fetchDiwaniyaData();
    }, []);


    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setTeam({ ...team, [name]: value });
    }

    const datapost = async (e) => {
        // console.log(team.id);
        e.preventDefault();
        const name = team.name;
        if (name.length < 5) {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }
        if (searchDiwaniya == null) {
            return enqueueSnackbar(t('select_diwaniya'), { variant: 'error' });
        }

        const diwaniyaId = searchDiwaniya.id;
        if (team.id == '') {
            const data = { name: name, diwaniya_id: diwaniyaId };
            const response = await Database.add(apiRoute + '/team', data);
            if (response.status) {
                fetchTeamData();
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            }
        }
        else {
            const data = { name: name };
            const response = await Database.update(apiRoute + '/team/' + team.id, data);
            if (response.status) {
                fetchTeamData();
                enqueueSnackbar(t('update_success'), { variant: 'success' });
                handleClose();
            }
        }


    }

    const dianiyaSelect = (e) => {
        setSearchDiwaniya(e);
    }

    const deleteTeam = async (_id) => {
        swal({
            title: t('delete_comfirmation'),
            text: t('delete_text'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const response = await Database.delete(apiRoute + '/team/', _id);
                if (response.status) {
                    fetchTeamData();
                    enqueueSnackbar(t('delete_success'), { variant: 'success' });
                } else {
                    swal({
                        title: "Error!",
                        text: response.message,
                        icon: "error",
                    });
                }
                swal(t('delete_success'), {
                    icon: "success",
                });
            }
        })
    }
    return (
        <>
            <div className='container bg-white pb-8 my-3 p-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i>{t('teams')}</h3>
                    {/* {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" onClick={() => {
                                setTeam({ name: '', id: '' }); setSearchDiwaniya({ label: '', diwaniya_id: '' }); handleShow();
                            }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>Add</button>
                        </div> : ''
                    } */}
                </div>
                {
                    Loading ? <Loader /> :
                        <>
                            {/* {RoundLoading ? <RoundLoader /> : ''} */}
                            <Datatable columns={columns} data={teamData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} filterBy="name" RoundLoading={RoundLoading}/>
                        </>
                }
            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t('edit')} {t('team')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={datapost}>
                        <div className='form-group'>
                            <label>{t('team')} {t('name')}</label>
                            <input type="text" name="name" id='diwaniyaName' className='form-control mt-1 border-0 rounded-0 border-bottom' placeholder={t('name')} onChange={inputHandle} value={team.name} />
                            <span className='invalid-feedback'>Please Enter Team Name</span>
                        </div>
                        {team.id == '' ? <div className='form-group pt-3'>
                            <label className='mb-2'>{t('select_diwaniya')}</label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={searchOptions}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Diwaniya" />}
                                onChange={(e, value) => dianiyaSelect(value)}
                                value={searchDiwaniya}
                            />
                        </div> : ""}

                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
            {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add Team</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={datapost}>
                                <div className='form-group'>
                                    <label>Team Name</label>
                                    <input type="text" name="name" id='diwaniyaName' className='form-control mt-1 border-0 rounded-0 border-bottom' placeholder='Team Name' onChange={inputHandle} value={team.name} />
                                    <span className='invalid-feedback'>Please Enter Team Name</span>
                                </div>
                                <div className='form-group pt-3'>
                                    <label className='mb-2'>Select Diwaniya</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={searchOptions}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Diwaniya" />}
                                        onChange={(e, value) => dianiyaSelect(value)}
                                        value={searchDiwaniya}
                                    />
                                </div>
                                <div className="modal-footer mt-3">
                                    <button type='reset' className='btn btn-danger px-5 py-2 rounded-1 my-3 mx-1'>Reset</button>
                                    <button type='submit' className='btn btn-dark px-5 py-2 rounded-1 my-3 mx-1' data-bs-dismiss="modal">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default Index