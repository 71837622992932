import React from 'react'
import PageHeader from '../../Components/PageHeader'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Details() {

    return (
        <div className='container bg-white pb-8'>
            <PageHeader title="Blog Details" text="" icon="pencil-square" />

            <form>
                <div className='form-group px-3 px-md-5'>
                    <label className='mb-1'>Title</label>
                    <input type="text" className="form-control" />
                </div>
                <div className='form-group px-3 px-md-5 mt-2'>
                    <label className='mb-1'>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data="<p>Hello from CKEditor 5!</p><p>Hello from CKEditor 5!</p><p>Hello from CKEditor 5!</p><p>Hello from CKEditor 5!</p><p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
                <div className='form-group px-3 px-md-5 mt-2'>
                    <label className='mb-1'>Status</label>
                    <select className='form-select'>
                        <option>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <div className='form-group px-3 px-md-5 mt-2'>
                    <button className='btn btn-danger float-end mt-3 mx-1 px-4'>Save</button>
                    <button type='reset' className='btn btn-warning float-end mt-3 mx-1 px-4'>Reset</button>
                </div>
            </form>
            <br />
            <br />
            <br />
        </div>
    )
}

export default Details