import React, { useEffect, useState } from 'react'
import Card from "../Components/Card";
import DataTable from '../Components/Datatable/WithoutPaginationDatatable'
import Database from '../Database/Index';
import { useTranslation } from 'react-i18next'
import Loader from '../Components/Loader';
import moment from 'moment';
import UpdateAuth from '../Components/Update_Authentication';

function Dashboard() {
  UpdateAuth();
  const [Loading, setLoading] = useState(true);
  const [CompleteGameData, SetCompleteGameData] = useState([])
  const [DataLength, setDataLength] = useState([])
  const [totalGamePlayed, setTotalGamePlayed] = useState(0)
  const { t } = useTranslation()

  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
  // for get data
  const callData = async () => {
    const data = { subadmin: "" }
    const CompleteGameResponse = await Database.add(apiRoute + '/game/start/complete?page=1&per_page=10&search=', data);
    if (CompleteGameResponse.status) {

      SetCompleteGameData(CompleteGameResponse.data);
      setTotalGamePlayed(CompleteGameResponse.total_records);
      setLoading(false);
    }
    const response = await Database.get(apiRoute + '/dashboard');
    if (response.status) {
      setDataLength(response.data);
    }
  }


  useEffect(() => {
    // update_auth();
    callData();
  }, [])

  const columns = [
    {
      name: '',
      selector: row => row.index,
      sortable: true
    },
    {
      name: t('diwaniya'),
      selector: row => row.diwaniya ? row.diwaniya.name : '',
      sortable: true
    },
    {
      name: t('game'),
      selector: row => row.game.name,
      sortable: true
    },
    {
      name: t('Winner'),
      selector: row => row.score.winner_name,
      sortable: true
    },
    {
      name: t('date'),
      selector: row => moment.utc(row.updatedAt).format("MMMM DD YYYY"),
      sortable: true
    },
  ];
  return (
    <>

      <div className="container-fluid pt-3 overflow-auto scrollbar-hidden bg-light">
        <div className="row my-2 dashboard_card_box">
          <Card className="col-12 col-md-4 mb-3" term={t('total') + " " + t('diwaniya')} value={DataLength.diwaniya_count} cardColor="bg-dark" back_image="/assets/images/Diwaniya.png" />
          {/* <Card className="col-12 col-md-4 mb-3" term={t('total') + " " + t('users')} value={DataLength.users_count} cardColor="bg-danger" back_image="/assets/images/user.png" /> */}
          <Card className="col-12 col-md-4 mb-3" term={t('total') + " " + t('players')} value={DataLength.player_count} cardColor="bg-warning" back_image="/assets/images/card_hand.png" />
          <Card className="col-12 col-md-4 mb-3" term={t('total') + " " + t('game') + " " + t('played')} value={totalGamePlayed} cardColor="bg-warning" back_image="/assets/images/games.png" />
        </div>
      </div>
      <div className="container-fluid pt-3 overflow-auto scrollbar-hidden bg-light dashboard_datatable_box" style={{ height: "100vh" }}>
        <div className="row my-2">
          <div className="col">
            <div className="card">
              <div className="card-header mb-3">{t('recently') + " " + t('played') + " " + t('games')}</div>
              <div className='p-4'>

                {
                  Loading ? <Loader /> :
                    <DataTable filterBy='game_id' search={false} columns={columns} data={CompleteGameData} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
