import React, { useState } from 'react'
import './DataTableDemo.css';

const data = [
    {
        employeeId: '01',
        name: 12,
        email: 55,
        position: 98,
        position1: 98,
        position2: 98,
        position3: 98,
        round: 'Round 1',
    },
    {
        employeeId: '02',
        name: 32,
        email: 21,
        position: 37,
        position1: 37,
        position2: 37,
        position3: 37,
        round: 'Round 2',

    },
    {
        employeeId: '03',
        name: 10,
        email: 89,
        position: 65,
        position1: 65,
        position2: 65,
        position3: 65,
        round: 'Round 3',
    },
    {
        employeeId: '04',
        name: 10,
        email: 89,
        position: 65,
        position1: 65,
        position2: 65,
        position3: 65,
        round: 'Round 4',
    },
    {
        employeeId: '05',
        name: 10,
        email: 89,
        position: 65,
        position1: 65,
        position2: 65,
        position3: 65,
        round: 'Round 5',
    },


]

const EditableTable = () => {
    const [employeeData, setEmployeeData] = useState(data)
    const [editTable, setEditTable] = useState(true)

    const onChangeInput = (e, employeeId) => {
        const { name, value } = e.target

        const editData = employeeData.map((item) =>
            item.employeeId === employeeId && name ? { ...item, [name]: value } : item
        )

        setEmployeeData(editData)
        // console.log(editData)
    }

    return (
        <>
            <div className='container bg-white pb-8 my-3 p-3'>
                <div className='header py-1 pb-2 position-relative'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i>Game Round</h3>
                    <br />
                    <h5 className={`text-dark`}>Kout Game - (Test Diwaniya)</h5>
                </div>
                <div className="row">
                    <div className="col-2">
                        <table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Round</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {employeeData.map(({ employeeId, round, }) => (
                                        <tr key={employeeId}>
                                            <td className='text-center p-2 roundColor'>
                                                <input
                                                    disabled
                                                    className=''
                                                    name="name"
                                                    value={round}
                                                    type="text"
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Round"
                                                />
                                            </td>

                                        </tr>
                                    ))}
                                    <br />
                                </>
                                <tr className=' mt-3'>
                                    <th className='mt-2 bg-dark text-center'>
                                        Total
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-10">
                        <table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Player 1</th>
                                    <th className='text-center'>Player 2</th>
                                    <th className='text-center'>Player 3</th>
                                    <th className='text-center'>Player 4</th>
                                    <th className='text-center'>Player 5</th>
                                    <th className='text-center'>Player 6</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {employeeData.map(({ employeeId, name, email, position, position1, position2, position3 }) => (
                                        <tr key={employeeId}>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="name"
                                                    value={name}
                                                    type="number"
                                                    max="4"
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="email"
                                                    value={email}
                                                    type="number"
                                                    max="4"
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="position"
                                                    type="number"
                                                    max="4"
                                                    value={position}
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="position1"
                                                    type="number"
                                                    max="4"
                                                    value={position1}
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="position2"
                                                    type="number"
                                                    max="4"
                                                    value={position2}
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                            <td className='p-2'>
                                                <input
                                                    disabled={editTable ? true : false}
                                                    name="position3"
                                                    type="number"
                                                    max="4"
                                                    value={position3}
                                                    onChange={(e) => onChangeInput(e, employeeId)}
                                                    placeholder="Score"
                                                    className='text-center'
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    <br />
                                </>
                                <tr className='bg-dark text-light mt-3'>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                    <th className='mt-2 bg-dark border-white text-center'>100</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div >
            <div className='px-4'>
                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : setEditTable(true) }} data-bs-target="#staticBackdrop" className='btn btn-danger float-end ms-3'>Cancel</button>
            </div>
            <div className='px-4'>
                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : setEditTable(true) }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>{editTable ? "Edit" : " Save"}</button>
            </div>

        </>

    )
}

export default EditableTable