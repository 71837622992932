import React, { useEffect, useState } from 'react'
import Database from '../Database/Index';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PageHeader from '../Components/PageHeader'
import get_permission from '../Components/Permission';


function Terms() {
  var parse = require('html-react-parser');

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [terms, setTerms] = useState([]);
  const [getTerms, setGetTerms] = useState([]);
  const [htmlContent, sethtmlContent] = useState("");
  const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

  const { t } = useTranslation()
  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/term';
  //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
  // get terms data
  const fetchFaqData = async () => {
    const response = await Database.get(apiRoute);
    if (response.status) {
      setTerms(response.data);
      console.log(response.data);
    }

  }

  const setPermissoin = async () => {
    var page_permission = await get_permission('terms');
    setPermission(page_permission);
  }

  useEffect(() => {
    setPermissoin();
    fetchFaqData();
  }, [])

  const inputHandle = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setGetTerms({ ...getTerms, [name]: value });
  }


  const datapost = async (e) => {

    e.preventDefault();
    const data = { id: getTerms.id, title: getTerms.title, terms: htmlContent };
    if (getTerms.id) {
      const response = await Database.put(apiRoute + '/' + getTerms.id, data);
      if (response.status) {
        fetchFaqData();
        enqueueSnackbar(response.message, { variant: 'success' });
        handleClose();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    else {
      const response = await Database.add(apiRoute + '/', data);
      if (response.status) {
        fetchFaqData();
        enqueueSnackbar(response.message, { variant: 'success' });
        handleClose();
      }
      else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
  }
  const TermsData = terms.map((terms, index) =>
    <>



      <div className="float-end mt-3">

        {
          permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { setGetTerms({ id: terms._id, terms: terms.terms }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button> : ''
        }
      </div>
      <div className="">{parse(terms.terms)}
      </div>


    </>
  );
  return (
    <>
      <div className='container bg-white pb-8'>
        <PageHeader title={t('terms')} text="" icon="file-earmark-text" />
        <div className=''>
          {TermsData}
        </div>
      </div>
      <Modal show={show} fullscreen="xxl-down">
        <Modal.Header closeButton onClick={() => { handleClose(); }}>
          <Modal.Title>{t('terms')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className='form' method='post' onSubmit={datapost}>



            <CKEditor
              editor={ClassicEditor}
              data={getTerms.terms}

              onChange={(event, editor) => {
                const data = editor.getData();
                sethtmlContent(data);
              }}
            />
            <div className="modal-footer border-0">
              <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
              <button type="submit" data-bs-dismiss="modal1" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
            </div>
          </form>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default Terms