import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import Datatable from '../../Components/Datatable/WithoutPaginationDatatable';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from '../../Components/Loader';


function Index() {
    const [show, setShow] = useState(false);
    const [teamIsChecked, setTeamIsChecked] = useState(false);
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [individualIsChecked, setIndividualIsChecked] = useState(false);
    const [TypeInJson, setTypeInJson] = useState([]);
    const [PlayerData, setPlayerData] = useState([]);
    const [Playertwo, setPlayertwo] = useState(false);
    const [Playerthree, setPlayerthree] = useState(false);
    // const [nullVal, setnull] = useState(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [diwaniya, setDiwaniya] = useState({ name: '', score_round: '', max_player: '', max_team: '', content: '', winning_type: '', id: '' });
    const [Type, setType] = useState({ team: '', individual: '' });
    const [Game, setGame] = useState([]);
    const [winning, setwinning] = useState("score");
    const [content, setcontent] = useState("");
    const [Loading, setLoading] = useState(true);
    const [keypress, setKeyPress] = useState(true);
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/game';

    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            setGame(response.data);
            setLoading(false);
        }
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('game');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setDiwaniya({ ...diwaniya, [name]: value });
    }

    const reset_winnings = (e) => {
        setDiwaniya({ ...diwaniya, score_round: '' });
    }

    const handleOnChange = (val) => {
        var input_value = val.target.value;
        if (input_value == 'team' || input_value == 'individual') {
            if (TypeInJson.includes(input_value)) {
                const index = TypeInJson.indexOf(input_value);
                if (index > -1) {
                    TypeInJson.splice(index, 1);
                }
                if (input_value == 'team') {
                    setDiwaniya({ ...diwaniya, max_team: '2' });
                    setTeamIsChecked(false);
                    setPlayertwo(true);
                    setPlayerthree(false);
                    setPlayerData(["2"]);
                } else {
                    setDiwaniya({ ...diwaniya, max_player: '2' });
                    setIndividualIsChecked(false);
                }
            } else {
                TypeInJson.push(input_value);
                if (input_value == 'team') {
                    setDiwaniya({ ...diwaniya, max_team: '2' });
                    setTeamIsChecked(true);
                    setPlayertwo(true);
                    setPlayerthree(false);
                    setPlayerData(["2"]);
                } else {
                    setDiwaniya({ ...diwaniya, max_player: '2' });
                    setIndividualIsChecked(true);
                }

            }
        }
        // var type = TypeInJson.toString();
        setTypeInJson(TypeInJson);

    }
    const datapost = async (e) => {
        e.preventDefault();
        setspinnerIsActive(true);
        var type = TypeInJson.toString();
        if (diwaniya.name.length < 3 || diwaniya.name.trim() == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('name_must_be_three'), { variant: 'error' });
        }
        if (type == '') {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('select_game_type'), { variant: 'error' });
        } else {
            if (!Playertwo && !Playerthree && teamIsChecked) {
                setspinnerIsActive(false);
                return enqueueSnackbar(t('select_max_team'), { variant: 'error' });
            }
        }

        if (diwaniya.score_round == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('winning_roud_empty'), { variant: 'error' });
        }
        if (content == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('game_content_empty'), { variant: 'error' });
        }

        var max_team = diwaniya.max_team;
        if (max_team == '') {
            max_team = '0';
        }
        const data = { id: diwaniya.id, name: diwaniya.name, type: type, score_round: diwaniya.score_round, max_player: diwaniya.max_player, max_team: max_team, winning_type: diwaniya.winning_type, content: content };
        if (diwaniya.id) {
            const response = await Database.update(apiRoute + '/' + diwaniya.id, data);
            if (response.status) {
                callData();
                enqueueSnackbar(t('update_success'), { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        else {
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                callData();
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        setspinnerIsActive(false);

    }



    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('game'),
            selector: row => row.name,
            sortable: true
        },
        {
            name: t('Type'),
            selector: row => row.type,
            sortable: true
        },
        {
            name: t('max_win_score'),
            selector: row => row.score_round,
            sortable: true
        },
        {
            name: t('max_player'),
            selector: row => row.max_player,
            sortable: true
        },
        {
            name: t('max_team'),
            selector: row => row.max_team,
            sortable: true
        },
        {
            name: t('winning_type'),
            selector: row => row.winning_type,
            sortable: true
        },

        {
            name: t('action'),
            selector: row =>
                <>
                    {/* <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteDiwaniya(row.id)}><i className='bi bi-trash'></i></button> */}
                    <button className='btn btn-warning btn-sm mx-1' onClick={() => { setDiwaniya({ name: row.name, type: row.type, score_round: row.score_round, max_team: row.max_team, max_player: row.max_player, content: row.content, winning_type: row.winning_type, id: row._id }); handleShow(); SetCheckBox(row.type); setPlayerBox(row.max_team); setcontent(row.content); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button>
                </>
        },
    ];

    // Remove action while user don`t have any permissions
    if (!permission.edit) { columns.pop(columns.length - 1); }

    const Game_player_type = (event) => {
        var selected_value = event.target.value;
        var is_checked = event.target.checked;
        var team_type = diwaniya.max_team;
        team_type = team_type.toString();
        var data_array = PlayerData;
        // Remove 0
        const zindex = data_array.indexOf("0");
        if (zindex != -1) {
            data_array.splice(zindex, 1);
        }

        const index = data_array.indexOf(selected_value);
        if (index == -1 && is_checked) {
            data_array.push(selected_value);
        } else {
            data_array.splice(index, 1);
        }
        if (data_array.length > 0) {
            data_array.sort();
            team_type = data_array.toString();
        } else {
            team_type = "";
            // data_array.push([]);
        }
        setDiwaniya({ ...diwaniya, max_team: team_type });
        setPlayerData(data_array);

        if (data_array.includes("2")) {
            setPlayertwo(true);
        } else {
            setPlayertwo(false);
        }

        if (data_array.includes("3")) {
            setPlayerthree(true);
        } else {
            setPlayerthree(false);
        }
    }

    const setPlayerBox = (val) => {
        var team_type = val;
        team_type = team_type.toString();
        var data_array = team_type.trim().split(",");

        if (data_array.includes("2")) {
            setPlayertwo(true);
        } else {
            setPlayertwo(false);
        }

        if (data_array.includes("3")) {
            setPlayerthree(true);
        } else {
            setPlayerthree(false);
        }

        setPlayerData(data_array);
        // const index = data_array.indexOf(selected_value);

    }

    const winning_field = (event) => {
        if (!keypress && event.keyCode != 8) {
            event.preventDefault();
        }
        setKeyPress(false);
    }

    const cardNum = (event) => {
        setKeyPress(true);
        var sanitizedValue = event.target.value.replace(/[^0-9]/gi, '');
        var length = winning == 'score' ? 3 : 1;
        var value = event.target.value;
        value = value.split(",");
        var last_value = value[value.length - 1].trim();
        if (last_value <= 0) {
            value.splice(value.length - 1, 1);
            sanitizedValue = value.toString().replace(/[^0-9]/gi, '');
            last_value = '';
        }
        if (event.keyCode == 32 && length == 3 || event.type == 'blur' && length == 3) {
            if (last_value.length < 3 && last_value.length != '') {
                var convalue = '';
                for (let index = 0; index < 3 - last_value.length; index++) {
                    convalue += '0';
                }
                last_value = convalue + last_value;
                value[value.length - 1] = last_value
                sanitizedValue = value.toString().replace(/[^0-9]/gi, '');
            }
        }

        var parts = [];
        for (var i = 0, len = sanitizedValue.length; i < len; i += length) {
            parts.push(sanitizedValue.substring(i, i + length));
        }
        event.target.value = parts;
        setDiwaniya({ ...diwaniya, score_round: event.target.value });
    }

    const MaxPlayerKeyDown = (event) => {
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) && event.keyCode != 8) {
            event.preventDefault();
        }
    }
    const max_player = (event) => {
        setTimeout(() => {
            var value = 0;
            var player_value = event.target.value.replace(/[^0-9]/gi, '');
            if (player_value >= 2 && player_value <= 6 || player_value == "") {
                return true
            } else {
                if (player_value < 2) {
                    value = 2;
                }
                if (player_value > 6) {
                    value = 6;
                }
                enqueueSnackbar(t('player_between_two_six'), { variant: 'error' });
            }

            event.target.value = value;
            setDiwaniya({ ...diwaniya, max_player: value });
        }, 1000)
    }

    const max_player_blur = (event) => {
        var player_value = event.target.value.replace(/[^0-9]/gi, '');
        if (player_value == "") {
            setDiwaniya({ ...diwaniya, max_player: 2 });
        }
    }

    const SetCheckBox = (val) => {
        var setjsonvalue = [];
        if (val.search("team") != -1) {
            setTeamIsChecked(true);
            setjsonvalue.push('team');
        } else {
            setTeamIsChecked(false);
        }

        if (val.search("individual") != -1) {
            setIndividualIsChecked(true);
            setjsonvalue.push('individual');
        } else {
            setIndividualIsChecked(false);
        }
        setTypeInJson(setjsonvalue);

        // set player check
        var team_type = diwaniya.max_team;
        team_type = team_type.toString();
        var data_array = team_type.trim().split(",");
        setPlayerData(data_array);
    }

    // const cardNumround = (event) => {
    //     var tags = [];
    //     if (event.keyCode == 32) {
    //         let tag = event.target.value.replace(/\s+/g, ',');
    //         tag.split(',').forEach(tag => {
    //             tags.push(tag);
    //             event.target.value = tags;
    //         });

    //     }


    // }

    return (
        <>
            <div className='container bg-white p-3 mt-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <img src="/assets/images/Diwaniya.png" alt='' className='w-40 bg-warning p-1 mx-3' />{t('game')}</h3>
                    {
                        permission.add && false ? <div className='px-3'>
                            <button data-bs-toggle="modal" onClick={() => {
                                setDiwaniya({ name: '', score_round: '', max_player: '0', max_team: '2', winning_type: 'low', content: '', type: '' });

                                handleShow();
                                setTeamIsChecked(true);
                                setPlayertwo(true);
                                setPlayerthree(false);
                                setPlayerData(["2"]);
                                setIndividualIsChecked(false);
                                setTypeInJson(['team']);

                            }} data-bs-target="#staticBackdrop" className='btn btn-dark'>{t('add')}</button>

                        </div> : ''
                    }
                </div>


                {Loading ? <Loader /> : <Datatable search={false} filterBy="name" columns={columns} data={Game} />}
            </div>
            {/* modal */}
            <Modal show={show} fullscreen="xxl-down">
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title className='ps-4'>{t('game')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form p-4' method='post' id='diwniyaForm' onSubmit={datapost} encType="multipart/form-data" >
                        <div className='form-group'>
                            <label className='mt-4'>{t('game')} {t('name')}</label>
                            <input type="text" name="name" id='diwaniyaName' className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('name')} onChange={inputHandle} value={diwaniya.name} />
                            <span className='invalid-feedback'>Please Enter Game Name</span>
                        </div>

                        <div className='row'>
                            <label className='mt-3 mb-2'>{t('game')} {t('type')}</label>
                            <div className='col-md-4'>
                                <div className="form-check">
                                    <input className="form-check-input" checked={teamIsChecked} onChange={(e) => { handleOnChange(e); }} value="team" type="checkbox" id="flexRadioDefault1" />
                                    <label className="form-check-label"> {t('team')} </label>
                                </div>

                                {teamIsChecked ?
                                    <>
                                        <div className='mt-2'>
                                            <label className="form-check-label me-2">{t('max_team')}  : </label>
                                            <input className="form-check-input" checked={Playertwo} onChange={(e) => { Game_player_type(e); }} type="checkbox" value='2' name='two_players' />
                                            <label className="form-check-label ms-1 me-3"> {t('2 Player')} </label>

                                            <input className="form-check-input" checked={Playerthree} onChange={(e) => { Game_player_type(e); }} type="checkbox" value='3' name='three_players' />
                                            <label className="form-check-label ms-1 me-3"> {t('3 Player')} </label>
                                        </div>
                                    </>
                                    : ""}
                            </div>

                            <div className='col-md-6'>
                                <div className="form-check">
                                    <input className="form-check-input" checked={individualIsChecked} onChange={(e) => { handleOnChange(e); }} type="checkbox" value="individual" id="flexRadioDefault2" />
                                    <label className="form-check-label">
                                        {t('Individual')}
                                    </label>
                                </div>

                                {individualIsChecked ?
                                    <>
                                        <div className='mt-2'>
                                            <label className="form-check-label me-2">{t('max_player')} : </label>
                                            <input onChange={inputHandle} onKeyDown={(e) => (MaxPlayerKeyDown(e))} value={diwaniya.max_player} type="text" onBlur={(e) => (max_player_blur(e))} onKeyUp={(e) => (max_player(e))} name="max_player" className='form-control  w-50 d-inline' placeholder={t('max_player')} id="" />
                                        </div>
                                    </>
                                    : ""}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <label className='mt-4'>{t('winning_type_score')}</label>
                                <div className="form-check">
                                    <input className="form-check-input" checked={diwaniya.winning_type == "low" ? true : false} onChange={inputHandle} value="low" type="radio" name="winning_type" id="flexRadioDefault1" />
                                    <label className="form-check-label">
                                        {t('low')}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" checked={diwaniya.winning_type == "high" ? true : false} onChange={inputHandle} type="radio" value="high" name="winning_type" id="flexRadioDefault2" />
                                    <label className="form-check-label">
                                        {t('high')}
                                    </label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <label className='mt-4'>{t('game_winning_round')}</label>
                                <select className="form-select mt-2" aria-label="Default select example" value={winning} name='' onChange={(e) => { setwinning(e.target.value); reset_winnings(); }}>
                                    <option value="score">{t('max_win_score')}</option>
                                    <option value="round">{t('max_rounds')}</option>
                                </select>

                                <div className='form-group mt-2'>
                                    <input onChange={inputHandle} value={diwaniya.score_round} onKeyDown={(e) => (winning_field(e))} onBlur={(e) => (cardNum(e))} onKeyUp={(e) => (cardNum(e))} type="text" name="score_round" className='form-control' placeholder={`00,00 Max winning ${winning}`} size="18" id="" />
                                    <span className='invalid-feedback'>{t('score')}</span>
                                </div>
                            </div>
                        </div>



                        <div className='form-group mt-2'>
                            <label className='mt-4'>{t('game_content')}</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={diwaniya.content}
                                // onReady={editor => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log('Editor is ready to use!', editor);
                                // }}

                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setcontent(data);
                                }}
                            // onBlur={(event, editor) => {
                            //     console.log('Blur.', editor);
                            // }}
                            // onFocus={(event, editor) => {
                            //     console.log('Focus.', editor);
                            // }}
                            />

                        </div>




                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" className="btn btn-danger rounded-0 px-4">
                                {spinnerIsActive ?
                                    <><i className="spinner-border custom-spinner" role="status"></i>
                                    </>
                                    : ""} {t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Index