import React, { useEffect, useState } from 'react'
import Database from '../../../Database/Index';
import DataTable from '../../../Components/Datatable/WDatatable';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
const columns = [
    {
        name: 'Username',
        selector: row => row.username.replace(' ', '_'),
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Phonenumber',
        selector: row => row.phonenumber,
        sortable: true,
    },
    {
        name: 'Address',
        selector: row => row.address,
        sortable: true,
    },
];

function Players() {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [diwaniya, setDiwaniya] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const callData = async () => {
        const _id = window.location.href.split('remove/')[1];
        const response = await Database.get('/team/players/', _id);
        if (response.status) {
            setDiwaniya(response.data[0]);
            if (response.data[0].players) {
                setUsers(response.data[0].players);
            }
        }
    }

    useEffect(() => {
        callData();
    }, [])

    // Datatble functions
    const selectUSerData = (e) => {
        setSelectedUsers(e.selectedRows);
    }

    const removePlayers = async () => {

        var playersid = [];
        var selectedId = [];

        selectedUsers.forEach((element, index) => {
            selectedId.push(element._id);
        })


        diwaniya.playersid.forEach((element, index) => {
            if (!selectedId.includes(element)) {
                playersid.push({ _id: element });
            }
        })

        const _id = window.location.href.split('remove/')[1];
        const data = { _id, playersid };
        const response = await Database.add('/team/players', data);
        if (response.status) {
            callData();
            enqueueSnackbar("Players Remove Successfully", { variant: 'success' });
            navigate(-1);
        } else {
            enqueueSnackbar(response.error, { variant: 'error' });
        }
    }

    return (
        <>
            <div className='p-3 m-3 bg-white pb-5'>
                <h4><Link to="/admin/diwaniya">{diwaniya ? diwaniya.name : ''}</Link> / players</h4>
                <DataTable
                    title="Select User To Remove From Team"
                    columns={columns}
                    data={users}
                    onSelect={selectUSerData}
                />
                {
                    selectedUsers.length ? (
                        <button onClick={removePlayers} className='btn btn-danger rounded-0 float-end my-3'>Remove Player</button>
                    ) : ('')
                }
                <br />
            </div>
        </>
    )
}

export default Players