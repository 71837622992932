const headers = {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('token'),
    'language': localStorage.getItem('lang')
};
const Index = {
    get(url, id = '') {
        // console.log(JSON.parse(localStorage.getItem('token')).token);
        const data = fetch(url + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
                'language': localStorage.getItem('lang')
            },
        }).then((res) => res.json()).then((response) => {
            if (response.status) {
                if (response.data.length) {
                    response.data.forEach((element, index) => {
                        element.index = index + 1;
                    });
                }
                return response;
            }
            else {
                return response;
            }
        })

        return data;
    },
    updateAuth(url, id = '') {
        // console.log(JSON.parse(localStorage.getItem('token')).token);
        const data = fetch(url + id, {
            method: "GET",
            headers: headers,
        }).then((res) => res.json()).then((response) => {
            if (response.status) {
                // if (response.data.length) {
                //     response.data.forEach((element, index) => {
                //         element.index = index + 1;
                //     });
                // }
                return response;
            }
            else {
                return response;
            }
        })

        return data;
    },

    delete(url, id = '') {
        const data = fetch(url + id, {
            method: "DELETE",
            headers: headers,
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },

    add(url, bodyData) {
        const data = fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
                'language': localStorage.getItem('lang')
            }, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },
    put(url, bodyData) {
        const data = fetch(url, {
            method: "PUT",
            headers: headers, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },

    update(url, bodyData, token = '') {
        if (token != '') {
            headers.Authorization = token;
        }
        const data = fetch(url, {
            method: "PUT",
            headers: headers, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },

    login(url, bodyData) {
        const data = fetch(url, {
            method: "POST",
            headers: headers, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },


    otpVerify(url, bodyData) {
        const data = fetch(url, {
            method: "POST",
            headers: headers, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },

    resend(url, bodyData) {
        const data = fetch(url, {
            method: "POST",
            headers: headers, body: JSON.stringify(bodyData)
        }).then((res) => res.json()).then((response) => {
            return response;
        })
        return data;
    },

    Headers() {
        return headers;
    },

}

export default Index