import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from '../Layout/Index'
import Home from '../Pages/Index'
import Privacy from '../Pages/Privacy'
import CardLink from '../Pages/CardLink'
import Dashboard from '../Pages/Dashboard'
import Profile from '../Pages/Profile'
import Teams from '../Pages/Teams/Index'
import TeamsAdd from '../Pages/Diwaniya/Teams/Add'
import AddTeams from '../Pages/Teams/Add'
import TeamsRemove from '../Pages/Diwaniya/Teams/Remove'
import TeamsPlayers from '../Pages/Diwaniya/Teams/Players'
import Players from '../Pages/Players/Index'
import Notifications from '../Pages/PushNotifications/Index'
import CardOrder from '../Pages/CardOrder/Index'
import CardQR from '../Pages/CardQR/Index'
import Test from '../Pages/Players/test'
import Game from '../Pages/Game/Index'
import Login from '../Pages/Auth/Login'
import Datatable from '../Pages/Example/Datatable'
import Score from '../Pages/History/Score'
import GameHistory from '../Pages/History/Game'
import ReportDeskManagement from '../Pages/ReportDeskManagement/Index'
import ChatBox from '../Pages/ReportDeskManagement/ChatBox'
import GameRound from '../Pages/History/Round'
// Game type pages
import GameKout from '../Pages/History/Kout'
import GeneralScoring from '../Pages/History/general_scoring'
import Spide from '../Pages/History/spide'
import Trix from '../Pages/History/trix'
import HandGame from '../Pages/History/hand'
import PlayerHistory from '../Pages/History/Player'
import Support from '../Pages/Support/Index'
import Diwaniya from '../Pages/Diwaniya/Index'
import DiwaniyaAdd from '../Pages/Diwaniya/AddUser'
import DiwaniyaPlayers from '../Pages/Diwaniya/Players'
import DiwaniyaTeams from '../Pages/Diwaniya/Teams/Index'
import Newsletter from '../Pages/Newsletter'
import Blogs from '../Pages/Blogs/Index'
import Image from '../Pages/Image/Index'
import BlogDetails from '../Pages/Blogs/Details'
import Faq from '../Pages/Faq/Faq'
import Terms from '../Pages/Terms'
import Leaderboard from '../Pages/Leaderboard'
import SubAdmin from '../Pages/Subadmin/index'
import Permission from '../Pages/Subadmin/permission'
import Error404 from "../Pages/errors/Error404";
import TempPlayer from '../Pages/Teams/Players';

// const Domain_backend_access = '52.31.229.126';
const Domain_backend_access = 'admin.aljaiker.com';
// const Current_domain = 'localhost';
const Current_domain = window.location.hostname;

  var protocol = window.location.protocol;
  var href = window.location.href;
  // if(protocol == 'https:') {
  //   let result = href.replace("https", "http");
  //   window.location.href = result;
  // }

function Index() {
  return (

    
      (Domain_backend_access == Current_domain) ?
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/PC/:code' element={<CardLink />} />
          <Route path='/admin' element={<Layout />}>
          </Route>
          {/* admin */}
          <Route path='admin' element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='profile' element={<Profile />} />
            <Route path='teams' element={<Teams />} />
            <Route path='diwaniya/teams/add/:id' element={<TeamsAdd />} />
            <Route path='subadmin/permission/:id' element={<Permission />} />
            <Route path='teams/add/:id' element={<AddTeams />} />
            <Route path='diwaniya/teams/remove/:id' element={<TeamsRemove />} />
            <Route path='diwaniya/teams/players/:id' element={<TeamsPlayers />} />
            <Route path='support' element={<Support />} />
            <Route path='players' element={<Players />} />
            <Route path='push_notification' element={<Notifications />} />
            <Route path='card_order' element={<CardOrder />} />
            <Route path='card_qr' element={<CardQR />} />
            <Route path='test' element={<Test />} />
            <Route path='game' element={<Game />} />
            <Route path='diwaniya' element={<Diwaniya />} />
            <Route path='diwaniya/add/:id' element={<DiwaniyaAdd />} />
            <Route path='diwaniya/players/:id' element={<DiwaniyaPlayers />} />
            <Route path='diwaniya/teams/:id' element={<DiwaniyaTeams />} />
            <Route path='newsletter' element={<Newsletter />} />
            <Route path='blogs' element={<Blogs />} />
            <Route path='image' element={<Image />} />
            <Route path='blogs/id' element={<BlogDetails />} />
            <Route path='faq' element={<Faq />} />
            <Route path='terms' element={<Terms />} />
            <Route path='leaderboard' element={<Leaderboard />} />
            <Route path='subadmin' element={<SubAdmin />} />
            <Route path='report_desk_management' element={<ReportDeskManagement />} />
            <Route path='report_desk_management/chatbox/:id' element={<ChatBox />} />
            <Route path="*" element={<Error404 />} />


            {/*  */}
            <Route path='/admin/teams/players/:id' element={<TempPlayer />} />
          </Route>
          {/* history */}
          <Route path='admin/history' element={<Layout />}>
            <Route path='score' element={<Score />} />
            <Route path='game' element={<GameHistory />} />
            <Route path='game/round' element={<GameRound />} />
            <Route path='game/kout/:id' element={<GameKout />} />
            <Route path='game/general_scoring/:id' element={<GeneralScoring />} />
            <Route path='game/spide/:id' element={<Spide />} />
            <Route path='game/trix/:id' element={<Trix />} />
            <Route path='game/hand/:id' element={<HandGame />} />

            <Route path='player' element={<PlayerHistory />} />
          </Route>

          <Route path='admin/login' element={<Login />} />
          <Route path='example/datatable' element={<Datatable />} />
        </Routes>
      </BrowserRouter>
      : <BrowserRouter>
        <Routes>
          <Route path='/PC/:code' element={<CardLink />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    
  )
}

export default Index