import React, { useEffect, useState, useRef } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Database from '../../Database/Index';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
// import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// //////////////////////////////////////////////////////////////////////////////////////// temp

function Game() {

    const [selectFilter, SetSelectFilter] = useState('username');
    const [GameScore, setGameScore] = useState([]);
    const [DiwaniyaData, setDiwaniyaData] = useState([]);
    const [GameData, setGameData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [Diwaniyaid, setDiwaniyaid] = useState('');
    const [Gameid, setGameid] = useState('');
    const selectDiwaniya = useRef("");
    const selectGame = useRef("");
    const { enqueueSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/game/start/complete';
    const apiRouteGlobal = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    const { t } = useTranslation();

    // for get data
    const callData = async (per_Page = perPage, Page = page, filtersearch = search, diwaniya_id = Diwaniyaid, game_id = Gameid) => {
        setRoundLoading(true);
        // const response = await Database.add(apiRoute + '/', {'subadmin' : ''});
        const response = await Database.add(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch + '&diwaniya_id=' + diwaniya_id + '&game_id=' + game_id, { 'subadmin': '' });
        if (response.status) {
            setRoundLoading(false);
            var count = 0;
            for (let index = 0; index < response.data.length; index++) {
                count++;
                response.data[index].count = count;
            }
            setGameScore(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('game');
        setPermission(page_permission);
    }

    const callDiwaniyaGAme = async () => {
        const diwaniya_response = await Database.get(apiRouteGlobal + '/diwaniya');
        if (diwaniya_response.status) {
            setDiwaniyaData(diwaniya_response.data);
        }
        const game_response = await Database.get(apiRouteGlobal + '/game');
        if (game_response.status) {
            setGameData(game_response.data);
            callData(perPage, page, search);
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        var DiwaniayGameId = getDiwaniayGameId();
        callData(newPerPage, page, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, page, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, 1, val, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id);
    }

    const getDiwaniayGameId = () => {
        var game_id = '';
        var diwaniya_id = '';
        var elems = document.querySelectorAll(".diwaniya_id");
        [].forEach.call(elems, function (el) {
            diwaniya_id = el.value;
        });

        var elems = document.querySelectorAll(".game_id");
        [].forEach.call(elems, function (el) {
            game_id = el.value;
        });
        return { diwaniya_id: diwaniya_id, game_id: game_id };
    }
    // Select Diwaniya/Game filter
    const FilterChange = (val) => {
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, 1, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id);
    }
    useEffect(() => {
        // setPermissoin();
        callDiwaniyaGAme();
        // callData();
    }, [])

    const clearFilters = () => {
        callData(perPage, page, search);
        selectDiwaniya.current.value = "";
        selectGame.current.value = "";
        document.getElementById('search').value = "";
        setSearch("");
    }
    const columns = [
        {
            name: '#',
            selector: row => row.count,
            sortable: true
        },
        {
            name: t('diwaniya'),
            selector: row => row.diwaniya != undefined ? row.diwaniya.name : '-',
            sortable: true
        },
        {
            name: t('game'),
            selector: row => row.game.name,
            sortable: true
        },
        {
            name: t('Winner'),
            selector: row => row.score.winner_name != '' ? row.score.winner_name : '-' ,
            sortable: true
        },
        {
            name: t('date'),
            selector: row => moment.utc(row.createdAt).format("MMMM DD YYYY"),
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {
                        row.score != undefined && row.score.score_data != '{}' && row.score.score_data != '' ?
                            <Link to={`/admin/history/game/` + row.game.name.replace(' ', '_') + `/` + row.score_id} className='btn btn-warning btn-sm mx-1' ><i className='bi bi-eye'></i></Link>
                            :
                            <button className='btn btn-warning btn-sm mx-1' onClick={() => enqueueSnackbar(t('no_score_avaliable_for_this_game'), { variant: 'warning' })}><i className='bi bi-eye'></i></button>
                    }
                </>
        },
    ];
    const FilterComponent = (
        <>
            <div class="form-group col-3 float-end ms-2">
                <select class="form-control rounded-0 diwaniya_id" ref={selectDiwaniya} name="" id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="">{t('--select_diwaniya--')}</option>
                    {
                        DiwaniyaData.map((diwaniya_data) => (
                            <option value={diwaniya_data._id}>{diwaniya_data.name}</option>
                        ))
                    }
                </select>
            </div>
            <div class="form-group col-3 float-end ms-2 me-2">
                <select class="form-control rounded-0 game_id" name="" ref={selectGame} id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="">{t('--select_game--')}</option>
                    {
                        GameData.map((game_data) => (
                            <option value={game_data._id}>{game_data.name}</option>
                        ))
                    }
                </select>
            </div>
        </>
    )

    return (
        <div className='container bg-white pb-8 my-3 p-3'>
            <div className="row">
                <div className="col-11">

                    <div className='header py-1 pb-2 position-relative'>
                        <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ps-2`}></i>{t('game history')}</h3>
                    </div>
                </div>
                <div className="col-1">
                    <button onClick={clearFilters} className="btn btn-danger">{t('reset')}</button>
                </div>
            </div>
            {/* <div class="form-group col-3 float-end">

                <select class="form-control" name="" id="" onChange={(e) => SetSelectFilter(e.target.value)}>
                    <option value="username">Search By Diwaniya</option>
                    <option value="game">Search By Game</option>
                </select>
            </div> */}
            {/* <DataTable filterBy={selectFilter == 'username' ? 'username' : 'game'} columns={columns} data={fakeUsers} /> */}
            {
                Loading ? <Loader /> :
                    <>
                        {/* {RoundLoading ? <RoundLoader /> : ''} */}
                        <Datatable columns={columns} data={GameScore} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} paginationPerPage={20} onSearch={onSearch} CustomFilter={FilterComponent} RoundLoading={RoundLoading} />
                    </>
            }
        </div >
    )
}

export default Game