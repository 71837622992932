import React, { useEffect, useState } from 'react'
import Database from '../../../Database/Index';
import DataTable from '../../../Components/Datatable/WDatatable';
import get_permission from '../../../Components/Permission';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Loader from '../../../Components/Loader';
const columns = [
    {
        name: 'Sr.no',
        selector: row => row.index,
        sortable: true,
    },
    {
        name: 'Username',
        selector: row => row.username.replace(' ', '_'),
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Phonenumber',
        selector: row => row.phonenumber,
        sortable: true,
    },
    {
        name: 'Address',
        selector: row => row.address,
        sortable: true,
    },
];

function Players() {

    const [teamData, setTeamData] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [Loading, setLoading] = useState(true);
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

    const callData = async () => {
        const _id = window.location.href.split('players/')[1];
        const response = await Database.get(apiRoute + '/team/', _id);
        if (response.status) {
            setTeamData(response.data);
            if (response.data.players) {
                response.data.players.forEach((element, index) => {
                    element.index = index + 1;
                });
                setUsers(response.data.players);
            }
        }
        setLoading(false);
    }

    const removePlayers = async () => {
        const players = selectedPlayer.filter((val, id, array) => selectedPlayer.indexOf(val) == id);
        // console.log(selectedPlayer, teamData._id, uniqueNames);
        var id = teamData._id;
        var data = { "players": players };
        const response = await Database.put(apiRoute + '/team/player/' + id, data);
        if (response.status) {
            enqueueSnackbar(response.message, { variant: 'success' });
            callData();
            setSelectedPlayer([]);
        } else {
            enqueueSnackbar(response.error, { variant: 'error' });
        }

    }

    const selectUSerData = (e) => {
        var players = [];
        e.selectedRows.forEach(element => {
            players.push(element._id);
        });
        setSelectedPlayer(players);
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('diwaniya');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {/* <div className='p-3 m-3 bg-white pb-5'>
                <div className='d-flex'>
                    <h4><Link to="/admin/diwaniya">{teamData ? teamData.name : ''}</Link> / players</h4>
                </div>
                <Link to={`/admin/diwaniya/teams/add/${teamData._id}&_-${teamData.diwaniyaid}`} className='btn btn-dark rounded-0 float-end my-3 mx-2'>Add Player</Link> */}

            <div className='container bg-white pb-8 my-3 p-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i><Link to="/admin/diwaniya">{teamData ? teamData.name : ''}</Link> / players</h3>
                    {
                        permission.add ? <div className='px-4'>
                            <Link to={`/admin/diwaniya/teams/add/${teamData._id}&-_${teamData.diwaniya_id}`} className='btn btn-dark float-end'>Add</Link>
                        </div> : ''
                    }
                </div>

                {
                    Loading ? <Loader /> : <DataTable
                        title="Select User To Remove From Team"
                        columns={columns}
                        data={users}
                        selectable={permission.delete}
                        onSelect={selectUSerData}
                    />
                }

                {
                    selectedPlayer.length ? (
                        <button onClick={removePlayers} className='btn btn-dark float-end rounded-0 px-4'>Remove</button>
                    ) : (
                        ""
                    )
                }

                <br />
            </div>
        </>
    )
}

export default Players