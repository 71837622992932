import React from 'react'
import DataTable from 'react-data-table-component';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function WDatatable({ data, columns, selectable = true, onSelect, title }) {
    const sortIcon = <ArrowDownwardIcon />;

    // for filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = data.filter(
        item => item.username && item.username.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);


    return (
        <>
            <DataTable
                className='selectDatatable'
                title={title}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeaderComponent={subHeaderComponentMemo}
                subHeader
                persistTableHead
                selectableRows={selectable}
                sortIcon={sortIcon}
                onSelectedRowsChange={(e) => onSelect(e)}
            />
        </>
    )
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <input
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            selectableRows
            title="Search"
            className="form-control w-25 rounded-0"
        />
        <button className='btn btn-dark rounded-0' type="button" onClick={onClear}>
            X
        </button>
    </>
);

export default WDatatable









