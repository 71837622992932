import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import Database from '../../Database/Index';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import UpdateAuth from '../../Components/Update_Authentication';

function Index() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const [menus, setMenus] = useState([]);
  const [Loading, setLoading] = useState(true);
  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
  // const [user, setUser] = useState({ email: localStorage.getItem('email'), password: 'Sfkhan@7615' });

  const toggleSidebar = () => {
    if (localStorage.getItem('token') != null) {
      UpdateAuth();  // localStorage.setItem('jwt', JSON.stringify(response));
    } else {
      localStorage.setItem('token', localStorage.getItem('token'));
      localStorage.setItem('permissions', localStorage.getItem('permissions'));
      localStorage.setItem('role', localStorage.getItem('role'));
    }

    document.body.classList.remove('flx-sidenav-toggled');
  }

  const JwtDetails = localStorage.getItem('jwt');
  if (JwtDetails == null) {
    navigate('/admin/login');
  }

  const callData = async () => {

    const response = await Database.get(apiRoute + '/menu/');

    if (response.status) {
      setMenus(response.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    callData();
  }, [])

  const MenuItems = menus.map((menu_data, index) =>
    <>
      {menu_data.route != '/admin/subadmin' &&  localStorage.getItem('role') == 'subadmin' ||  localStorage.getItem('role') == 'admin' ?
        <NavLink to={menu_data.route} onClick={toggleSidebar} className="list-group-item list-group-item-action border-0 p-3 rounded-2 mt-1 d-flex overflow-hidden"><i className={menu_data.icon + ' me-2'}></i>
          <span>{t(menu_data.name)}</span>
        </NavLink> : ''
      }
    </>
  );

  return (
    <>
      <div className="bg-dark" id="sidebar-wrapper">
        <div className="sidebar-heading ps-4 fs-4 text-white"><span>{t('DIWANIYA')}</span></div>
        <div className="list-group list-group-flush mt-2 px-2 sidebar-list">
          {Loading ? <Loader /> : <>{MenuItems}</>}
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Index;
