import React, { useEffect, useState } from 'react'
import Database from '../Database/Index';
import './CardQR/card_qr.css';
function Index() {
  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
  const id = window.location.href.split('PC/')[1];
  const [Type, setType] = useState('');
  const [Source, setSource] = useState('');
  const [Loader, setLoader] = useState(true);

  const fetchData = async () => {
    const response = await Database.get(apiRoute + '/pcqr/' + id);
    if (response.status) {
      if (response.data.length) {
        setLoader(false);
        var type = response.data['0'].file_type;
        var url = response.data['0'].url;
        if (type == 'url') {
          setTimeout(() => {
            window.location.href = url;
          }, 1000);
        }
        setType(type);
        setSource(url);
      }
    }

  }

  useEffect(() => {
    // console.log()
    fetchData();
  }, [])
  // setType(response.data['0'].file_type);
  //       setSource(response.data['0'].url);

  console.log('Type = ', Type, 'Source = ', Source);

  return (
    <div className="conatiner-fluid bg-white">
      {
        Loader ?
          <div className='position-fixed top-50 start-50 translate-middle'>
            <div class="spinner-border text-danger fs-1 custom_size" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
          : ''
      }
      {
        Type == 'url' ?
          <div className=''>
           <h2 className="mt-5 text-center text-secondary">Redirecting to {Source}</h2>
          </div>
          : ''
      }

{
        Type == 'image' ?
          <div className='position-fixed top-50 start-50 translate-middle'>
            <img className='max-height-95vh max-width-100vw mx-auto d-block' src={apiRoute + '/' + Source} />
          </div>
          : ''
      }

      {
        Type == 'video' ?
          <div className='position-fixed top-50 start-50 translate-middle'>
            <video className='h-90vh max-width-100vw' controls>
              <source src={apiRoute + '/' + Source} />
            </video>
          </div>
          : ''
      }
    </div>
  )
}
export default Index