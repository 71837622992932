import React from 'react'
import DataTable from 'react-data-table-component';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function WsDatatable({ data, columns, filterBy = 'username' }) {

    const sortIcon = <ArrowDownwardIcon />;

    // for filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = data.filter(
        item => item[filterBy] && item[filterBy].toLowerCase().includes(filterText.toLowerCase()),
    );


    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                className='withoutselectDatatable'
                columns={columns}
                data={filteredItems}
                sortIcon={sortIcon}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeaderComponent={subHeaderComponentMemo}
                subHeader
                persistTableHead
            />
        </>
    )
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <input
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            className="form-control w-25 rounded-0"
        />
        <button className='btn btn-dark rounded-0' type="button" onClick={onClear}>
            X
        </button>
    </>
);

export default WsDatatable















// import React from 'react'
// //Datatable Modules
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";

// function Datatable({ data, type, button, onEdit, onDelete, onView, ButtonTitle, columnSize, is_image }) {
//     //initialize datatable
//     $(document).ready(function () {
//         $("#example").DataTable();
//     });

//     var datatable_keys = [];
//     if (data[0] !== undefined) {
//         datatable_keys = Object.keys(data[0]);
//     }

//     var columnSizeStatus = columnSize !== undefined && columnSize.length === datatable_keys.length ? true : false;

//     return (
//         <table id="example" className="display table table-bordered border-top">
//             <thead>
//                 <tr className='bg-danger text-white'>
//                     <th className='w-10'>#</th>

//                     {
//                         datatable_keys.map((val, index) => (
//                             is_image && val === 'Image' ? (
//                                 <th className="table_action w_10">Image</th>
//                             ) : (
//                                 <th className={columnSizeStatus ? columnSize[index] : ''} key={index}>{val.replace("_", " ")}</th>
//                             )
//                         ))
//                     }
//                     {
//                         type ? (
//                             <th className="table_action w_20">Action</th>
//                         ) : ('')
//                     }
//                 </tr>
//             </thead>
//             <tbody>
//                 {
//                     data.map((val, index) => (
//                         <tr key={index}>
//                             <td>{index + 1}</td>
//                             {
//                                 datatable_keys.map((key_val, key_index) => (

//                                     is_image && key_val === 'Image' ? (
//                                         <td className='text-center'><img className='table_profile' src={val[key_val]} alt="" /></td>
//                                     ) : (
//                                         <td key={key_index}>{val[key_val]}</td>
//                                     )

//                                 ))
//                             }
//                             {
//                                 type ? (
//                                     <td>
//                                         {
//                                             button.map((btnVal, index_btn) => (
//                                                 <button key={index_btn} title={ButtonTitle ? ButtonTitle[index_btn] : ''} onClick={() => btnVal === 'view' ? (onView ? onView(val) : '') : btnVal === 'edit' ? (onEdit ? onEdit(val) : '') : btnVal === 'delete' ? (onDelete ? onDelete(val) : '') : ''} className={`btn mx-1 btn-sm btn-${btnVal === 'delete' ? 'danger' : btnVal === 'edit' ? 'warning' : btnVal === 'view' ? 'dark' : btnVal === 'sendEmai' ? 'success' : btnVal === 'qr' ? 'dark' : 'primary'}`}><i className={`bi bi-${btnVal === 'delete' ? 'trash' : btnVal === 'edit' ? 'pencil-square' : btnVal === 'view' ? 'eye' : btnVal === 'sendEmai' ? 'envelope' : btnVal === 'qr' ? 'qr-code' : 'phone'}`}></i></button>
//                                             ))
//                                         }
//                                     </td>
//                                 ) : ('')
//                             }
//                         </tr>
//                     ))
//                 }
//             </tbody>
//         </table >
//     )
// }

// export default Datatable