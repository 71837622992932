import React from 'react'
import { useNavigate } from 'react-router-dom';
function Index() {
  const navigate = useNavigate();

  return (
    <div className='container-fluid  bg-white'>
      <div className="row text-center mt-5">
        <div class="app-icons">
          <img width={150}
            class="android"
            alt='Get it on Google Play'
            src='/assets/Login/images/app_icon.png' />
        </div>
      </div>
      <h1 className='text-center mt-2 fw-bold text-danger'>About AlJaiker </h1>
      <p className='p-5'>AlJaiker is the perfect app for card game enthusiasts of all ages! With AlJaiker, you can easily calculate scores for a variety of popular card games, such as Kout, Trix, General Scoring, Hand, and Spide, But that's not all – AlJaiker also allows you to create groups called Al-Diwaniya, which are designated places for hosting guests and enjoying quality time with friends, neighbors, and relatives. With AlJaiker, you can interact with other players and see live results as you play. Download AlJaiker now and experience the ultimate card game scoring and socializing app!
      </p>
      <h1 className='text-center mt-3 fw-bold text-danger'>Download AlJaiker </h1>
      <div className="row text-center">
        <div class="app-icons">
          <a href="https://apps.apple.com/in/app/aljaiker/id6446133730">
            <img
              class="apple"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
              alt="Download on the App Store" />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.anas.joker&pli=1'>
            <img width={150}
              class="android"
              alt='Get it on Google Play'
              src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
          </a>
          <h5 onClick={() => { navigate('/privacy-policy') }} className='p-2 text-primary cursor-pointer'>privacy-policy</h5>
        </div>
      </div>
    </div>
  )
}
export default Index