import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import DataTable from '../../Components/Datatable/WDatatable';
import get_permission from '../../Components/Permission';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';

function Players() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [diwaniya, setDiwaniya] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;

  const callData = async () => {
    const _id = window.location.href.split('players/')[1];
    const response = await Database.get(apiRoute + '/team/', _id);
    if (response.status) {
      console.log(response.data);
      setDiwaniya(response.data);
      if (response.data.players) {
        setUsers(response.data.players);
      }
    }
  }

  const columns = [
    {
      name: t('username'),
      selector: row => row.username.replace(' ', '_'),
      sortable: true,
    },
    {
      name: t('email'),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: t('phone number'),
      selector: row => row.phonenumber,
      sortable: true,
    },
    {
      name: t('address'),
      selector: row => row.address,
      sortable: true,
    },
  ];


  const setPermissoin = async () => {
    var page_permission = await get_permission('teams');
    setPermission(page_permission);
  }

  useEffect(() => {
    setPermissoin();
    callData();
  }, [])

  // Datatble functions
  const selectUSerData = (e) => {
    setSelectedUsers(e.selectedRows);
  }

  // const removePlayers = async () => {
  //   var playersid = [];
  //   var selectedId = [];

  //   selectedUsers.forEach((element, index) => {
  //     selectedId.push(element._id);
  //   })

  //   diwaniya.playersid.forEach((element, index) => {
  //     if (!selectedId.includes(element)) {
  //       playersid.push({ _id: element });
  //     }
  //   })


  //   const _id = window.location.href.split('players/')[1];
  //   const data = { _id, playersid };
  //   const response = await Database.add(apiRoute + '/diwaniya/players', data);
  //   if (response.status) {
  //     callData();
  //     enqueueSnackbar("Players Remove Successfully", { variant: 'success' });
  //   } else {
  //     enqueueSnackbar(response.error, { variant: 'error' });
  //   }
  // }
  const removePlayers = async () => {
    var players = [];
    var selectedId = [];

    selectedUsers.forEach((element, index) => {
      if (!players.includes(element)) {
        players.push(element._id);
      }
    })

    // diwaniya.players.forEach((element, index) => {
    //   if (!selectedId.includes(element)) {
    //     playersid.push({ _id: element });
    //   }
    // })


    const _id = window.location.href.split('players/')[1];
    const data = { players };
    const response = await Database.put(apiRoute + '/team/player/' + _id, data);
    if (response.status) {
      callData();
      enqueueSnackbar(t('player_remove_success'), { variant: 'success' });
      navigate(-1);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }
  return (
    <>
      <div className='p-3 m-3 bg-white pb-5'>
        <h4><Link to="/admin/diwaniya">{diwaniya ? diwaniya.name : ''}</Link> / {t('players')}</h4>
        <DataTable
          title="Select User To Remove From Team"
          columns={columns}
          data={users}
          selectable={permission.delete}
          onSelect={selectUSerData}
        />
        {
          selectedUsers.length ? (
            <button onClick={removePlayers} className='btn btn-danger rounded-0 float-end my-3'>{t('remove_player')}</button>
          ) : ('')
        }
        <br />
      </div>
    </>
  )
}

export default Players