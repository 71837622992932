import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import './DataTableDemo.css';
import { useTranslation } from 'react-i18next';

function GameScore() {
    // const [employeeData, setEmployeeData] = useState(data);
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [ScoreData, setScoreData] = useState([]);
    const [TeamList, setTeamList] = useState([]);
    const [AllowScore, setAllowScore] = useState([0, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 36]);
    const [TeamData, setTeamData] = useState({});
    const [TeamDataD, setTeamDataD] = useState({});
    const [TotalScore, setTotalScore] = useState({});
    const [WinningTarget, setWinningTarget] = useState('');
    const [WinningType, setWinningType] = useState('');
    const [PreviousScore, setPreviousScore] = useState({ team_0: 0, team_1: 0 });
    const [ValidScore, setValidScore] = useState({ team_0: false, team_1: false });
    const [editTable, setEditTable] = useState(true);
    const [Loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const id = window.location.href.split('kout/')[1];
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/score/' + id;
    const { t } = useTranslation();
    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            extractData(response.data);
            setWinningType(response.data.game.winning_type);
            setLoading(false);
        }
    }

    const extractData = (data) => {
        var score_data = data.score_data;
        score_data = JSON.parse(score_data);
        var winning_target = '';
        var teamdata = [];
        var team_listing = []
        for (let i = 0; i < score_data.data.length; i++) {
            var data_obj = score_data.data[i];
            winning_target = data_obj.wining_target;
            var team_list = data_obj.teamList;
            for (let index = 0; index < team_list.length; index++) {
                team_listing[index] = team_list[index];

            }
            var team_data = data_obj.teamData;
            var Team_List = [];
            Team_List.push(data_obj.teamList);
            var obj = {};
            for (let index = 0; index < team_list.length; index++) {
                obj['team_' + index] = team_data[index];
            }
            obj.bid = data_obj['name'];
            teamdata.push(obj);
        }

        // Set Current team_score start
        var count_score_0 = 0;
        var count_score_1 = 0;
        for (let index = 0; index < teamdata.length; index++) {
            var round_data = teamdata[index];
            count_score_0 += round_data.team_0;
            count_score_1 += round_data.team_1;
        }
        setPreviousScore({ team_0: count_score_0, team_1: count_score_1 });
        // Set Current team_score start

        setTeamList(team_listing);
        setTeamData(teamdata);
        setTeamDataD(teamdata);
        setWinningTarget(winning_target);
        setScoreData(data);
        var total_score = total_arr(teamdata);
        setTotalScore(total_score);
    }

    const total_arr = (teamdata) => {
        var total_score_obj = {};
        for (let index = 0; index < teamdata.length; index++) {
            var team_data = teamdata[index];
            if (team_data && team_data.team_0 != undefined) {
                if (total_score_obj.team_0 != undefined) {
                    var team_0 = team_data.team_0 + total_score_obj.team_0;
                    var team_1 = team_data.team_1 + total_score_obj.team_1;
                    total_score_obj = { team_0: team_0, team_1: team_1 };
                } else {
                    total_score_obj = { team_0: team_data.team_0, team_1: team_data.team_1 };
                }
            }
        }
        return total_score_obj;
    }

    const datapost = async () => {
        setspinnerIsActive(true); 
        var reset = true;
        var score_data = ScoreData.score_data;
        score_data = JSON.parse(score_data);

        if (TotalScore.team_0 >= WinningTarget && TotalScore.team_1 >= WinningTarget) {
            enqueueSnackbar(t('team_score_cant_greater_than_equal_to_winning_score') + WinningTarget, { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        if (TeamData.length) {
            for (let index = 0; index < TeamData.length; index++) {
                var TeamArr = TeamData[index];
                var arr = [];
                for (let i = 0; i < 2; i++) {
                    var check = AllowScore.includes(parseInt(TeamArr['team_' + i]));
                    if (!check) {
                        enqueueSnackbar(`${t('Score_data_range_of')} ( ${AllowScore} )`, { variant: 'error' });
                        setspinnerIsActive(false);
                        return;
                    }
                    arr.push(TeamArr['team_' + i]);
                }
                score_data.data[index]['teamData'] = arr;

            }
            var samevalue_status = false;
            var elems = document.querySelectorAll(".team_field");
            [].forEach.call(elems, function (el) {
                var round = el.attributes.dataround.value;
                var datakey = el.attributes.datakey.value;
                if (TeamData[round].team_0 == 0 && TeamData[round].team_1 == 0 && datakey == 1 && reset) {
                    enqueueSnackbar(t('team_score_cant_be_0'), { variant: 'error' });
                    samevalue_status = true;
                    reset = false;
                }
            });
            var winner_details = WinnerDetails();
            if (samevalue_status) { return; }

            if (ScoreData._id) {
                var score_data_stringfy = JSON.stringify(score_data);
                const response = await Database.update(apiRoute, { 'score_data': score_data_stringfy, winner_name: winner_details.winner_name, winner_score: winner_details.winner_score });
                if (response.status) {
                    enqueueSnackbar(t('game_update_success'), { variant: 'success' });
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            }
        }
        setspinnerIsActive(false);
        setEditTable(true);
    }

    const WinnerDetails = () => {
        var team_0 = TotalScore.team_0;
        var team_1 = TotalScore.team_1;
        var team_index = 0;
        var max_score = team_0;
        if (WinningType == 'low') {
            if (team_0 < team_1) {
                team_index = 0;
                max_score = team_0;
            } else {
                team_index = 1;
                max_score = team_1;
            }
        } else {
            if (team_0 < team_1) {
                team_index = 1;
                max_score = team_1;
            } else {
                team_index = 0;
                max_score = team_0;
            }
        }
        return { winner_name: TeamList[team_index], winner_score: max_score };
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('history/game');
        setPermission(page_permission);
    }

    const onChangeInput = (e) => {
        var team_data = TeamData;
        var newarr = [];
        let name, value;
        name = e.target.name;
        value = e.target.value;
        var keys = name.split('_');
        var round = parseInt(keys[1]);
        var key = parseInt(keys[2]);
        if (value == '' || value == undefined || value == null) {
            value = 0;
        } else {
            var data_key = key == 0 ? 1 : 0;
            team_data[round]['team_' + data_key] = parseInt(0);
        }

        team_data[round]['team_' + key] = parseInt(value);
        setTeamDataD({ ...TeamDataD, [0]: newarr });
        var total_score = total_arr(team_data);
        setTotalScore(total_score);
    }

    const KeyDown = (event) => {
        var reg = new RegExp('^[0-9]*$');
        var team_total_score_key = event.target.attributes.datakey.value;
        if (!reg.test(event.key) && event.keyCode != 8) {
            event.preventDefault();
        }
    }

    const ScoreKeyUp = (event) => {
        var team_total_score_key = event.target.attributes.datakey.value;
        var class_list = event.target.className;
        var current_round = event.target.attributes.dataround.value;
        class_list = class_list.replace("not-valid-ws", '');
        event.target.className = '';
        // if ((WinningTarget < TotalScore['team_' + team_total_score_key])) {
        //     event.target.className = class_list + " not-valid-ws ";
        //     setValidScore({ ...ValidScore, ['team_' + team_total_score_key]: true });
        //     enqueueSnackbar("Total score can`t be greate than winning score " + WinningTarget, { variant: 'error' });
        // } else {
        var elems = document.querySelectorAll(".not-valid-ws");
        [].forEach.call(elems, function (el) {
            var round = el.attributes.dataround.value;
            if (el.attributes.datakey.value == team_total_score_key && (TeamData[round].team_0 != 0 || TeamData[round].team_1 != 0)) {
                el.className = el.className.replace('not-valid-ws', "");
            }
        });
        event.target.className += class_list;
        setValidScore({ ...ValidScore, ['team_' + team_total_score_key]: false });
        // }

        var elems = document.querySelectorAll(".team_field");
        [].forEach.call(elems, function (el) {
            var class_lists = el.attributes.class.value;
            class_lists = class_list.replace("not-valid-ws", '');
            var round = el.attributes.dataround.value;
            if (current_round == round) {
                if (TeamData[round].team_0 == 0 && TeamData[round].team_1 == 0) {
                    el.className = class_lists + " not-valid-ws ";
                } else {
                    el.className = el.className.replace('not-valid-ws', "");
                }
            }
        });
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {
                Loading ? <Loader /> :
                    <>
                        <div className='container bg-white pb-8 my-3 p-3'>
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('Kout_score')}</h3>
                                    <br />
                                    <h5 className={`text-dark`}>
                                        {ScoreData.game.name} - ({ScoreData.diwaniya.name})
                                        <span className='float-end'>WS : {WinningTarget}</span>
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='text-center b-white w-25'>{t('Bid')}</th>
                                                <>
                                                    {TeamList.map((team_name) => (
                                                        <>
                                                            <th className='text-center b-white'>{team_name}</th>
                                                        </>
                                                    ))}
                                                </>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {TeamData.map((round_data, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td className='text-center p-2 roundColor'>
                                                                <input
                                                                    disabled
                                                                    className=''
                                                                    name="bid"
                                                                    value={round_data.bid}
                                                                    type="text"
                                                                    placeholder={t('Round')}
                                                                />
                                                            </td>
                                                            <td className={'text-center roundColor'}>
                                                                <input
                                                                    disabled={editTable ? true : false}
                                                                    className={`w-100 p-2 team_field ` + (AllowScore.includes(parseInt(round_data.team_0)) ? '' : 'not_valid')}
                                                                    name={'team_' + index + '_0'}
                                                                    value={round_data.team_0}
                                                                    type="text"
                                                                    onChange={(e) => onChangeInput(e)}
                                                                    onKeyDown={(e) => (KeyDown(e))}
                                                                    onKeyUp={(e) => (ScoreKeyUp(e))}
                                                                    placeholder={t('Score')}
                                                                    datakey="0"
                                                                    dataround={index}
                                                                />
                                                            </td>
                                                            <td className={`text-center roundColor`}>
                                                                <input
                                                                    disabled={editTable ? true : false}
                                                                    className={`w-100 p-2 team_field ` + (AllowScore.includes(parseInt(round_data.team_1)) ? '' : 'not_valid')}
                                                                    name={'team_' + index + '_1'}
                                                                    value={round_data.team_1}
                                                                    type="text"
                                                                    onChange={(e) => onChangeInput(e)}
                                                                    onKeyDown={(e) => (KeyDown(e))}
                                                                    onKeyUp={(e) => (ScoreKeyUp(e))}
                                                                    placeholder={t('Score')}
                                                                    datakey="1"
                                                                    dataround={index}
                                                                />
                                                            </td>

                                                        </tr>
                                                    </>
                                                ))}
                                            </>
                                        </tbody>
                                        <br />
                                        <tfoot>
                                            <tr>
                                                <th className='mt-2 bg-dark text-center b-white'> {t('total')} </th>
                                                <th className='mt-2 bg-dark text-center b-white'> {TotalScore.team_0} </th>
                                                <th className='mt-2 bg-dark text-center b-white'> {TotalScore.team_1} </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div >

                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { setEditTable(true) }} data-bs-target="#staticBackdrop" className='btn btn-danger float-end ms-3'>{t('Cancel')}</button>
                            </div> : ''
                        }
                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : datapost(); }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>
                                    {spinnerIsActive ?
                                        <><i className="spinner-border custom-spinner me-2" role="status"></i>
                                        </>
                                        : ""}
                                    {editTable ? t('edit') : t('save')}</button>
                            </div> : ''
                        }
                    </>
            }

        </>

    )
}

export default GameScore