import React, { useEffect, useState, useRef } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import Loader from '../../Components/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RoundLoader from '../../Components/RoundLoader';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from 'react-csv';
const axios = require("axios");

function Index() {

    const [show, setShow] = useState(false);
    const [infoShow, setInfoShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleInfoShow = () => setInfoShow(true);
    const handleClose = () => { setShow(false); setNotiUsers([]); setDevice(''); setNotificationImage('');}
    const handleInfoClose = () => { setInfoShow(false); }

    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userData, setUserData] = useState([]);
    const [playerData, setPlayerData] = useState([]);
    const [userDataForCsv, setUserDataForCsv] = useState([]);
    const [userValue, setUserValue] = useState({ username: '', address: '', phonenumber: '', email: '', image: '', id: '' });
    const [modalTitle, setModalTitle] = useState('add');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [formdata, setFormData] = useState([]);
    const [NotiUsers, setNotiUsers] = useState([]);
    const [UserListing, setUserListing] = useState([]);
    const [notification_image, setNotificationImage] = useState('');
    const [Device, setDevice] = useState('');
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const [uploadImageLoader, setUploadImageLoader] = useState(false);
    const [Disable, setDisable] = useState(false);
    const [modalData, setModalData] = useState([]);


    const inputRef = useRef(null);

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/notification';
    const lang = localStorage.getItem('lang');
    // get User data
    // console.log(lang);
    const fetchUserData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setUserData(response.data);
            setUserDataForCsv(response.data);
            setTotalRows(response.total_records);
        }
        setLoading(false);
    }

    

    const setPermissoin = async () => {
        var page_permission = await get_permission('players');
        setPermission(page_permission);
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchUserData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchUserData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchUserData(perPage, 1, val);
    }

    // delete
    const deletePlayer = async (id) => {

        enqueueSnackbar(t('delete_comfirmation'), {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(id); closeSnackbar(key); }}>
                        Delete
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (id) => {
        const response = await Database.delete(apiRoute + '/', id);
        if (response.status) {
            enqueueSnackbar(t('delete_success'), { variant: 'success' });
            fetchUserData(perPage, page);
        }
    }

    useEffect(() => {
        setPermissoin();
        fetchUserData(perPage, page, search);
    }, [])

    const inputHandle = async (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }

    const removeImage = (e) => {
        const formdata = new FormData();
        setNotificationImage('');
        inputRef.current.value = null;
        return;
        setUploadImageLoader(true);
        formdata.append("id", userValue.id);
        formdata.append("username", userValue.username);
        formdata.append("address", userValue.address);
        formdata.append("email", userValue.email);
        formdata.append("image", "");
        setFormData(formdata);
        axios
            .put(apiRoute + '/' + userValue.id,
                formdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.M3RRMHdLTWZ6dQ.3KYGKye5acseMF2meWYZ2TvE_UvVaPei2yZS3t3T160"
                    },
                }
            )
            .then((res) => {
                if (res.data.length != 0) {
                    fetchUserData(perPage, page, search);
                    enqueueSnackbar('Removed', { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar("Image name Already Exist !", { variant: 'error' });
                }
            })
    };
    const datapost = async (e) => {
        setDisable(true)
        e.preventDefault();
        const formdata = new FormData(e.target);
        var form_elements = e.target.elements;
        if (form_elements.device.value == 'user') {
            formdata.append("users", JSON.stringify(NotiUsers));
        }
        if (form_elements.title.value == '' || form_elements.message.value == '') {
            enqueueSnackbar(t('required_field'), { variant: 'error' }); return;
        }
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
            'language': localStorage.getItem('lang')
        }

        axios.put(apiRoute, formdata, {
            headers: headers
        })
            .then((res) => {
                if (res.data.length != 0) {
                    enqueueSnackbar(t('add_success'), { variant: 'success' });
                    setDisable(false)
                    handleClose();
                    fetchUserData(perPage, page, search);
                } else {
                    enqueueSnackbar(t('name_exist'), { variant: 'error' });
                }
            })
            .catch((error) => {

            })
        return;
        // return;
        axios.post(apiRoute, formdata, +
            {
                headers: {
                    "Content-Type": "multipart/form-dataddddddddddddd",
                    "Access-Control-Allow-Origin": "*",
                },
            }
        ).then((res) => {
            if (res.data.length != 0) {
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(t('name_exist'), { variant: 'error' });
            }
        })
    }

    // For Craete Array to Export CSV
    var CsvArray = [];
    for (let index = 0; index < userDataForCsv.length; index++) {
        var newArr = {};
        newArr.address = userDataForCsv[index].address;
        newArr.username = userDataForCsv[index].username;
        newArr.email = userDataForCsv[index].email;
        newArr.phonenumber = userDataForCsv[index].phonenumber;
        newArr.verified = userDataForCsv[index].verified;
        newArr.createdAt = userDataForCsv[index].createdAt;
        newArr.updatedAt = userDataForCsv[index].updatedAt;
        CsvArray.push(newArr);
    }

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////

    // columns

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('user'),
            selector: row => row.players[0].username,
            sortable: true
        },
        {
            name: t('Device'),
            selector: row => row.device,
            sortable: true
        },
        {
            name: t('title'),
            selector: row => row.title.length > 10 ? row.title.substring(0,10)+'...' : row.title || "-",
            sortable: true
        },
        {
            name: t('message'),
            selector: row => row.message.length > 15 ? row.message.substring(0,15)+'...' : row.message,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    <button className='btn btn-danger btn-sm mx-1' type='button' onClick={() => { setModalData(row); handleInfoShow(); }}><i className='bi bi-info-lg'></i></button>

                </>
        },
    ];

    // Remove action while user don`t have any permissions
    if (!permission.edit && !permission.delete) { columns.pop(columns.length - 1); }

    const numberKeyDown = (event) => {
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) & event.keyCode != 8) {
            event.preventDefault();
        }
    }



    const uploadFile = (event) => {
        const formdata = new FormData();
        setNotificationImage(URL.createObjectURL(event.target.files[0]));

        formdata.append("image", event.target.files[0]);
        setFormData(formdata);
        // axios
        //     .post(apiRoute,
        //         formdata, +
        //     {
        //         headers: {
        //             "Content-Type": "multipart/form-data",
        //             "Access-Control-Allow-Origin": "*",
        //         },
        //     }
        //     )
        //     .then((res) => {
        //         if (res.data.length != 0) {
        //             enqueueSnackbar(t('add_success'), { variant: 'success' });
        //             handleClose();
        //         } else {
        //             enqueueSnackbar(t('name_exist'), { variant: 'error' });
        //         }
        //     })
    };
    // Get players with device type

    const fetchPlayerWithDevice = async () => {
        // setRoundLoading(true);
        const playerResponse = await Database.get(process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/player/getPlayerWithDeviceType');
        if (playerResponse.status) {
            // setRoundLoading(false);
            setPlayerData(playerResponse.data);
            // setUserDataForCsv(response.data);
            // setTotalRows(response.total_records);
        }
        // setLoading(false);
    }


    const state = {
        options: playerData
    };

    const getUserlisting = (e) => {
        if(e.target.value == 'user'){
            fetchPlayerWithDevice();
        }
        setDevice(e.target.value)
    }


    const onSelect = (e) => {
        setNotiUsers(e);
    };
    const onRemove = (e) => {
        setNotiUsers(e);
    };

    const FilterComponent = (
        <>
            <div class="form-group col-3 float-end me-2">
                <select class="form-control rounded-0"  name="" id="" onChange={e => { onSearch(e.target.value) }}>
                            <option value=''>All</option>
                            <option value='android'>Android</option>
                            <option value='ios'>ios</option>
                </select>
            </div>
            {/* <div class="form-group col-3 float-end ms-2 me-2">
                <select class="form-control rounded-0 game_id" name="" ref={selectGame} id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="">{t('--select_game--')}</option>
                    {
                        GameData.map((game_data) => (
                            <option value={game_data._id}>{game_data.name}</option>
                        ))
                    }
                </select>
            </div> */}
        </>
    )
    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-bell  ms-2`}></i>{t('push_notifications')}</h3>

                    {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setUserValue({ username: '', email: '', phonenumber: '', address: '', image: '' }); handleShow(); setModalTitle('Send') }} className='btn btn-dark float-start'>{t('send')}</button>
                        </div> : ''
                    }
                </div>
                {
                    Loading ? <Loader /> :
                    <>
                        <Datatable columns={columns} data={userData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} CustomFilter={FilterComponent} loadertop="t506" />
                    </>
                }

            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t(modalTitle)} {t('notification')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>

                        <table border='0'>
                            <tr>
                                <td className=' text-end border border-0 p-2'><label className="form-check-label me-2">{t('devices')} : </label></td>
                                <td className='border border-0 p-2'>
                                    <select className="form-control  w-100 d-inline" aria-label="Default select example" name='device' onChange={(e) => { getUserlisting(e); }}>
                                        <option value="android">{t('android')}</option>
                                        <option value="ios">{t('ios')}</option>
                                        <option value="all">{t('all')}</option>
                                        <option value="user">{t('user')}</option>
                                    </select>
                                    {Device == 'user' ?
                                        <div className='mt-3'>
                                            <Multiselect
                                                options={state.options} // Options to display in the dropdown
                                                selectedValues={NotiUsers} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="username" // Property name to display in the dropdown options
                                            />
                                        </div>
                                        : ''

                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className='text-end border border-0 p-2'><label className="form-check-label me-2">{t('title')} : </label></td>
                                <td className='border border-0 p-2'>
                                    <input type="text" name="title" className='form-control  w-100 d-inline' placeholder={t('title')} onChange={inputHandle} required />
                                    <span className='invalid-feedback'>{t('title')} </span>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-end border border-0 p-2'>
                                    <label className="form-check-label me-2">{t('message')} : </label>
                                </td>
                                <td className='border border-0 p-2'>
                                    <textarea rows="5" name="message" className='form-control  w-100 d-inline' placeholder={t('message')} onChange={inputHandle} required ></textarea>
                                    <span className='invalid-feedback'>{t('message')} </span>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-end border border-0 p-2'>
                                    <label className="form-check-label me-2">{t('image')} : </label>
                                </td>
                                <td className='border border-0 p-2 position-relative'>
                                    <input type="file" ref={inputRef} accept="image/*" name="image" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom pe-4' placeholder={t('address')} onChange={(e) => uploadFile(e)} />
                                    {notification_image != '' ? <button type="button" className="btn-danger bg-danger position-absolute end-0 top-0 mt-4" onClick={removeImage} aria-label="Close">X</button> : ''}

                                </td>
                            </tr>

                            {
                                notification_image != '' ?
                                    <tr>
                                        <td className='text-end border border-0 p-2'>
                                        </td>
                                        <td className='border border-0 p-2'>
                                            <img className='w-100' src={notification_image} />
                                        </td>
                                    </tr>
                                    : ''
                            }

                        </table>

                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" disabled={Disable} className="btn btn-danger rounded-0 px-4">{t('send')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>

            <Modal show={infoShow}>
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleInfoClose(); }}>
                    <Modal.Title className='ps-4'>{modalData.players ? modalData.players[0].username :''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-3"><h5>{t('Device')}</h5> </div>
                            <div className="col-9"><p className='text-break'>{modalData.device}</p></div>
                        </div>
                        <div className="row">
                            <div className="col-3"><h5>{t('title')}</h5> </div>
                            <div className="col-9"><p className='text-break'>{modalData.title}</p></div>
                        </div><div className="row">
                            <div className="col-3"><h5>{t('message')}</h5> </div>
                            <div className="col-9"><p className='word-wrap'>{modalData.message}</p></div>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>

        </>
    )
}

export default Index
