import Database from '../Database/Index';
const setpermission = async (module_name) => {
    let LoginSession = JSON.parse(localStorage.getItem('jwt'));
    var module_permission = { 'add': false, 'edit': false, 'delete': false };
    const menuRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/menu?route=' + module_name;
    const response = await Database.get(menuRoute);
    if (response.status && response.data  && localStorage.getItem('permissions') && localStorage.getItem('role') == 'subadmin') {
        var permission_arr = JSON.parse(localStorage.getItem('permissions'));
        if (permission_arr.length) {
            // console.log("Local Storage permission => ",JSON.parse(localStorage.getItem('permissions')))
            // console.log("Login Session => ",JSON.parse(LoginSession.permissions))
            var id = response.data[0]._id;
            permission_arr.forEach((ele, ind) => {
                if (ele._id == id) {
                    module_permission = ele;
                }
            });
        }
    } else {
        module_permission = { 'add': true, 'edit': true, 'delete': true };
    }
    return module_permission;
}

export default setpermission;

