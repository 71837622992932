import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import { Modal, Checkbox } from "react-bootstrap";
import './DataTableDemo.css';
import { useTranslation } from 'react-i18next';

function GameScore() {
    const [show, setShow] = useState(false);
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [ScoreData, setScoreData] = useState([]);
    const [PlayerData, setPlayerData] = useState([]);
    const [PlayerScore, setPlayerScore] = useState([]);
    const [TotalScore, setTotalScore] = useState({});
    const [editTable, setEditTable] = useState(true);
    const [Loading, setLoading] = useState(true);
    const [KeySet, setKeySet] = useState(true);
    const [WinningType, setWinningType] = useState('');
    const [MinusScoreType, setMinusScoreType] = useState(true);
    const [CustomScoreBox, setCustomScoreBox] = useState({ value: 0 });
    const [FieldLocation, setFieldLocation] = useState({ round: 0, key: 0, name: "", value: 0 });
    const [ScorePair, setScorePair] = useState({ "-30": 100, "-60": 200, "-120": 400, "-200": 600, "-400": 800, "-600": 1200 });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const id = window.location.href.split('hand/')[1];
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/score/' + id;

    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            extractData(response.data);
            setWinningType(response.data.game.winning_type);
            setLoading(false);
        }
    }

    const extractData = (data) => {
        var score_data = data.score_data;
        score_data = JSON.parse(score_data);
        setScoreData(data);
        setPlayerData(score_data);
        var player_score_listing = [];

        for (let index = 0; index < score_data.roundList.length; index++) {
            var temp_arr = [];
            for (let i = 0; i < score_data.playerdata.length; i++) {
                var player_score = score_data.playerdata[i].scores[index];
                player_score.key = i;
                temp_arr.push(player_score);
            }
            player_score_listing.push(temp_arr);
        }

        var player_score_total = total_arr(score_data.playerdata);
        setTotalScore(player_score_total);
        setPlayerScore(player_score_listing);
    }

    const total_arr = (playerdata) => {
        var player_score_total = [];
        for (let index = 0; index < playerdata.length; index++) {
            var player_data = playerdata[index];

            // Sum of player total score
            var temp_sum = 0;
            for (let index = 0; index < player_data.scores.length; index++) {
                var temp_score_data = player_data.scores[index];
                temp_sum += temp_score_data.score;
            }
            player_score_total.push(temp_sum);
        }
        return player_score_total;
    }

    const datapost = async () => {
        setspinnerIsActive(true);
        validate_score_fields();
        var reset = true;
        var score_data = ScoreData.score_data;
        score_data = JSON.parse(score_data);

        var samevalue_status = false;
        var elems = document.querySelectorAll(".not_valid");
        [].forEach.call(elems, function (el) { samevalue_status = true; });
        if (samevalue_status) {
            enqueueSnackbar(t('Player_score_limit_error'), { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        var winner_details = WinnerDetails();
        if (ScoreData._id) {

            var score_data_stringfy = JSON.stringify(PlayerData);
            const response = await Database.update(apiRoute, { 'score_data': score_data_stringfy, winner_name: winner_details.winner_name, winner_score: winner_details.winner_score });
            if (response.status) {
                enqueueSnackbar(t('game_update_success'), { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        setEditTable(true);
        setspinnerIsActive(false);
    }

    const validate_score_fields = () => {
        var elems = document.querySelectorAll(".score_fields");
        [].forEach.call(elems, function (el) {
            var value = el.value;
            var class_lists = el.className.replace("not_valid", '');
            if (value > 1200 || value == 0) {
                el.className = class_lists + ' not_valid';
            } else {
                el.className = class_lists;
            }

        });
    }

    const WinnerDetails = () => {

        if (WinningType == 'low') {
            var max_score = Math.min(...TotalScore);
        } else {
            var max_score = Math.max(...TotalScore);
        }
        var winnerName = [];
        for (let index = 0; index < TotalScore.length; index++) {
            const score = TotalScore[index];
            if (max_score == score) {
                winnerName.push(PlayerData.playerList[index].name);
            }
        }
        return { winner_name: winnerName.toString(), winner_score: max_score };
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('game');
        setPermission(page_permission);
    }

    const onChangeInput = (e) => {
        // 
    }

    const onchecked = (e) => {
        var round = e.target.attributes.value.value;
        var player_data = PlayerData;
        for (let index = 0; index < PlayerScore[round].length; index++) {
            var data = PlayerScore[round][index];
            player_data.playerdata[data.key].scores[round].score = parseInt(0);
        }

        var player_score_total = total_arr(player_data.playerdata);
        setTotalScore(player_score_total);
        validate_score_fields();
    }

    const scoreClick = (e) => {
        var round = e.target.attributes.dataround.value;
        var key = e.target.attributes.datakey.value;
        var value = e.target.attributes.value.value;

        // Check Minus Value
        var status = false;
        var isNegative = false;
        var negative_value = value;
        var elems = document.querySelectorAll(".score_field_key_" + round);
        [].forEach.call(elems, function (el) {
            var score_key = el.attributes.datakey.value;
            var value = el.attributes.value.value;
            if (value < 0) {
                negative_value = value;
                isNegative = true;
                if (score_key == key) {
                    status = true;
                }
            }
        });

        status = !isNegative ? true : status
        setMinusScoreType(status);
        setCustomScoreBox(0);
        setShow(true);
        setFieldLocation({ round: round, key: key, name: PlayerData.playerList[key].name, value: negative_value });
        validate_score_fields();
    }

    const KeyDown = (event) => {
        if (!KeySet) {
            event.preventDefault();
        }
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) && event.keyCode != 8) {
            event.preventDefault();
        }
        if (KeySet) {
            setKeySet(false);
        }
    }

    const keyUp = (event) => {
        setKeySet(true);
    }

    const CustomInput = () => {
        var value = document.querySelectorAll(".custom_score_box")[0].value;
        if (value == '' || value == undefined || value == 0 || value > 1200) {
            enqueueSnackbar(t('Player_score_limit_error_1200'), { variant: 'error' });
        } else {
            PlayerData.playerdata[FieldLocation.key].scores[FieldLocation.round].score = parseInt(value);
            var player_score_total = total_arr(PlayerData.playerdata);
            setTotalScore(player_score_total);
            setShow(false);
            document.querySelectorAll(".reset_checkbox_" + FieldLocation.round)[0].checked = false;
        }
    }

    const selectScore = (e) => {
        PlayerData.playerdata[FieldLocation.key].scores[FieldLocation.round].score = parseInt(e.target.attributes.datavalue.value);
        var player_score_total = total_arr(PlayerData.playerdata);
        setTotalScore(player_score_total);
        setShow(false);
        document.querySelectorAll(".reset_checkbox_" + FieldLocation.round)[0].checked = false;
        validate_score_fields();
    }

    const onChangeCustomScoreBox = (e) => {
        var value = e.target.value;
        var class_lists = e.target.className.replace("b-red", '');
        if (value > 1200 || value == 0) {
            e.target.className = class_lists + ' b-red';
        } else {
            e.target.className = class_lists;
        }
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {
                Loading ? <Loader /> :
                    <>
                        <div className='container bg-white pb-8 my-3 p-3'>
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('hand_game')}</h3>
                                    <br />
                                    <h5 className={`text-dark`}>
                                        {ScoreData.game.name} - ({ScoreData.diwaniya.name})
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                {permission.edit ? <th className='text-center b-white'>{t('reset_score')}</th> : ''}
                                                <th className='text-center b-white'>{t('Rounds')}</th>
                                                <>
                                                    {PlayerData.playerList.map((player_name) => (
                                                        <>
                                                            <th className='text-center b-white'>{player_name.name}</th>
                                                        </>
                                                    ))}
                                                </>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {

                                                    PlayerScore.map((score, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                {permission.edit ? <td className='text-center p-2 roundColor'>
                                                                    <input className={`w-auto cursor-pointer reset_checkbox_` + index} title='Reset Round' onChange={(e) => onchecked(e)} value={index} type="checkbox" disabled={editTable ? true : false} />
                                                                </td> : ''}
                                                                <td className='text-center p-2 roundColor'>
                                                                    <input
                                                                        disabled
                                                                        name="bid"
                                                                        value={index + 1}
                                                                        type="text"
                                                                        placeholder="Round"
                                                                    />
                                                                </td>

                                                                {
                                                                    score.map((data, i) => (
                                                                        <td className={'text-center roundColor'}>
                                                                            <input
                                                                                disabled={editTable ? true : false}
                                                                                className={`w-100 p-2 cursor-pointer score_fields score_field_key_` + index}
                                                                                name={'team_'}
                                                                                value={data.score}
                                                                                type="text"
                                                                                onChange={(e) => onChangeInput(e)}
                                                                                onKeyDown={(e) => (KeyDown(e))}
                                                                                onKeyUp={(e) => (keyUp(e))}
                                                                                onClick={(e) => (scoreClick(e))}
                                                                                placeholder="Score"
                                                                                datakey={data.key}
                                                                                dataround={index}
                                                                                readOnly
                                                                                maxlength="3"
                                                                            />
                                                                        </td>
                                                                    ))
                                                                }


                                                            </tr>
                                                        </>
                                                    ))}
                                            </>
                                        </tbody>
                                        <br />
                                        <tfoot>
                                            <tr>
                                                {permission.edit ? <th className='mt-2 bg-dark text-center b-white'></th> : ''}
                                                <th className='mt-2 bg-dark text-center b-white'> {t('total')} </th>
                                                {
                                                    TotalScore.map((total, index) => (
                                                        <>
                                                            <th className='mt-2 bg-dark text-center b-white'> {total} </th>
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div >

                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { setEditTable(true) }} data-bs-target="#staticBackdrop" className='btn btn-danger float-end ms-3'>{t('Cancel')}</button>
                            </div> : ''
                        }
                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : datapost(); }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>
                                    {spinnerIsActive ?
                                        <><i className="spinner-border custom-spinner me-2" role="status"></i>
                                        </>
                                        : ""}
                                    {editTable ? t('edit') : t('save')}
                                </button>
                            </div> : ''
                        }

                    </>
            }

            <Modal show={show} fullscreen="md-down">
                <Modal.Header className='bg-danger text-light custom_close_btn_header' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title className=''>{FieldLocation.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        MinusScoreType ?
                            <div className=''>
                                <div className='score_label' datavalue="-30" onClick={(e) => { selectScore(e) }}>-30</div>
                                <div className='score_label' datavalue="-60" onClick={(e) => { selectScore(e) }}>-60</div>
                                <div className='score_label' datavalue="-120" onClick={(e) => { selectScore(e) }}>-120</div>
                                <div className='score_label' datavalue="-200" onClick={(e) => { selectScore(e) }}>-200</div>
                                <div className='score_label' datavalue="-400" onClick={(e) => { selectScore(e) }}>-400</div>
                                <div className='score_label' datavalue="-600" onClick={(e) => { selectScore(e) }}>-600</div>
                            </div>
                            :
                            <div className=''>
                                <div className='score_label float-none m-auto' datavalue={ScorePair[FieldLocation.value]} onClick={(e) => { selectScore(e) }}>{ScorePair[FieldLocation.value]}</div>
                                <div className=' d-flow-root w-fit-content m-auto mt-3'>
                                    <div className='w-50 float-start'>
                                        <input className='form-control box-shadow custom_score_box' onKeyDown={(e) => (KeyDown(e))} onKeyUp={(e) => (keyUp(e))} onChange={(e) => onChangeCustomScoreBox(e)} type="text" value={CustomScoreBox.value} placeholder="Enter Score" maxlength="4" name="custom_score" />
                                    </div>
                                    <div className='w-50 float-start'>
                                        <button data-bs-toggle="modal" onClick={() => { CustomInput() }} data-bs-target="#staticBackdrop" className='btn btn-dark ms-3 box-shadow'>{t('done')}</button>
                                    </div>
                                </div>
                            </div>
                    }

                </Modal.Body>
            </Modal>

        </>

    )
}

export default GameScore