import React from 'react'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function Datatable() {
    //initialize datatable
    $(document).ready(function () {
        $("#example").DataTable();
    });

    const data = [
        { name: 'Diwaniya Name', type: 'Knout', score: '38', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 1', type: 'Trix', score: '50', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 2', type: 'Hand', score: '100', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 3', type: 'Spade', score: '219', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 4', type: 'Trix', score: '100', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 5', type: 'Hand', score: '200', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 6', type: 'Spade', score: '380', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 7', type: 'Knout', score: '150', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 8', type: 'Knout', score: '18', date: '28-Sept-2022' },
        { name: 'Diwaniya Name 9', type: 'Spade', score: '50', date: '28-Sept-2022' },
    ]

    return (
        <>
            <div
                className="container-fluid pt-3 overflow-auto scrollbar-hidden bg-light"
                style={{ height: "100vh" }}
            >
                <div className="row">
                    <div className="col-md-3 ">
                        <h4 className="fw-600 fsp-22">Activity Log</h4>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        <div className="card  p-4 mb-2">
                            <table id="example" className="display table table-bordered border-top mt-5">
                                <thead>
                                    <tr>
                                        {
                                            Object.keys(data[0]).map((val, index) => (
                                                <th key={index}>{val}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((val, index) => (
                                            <tr key={index}>
                                                <th>{val.name}</th>
                                                <th>{val.type}</th>
                                                <th>{val.score}</th>
                                                <th>{val.date}</th>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Datatable