import React, { useEffect, useState } from 'react'
import DataTable from '../../Components/Datatable/WDatatable';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';

function Add() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            name: t('username'),
            selector: row => row.username.replace(' ', '_'),
            sortable: true,
        },
        {
            name: t('email'),
            selector: row => row.email,
            sortable: true,
        },
        {
            name: t('phone number'),
            selector: row => row.phonenumber,
            sortable: true,
        },
        {
            name: t('address'),
            selector: row => row.address,
            sortable: true,
        },
    ];
    const [selectedPlayer, setSelectedPlayer] = useState([])
    const [playersData, setPlayersData] = useState([]);
    const [diwaniyaData, setDiwaniyaData] = useState([]);
    const [teamData, setteamData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    // Users
    const ids = window.location.href.split('add/')[1];
    const team_id = ids.split('&-_')[0];
    const feachUserData = async () => {
        const diwaniya_id = ids.split('&-_')[1];

        const teamResponse = await Database.get(apiRoute + '/team/', team_id);
        setteamData(teamResponse.data);

        const playerResponse = await Database.get(apiRoute + '/diwaniya/', diwaniya_id);
        setPlayersData(playerResponse.data.players);

        // if (diwaniyResponse.status) {
        //     if (diwaniyResponse.data.length) {
        //         const getTeamData = await Database.get(apiRoute+'/team/', team_id);
        //         if (getTeamData.status) {
        //             setteamData(getTeamData.data);

        //             if (diwaniyResponse.data) {
        //                 if (diwaniyResponse.data.players) {
        //                     diwaniyResponse.data.players.forEach((player, dindex) => {
        //                         if (getTeamData.data) {
        //                             if (getTeamData.data.playersid) {
        //                                 getTeamData.data.playersid.forEach((val, tindex) => {
        //                                     if (player._id === val) {
        //                                         diwaniyResponse.data[0].players.splice(dindex, 1);
        //                                     }
        //                                 })
        //                             }
        //                         }
        //                     })
        //                 }
        //             }
        //         }
        //         setPlayersData(diwaniyResponse.data[0].players);
        //         setDiwaniyaData(diwaniyResponse.data[0]);
        //     }
        // }
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('teams');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        feachUserData();
    }, [])

    // functions

    const selectUSerData = (e) => {
        setSelectedPlayer(e.selectedRows)
    }

    const addUsersInDiwaniya = async () => {

        var players = [];
        // if (teamData) {
        //     if (teamData.playersid) {
        //         teamData.playersid.forEach(element => {
        //             playersid.push({ _id: element });
        //         });
        //     }
        // }


        selectedPlayer.forEach(element => {
            players.push(element._id);
        });

        // console.log(players);
        const ids = window.location.href.split('add/')[1];
        const _id = ids.split('&-_')[0];
        const data = { players };
        const response = await Database.add(apiRoute + '/team/player/' + team_id, data);
        if (response.status) {
            feachUserData();
            enqueueSnackbar(response.message, { variant: 'success' });
            navigate(-1);
        } else {
            enqueueSnackbar(response.error, { variant: 'error' });
        }
    }

    return (
        <div className='container bg-white pb-8 my-3 p-3'>
            <div className='header py-1 pb-2 position-relative'>
                <div className='row'>
                    <div className='col-8'>
                        <h4 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i><Link to="/admin/diwaniya">{teamData ? teamData.name : ''}</Link>/{t('add')} {t('players')}</h4>
                    </div>
                </div>
            </div>
            <DataTable title={`${t('select_players_for_add_in')} ${teamData ? teamData.name : ''} ${t('team')}`} selectable={permission.add} columns={columns} data={playersData} onSelect={selectUSerData} />
            <div className='pb-5 pt-3'>
                {
                    selectedPlayer.length ? (
                        <button onClick={addUsersInDiwaniya} className='btn btn-dark float-end rounded-0 px-4'>{t('save')}</button>
                    ) : (
                        ""
                    )
                }
            </div>
        </div>
    )
}

export default Add