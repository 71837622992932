import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import DataTable from '../../Components/Datatable/WDatatable';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';



function Players() {

  const { enqueueSnackbar } = useSnackbar();
  const [diwaniya, setDiwaniya] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;

  const callData = async () => {
    const _id = window.location.href.split('players/')[1];
    // console.log(_id);
    const response = await Database.get(apiRoute + '/diwaniya/', _id);
    if (response.status) {
      setDiwaniya(response.data);
      if (response.data.players[0] != null) {
        setUsers(response.data.players);
      } else {
        // setUsers(response.data.players[0])
      }
    }

    setLoading(false);
  }
  const { t } = useTranslation()
  const columns = [
    {
      name: t('username'),
      selector: row => row.username.replace(' ', '_'),
      sortable: true,
    },
    {
      name: t('email'),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: t('phone number'),
      selector: row => row.phonenumber,
      sortable: true,
    },
    {
      name: t('address'),
      selector: row => row.address,
      sortable: true,
    },
  ];

  const setPermissoin = async () => {
    var page_permission = await get_permission('diwaniya');
    setPermission(page_permission);
  }

  useEffect(() => {
    setPermissoin();
    callData();
  }, [])

  // Datatble functions
  const selectUSerData = (e) => {
    setSelectedUsers(e.selectedRows);
  }

  const removePlayers = async () => {
    var players = [];
    // var selectedId = [];

    selectedUsers.forEach((element, index) => {
      players.push(element._id);
    })

    // diwaniya.players.forEach((element, index) => {
    //   if (!selectedId.includes(element)) {
    //     playersid.push({ _id: element });
    //   }
    // })


    const _id = window.location.href.split('players/')[1];
    const data = { players };
    const response = await Database.update(apiRoute + '/diwaniya/player/' + _id, data);
    if (response.status) {
      callData();
      enqueueSnackbar("Players Remove Successfully", { variant: 'success' });
    } else {
      enqueueSnackbar(response.error, { variant: 'error' });
    }
  }

  return (
    <>
      <div className='p-3 m-3 bg-white pb-5'>
        <div className='header py-1 pb-2 position-relative d-flex'>
          <h4><Link to="/admin/diwaniya">{diwaniya ? diwaniya.name : ''}</Link> / {t('players')}</h4>
          {
            permission.add ? <div className='px-4'>
              <Link to={"/admin/diwaniya/add/" + diwaniya._id}><button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className='btn btn-dark float-start'>{t('add')} {t('players')}</button></Link>
            </div> : ''
          }
        </div>

        {
          Loading ? <Loader /> : <DataTable
            title={t('select_user')}
            columns={columns}
            data={users}
            selectable={permission.delete}
            onSelect={selectUSerData}
          />
        }
        {
          selectedUsers.length ? (
            <button onClick={removePlayers} className='btn btn-danger rounded-0 float-end my-3'>{t('remove_player')}</button>
          ) : ('')
        }
        <br />
      </div>
    </>
  )
}

export default Players