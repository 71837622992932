import React, { useEffect, useState, useRef } from 'react'
import DataTable from '../../Components/Datatable/WithoutPaginationDatatable';
import Database from '../../Database/Index';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

function AddUser() {

    const navigate = useNavigate();
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();
    const [EditedObject, SetEditedObject] = useState([]);


    const [subAdminData, SetSubAdminData] = useState([])
    const [menuData, setMenuData] = useState([]);


    const rowCheck = useRef();
    const addColCheck = useRef();
    const editColCheck = useRef();
    const deleteColCheck = useRef();

    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    // Users

    const user_id = window.location.href.split('permission/')[1];

    const fetchUserData = async () => {
        const SubAdminResponse = await Database.get(apiRoute + '/subadmin/' + user_id);
        SetSubAdminData(SubAdminResponse.data);
        // console.log(SubAdminResponse.data.permissions);
        if (SubAdminResponse.data.permissions != "") {
            setMenuData(JSON.parse(SubAdminResponse.data.permissions))
        } else {
            const response = await Database.get(apiRoute + '/menu');
            if (response.status) {
                setMenuData(response.data);

            }
        }
    }

    useEffect(() => {
        fetchUserData();

    }, [])

    const handleOnChange = (val, moduleId, position) => {

        var NewObject = [];
        menuData.forEach(element => {
            var ModuleId = element._id;
            if ((moduleId == ModuleId && moduleId != "all" && position == "add") || (moduleId == "all" && position == "add")) {
                Object.keys(element).forEach((key, index) => {
                    element[position] = val.target.checked;
                    // element[key] = element[key] + index;
                    // console.log(val.target);
                });
            }

            if ((moduleId == ModuleId && moduleId != "all" && position == "edit") || (moduleId == "all" && position == "edit")) {
                Object.keys(element).forEach((key, index) => {
                    element[position] = val.target.checked;
                    // element[key] = element[key] + index;
                });
            }
            if ((moduleId == ModuleId && moduleId != "all" && position == "delete") || (moduleId == "all" && position == "delete")) {
                Object.keys(element).forEach((key, index) => {
                    element[position] = val.target.checked;
                    // element[key] = element[key] + index;
                });
            }
            if (moduleId == ModuleId && moduleId != "all" && position == "row") {
                Object.keys(element).forEach((key, index) => {
                    position = "add";
                    element[position] = val.target.checked;
                    position = "edit";
                    element[position] = val.target.checked;
                    position = "delete";
                    element[position] = val.target.checked;
                    // element[key] = element[key] + index;
                    // console.log(val.target);
                });
            }
            NewObject.push(element);
            SetEditedObject(NewObject);

        });

    };

    const columns = [
        {
            name: t('#'),
            selector: row => <>
                <input type="checkbox"
                    onChange={(e) => { handleOnChange(e, row._id, "row") }} ref={rowCheck} className={`custom-control-input getrow rowCheck${row.index}`} id={`rowCheck${row.index}`} />
                {row.index}
            </>,
            sortable: true,
        },
        {
            name: t('module_name'),
            selector: row => t(row.name),
            sortable: true,
        },
        {
            name: <>
                <input onChange={(e) => { handleOnChange(e, 'all', "add"); }} ref={addColCheck} type="checkbox" className="custom-control-input w-20 add" />
                {t('add')}
            </>,
            selector: row => (
                <>
                    <input onChange={(e) => { handleOnChange(e, row._id, "add"); }} checked={row.add != undefined ? row.add : false} type="checkbox" id={`customCheckAdd${row.index}`} className={` custom-control-input`} />

                    <input onChange={(e) => { handleOnChange(e, row._id, "add"); }} checked={false} type="checkbox" id={`customCheckAddDN${row.index}`} disabled className={`d-none custom-control-input`} />


                </>
            ),
            sortable: true,
        },
        {
            name: <>
                <input onChange={(e) => { handleOnChange(e, 'all', "edit"); }} ref={editColCheck} type="checkbox" className="custom-control-input w-20 edit" />
                {t('edit')}
            </>,
            selector: row => (
                <>
                    <input onChange={(e) => { handleOnChange(e, row._id, "edit"); }} checked={row.edit != undefined ? row.edit : false} type="checkbox" id={`customCheckEdit${row.index}`} className={`custom-control-input `} />

                    <input onChange={(e) => { handleOnChange(e, row._id, "edit"); }} checked={false} type="checkbox" id={`customCheckEditDN${row.index}`} disabled className={`d-none custom-control-input `} />
                </>
            ),
            sortable: true,
        },
        {
            name: <>
                <input onChange={(e) => { handleOnChange(e, 'all', "delete"); }} ref={deleteColCheck} type="checkbox" className="custom-control-input w-20 delete" />
                {t('delete')}
            </>,
            selector: row => (
                <>
                    <input onChange={(e) => { handleOnChange(e, row._id, "delete"); }} checked={row.delete != undefined ? row.delete : false} type="checkbox" className={`custom-control-input`} id={`customCheckDelete${row.index}`} />

                    <input onChange={(e) => { handleOnChange(e, row._id, "delete"); }} checked={false} type="checkbox" disabled className={`d-none custom-control-input`} id={`customCheckDeleteDN${row.index}`} />
                </>
            ),
            sortable: true,
        },
    ];



    // functions

    const Save = async () => {



        // const data = { user_id, EditedObject };

        if (user_id) {
            var data = { _id: user_id, permissions: JSON.stringify(EditedObject) };
            const response = await Database.update(apiRoute + '/subadmin/' + user_id, data);
            if (response.status) {
                navigate(-1);
                // fetchUserData();
                enqueueSnackbar(`${subAdminData.name}'s Permissions Updated Successfully`, { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
    }

    // For Hold CheckBox when All Add permissions are Checked.
    var mainCheckAdd = [];
    var mainCheckEdit = [];
    var mainCheckDelete = [];
    for (let index = 0; index < menuData.length; index++) {
        if (menuData[index].add) {
            mainCheckAdd.push(menuData[index].add)
            if (mainCheckAdd.length == menuData.length) {
                addColCheck.current.checked = true;
            } else {
                addColCheck.current.checked = false;


            }
        }
        if (menuData[index].edit) {
            mainCheckEdit.push(menuData[index].edit)
            if (mainCheckEdit.length == menuData.length) {
                editColCheck.current.checked = true;
            } else {
                editColCheck.current.checked = false;


            }
        }
        if (menuData[index].delete) {
            mainCheckDelete.push(menuData[index].delete)
            if (mainCheckDelete.length == menuData.length) {
                deleteColCheck.current.checked = true;
            } else {
                deleteColCheck.current.checked = false;
            }
        }

    }
    var DisableTheseElementForAdd = ['terms','report_manage','card_order','teams'];
    var DisableTheseElementForEdit = [];
    var DisableTheseElementForDelete = ['Game','terms','report_manage','card_order'];

    menuData.forEach((element, index) => {
        if (document.getElementById("rowCheck" + element.index)) {
            if (element.add && element.edit && element.delete) {
                document.getElementById("rowCheck" + element.index).checked = true;
            } else {
                document.getElementById("rowCheck" + element.index).checked = false;
            }
            // console.log(element.name);
            if (DisableTheseElementForAdd.includes(element.name)) {
                // document.getElementById("customCheck" + element.index).disabled = true;
                document.getElementById("customCheckAdd" + element.index).classList.add("d-none");
                document.getElementById("customCheckAddDN" + element.index).classList.remove("d-none");

            }
            if (DisableTheseElementForEdit.includes(element.name)) {
                // document.getElementById("customCheck" + element.index).disabled = true;
                document.getElementById("customCheckEdit" + element.index).classList.add("d-none");
                document.getElementById("customCheckEditDN" + element.index).classList.remove("d-none");

            }
            if (DisableTheseElementForDelete.includes(element.name)) {
                // document.getElementById("customCheck" + element.index).disabled = true;
                document.getElementById("customCheckDelete" + element.index).classList.add("d-none");
                document.getElementById("customCheckDeleteDN" + element.index).classList.remove("d-none");

            }
        }
    });
    // console.log(menuData)
    return (
        <div className='container bg-white pb-8 my-3 p-3'>
            <div className='header py-1 pb-2 position-relative'>
                <div className='row'>
                    <div className='col-8'>
                        <h4 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{subAdminData.length != 0 ? subAdminData.name : ''}</h4>
                    </div>
                </div>
            </div>
            <DataTable filterBy='name' showPagination={false} columns={columns} data={menuData} />
            <div className='pb-5 pt-3'>
                {
                    EditedObject.length ? (
                        <button onClick={Save} className='btn btn-dark float-end rounded-0 px-4'>{t('save')}</button>
                    ) : (
                        ""
                    )
                }
            </div>
        </div>
    )
}

export default AddUser