import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
import { CSVLink } from 'react-csv';
const axios = require("axios");

function Index() {

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userData, setUserData] = useState([]);
    const [userDataForCsv, setUserDataForCsv] = useState([]);
    const [userValue, setUserValue] = useState({ username: '', address: '', phonenumber: '', email: '', image: '', id: '' });
    const [modalTitle, setModalTitle] = useState('add');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [formdata, setFormData] = useState([]);
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const [uploadImageLoader, setUploadImageLoader] = useState(false);

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/player';
    const lang = localStorage.getItem('lang');
    // get User data
console.log(lang);
    const fetchUserData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setUserData(response.data);
            setUserDataForCsv(response.data);
            setTotalRows(response.total_records);
        }
        setLoading(false);
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('players');
        setPermission(page_permission);
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchUserData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchUserData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchUserData(perPage, 1, val);
    }

    // delete
    const deletePlayer = async (id) => {

        enqueueSnackbar(t('delete_comfirmation'), {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(id); closeSnackbar(key); }}>
                        Delete
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (id) => {
        const response = await Database.delete(apiRoute + '/', id);
        if (response.status) {
            enqueueSnackbar(t('delete_success'), { variant: 'success' });
            fetchUserData(perPage, page);
        }
    }

    useEffect(() => {
        setPermissoin();
        fetchUserData(perPage, page, search);

    }, [])

    const inputHandle = async (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }

    const removeImage = (e) => {
        const formdata = new FormData();
        setUploadImageLoader(true);
        formdata.append("id", userValue.id);
        formdata.append("username", userValue.username);
        formdata.append("address", userValue.address);
        formdata.append("email", userValue.email);
        formdata.append("image", "");
        setFormData(formdata);
        axios
            .put(apiRoute + '/' + userValue.id,
                formdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.M3RRMHdLTWZ6dQ.3KYGKye5acseMF2meWYZ2TvE_UvVaPei2yZS3t3T160"
                    },
                }
            )
            .then((res) => {
                if (res.data.length != 0) {
                    fetchUserData(perPage, page, search);
                    enqueueSnackbar('Removed', { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar("Image name Already Exist !", { variant: 'error' });
                }
            })
    };

    const datapost = async (e) => {
        e.preventDefault();
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userValue.username.length < 5 || userValue.username.trim() == "") {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }
        if (userValue.email != undefined && userValue.email != '' &&!userValue.email.match(validRegex)) {
            return enqueueSnackbar(t('invalid_email'), { variant: 'error' });
        }
        if (userValue.phonenumber.length < 8) {
            return enqueueSnackbar(t('invalid_phone'), { variant: 'error' });
        }
        if (userValue.id) {
            var data = { id: userValue.id, username: userValue.username.replace(/\s+/g, ""), email: userValue.email, address: userValue.address };
            const response = await Database.update(apiRoute + '/' + userValue.id, data);
            if (response.status) {
                fetchUserData(perPage, page, search);
                enqueueSnackbar(response.message, { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        else {
            var data = { id: userValue.id, username: userValue.username.replace(/\s+/g, ""), email: userValue.email, address: userValue.address, phonenumber: userValue.phonenumber };
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                fetchUserData(perPage, page, search);
                enqueueSnackbar(response.message, { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }

    }

    // For Craete Array to Export CSV
    var CsvArray = [];
    for (let index = 0; index < userDataForCsv.length; index++) {
        var newArr = {};
        newArr.address = userDataForCsv[index].address;
        newArr.username = userDataForCsv[index].username;
        newArr.email = userDataForCsv[index].email;
        newArr.phonenumber = userDataForCsv[index].phonenumber;
        newArr.verified = userDataForCsv[index].verified;
        newArr.createdAt = userDataForCsv[index].createdAt;
        newArr.updatedAt = userDataForCsv[index].updatedAt;
        CsvArray.push(newArr);
    }

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////

    // columns

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('username'),
            selector: row => row.username,
            sortable: true
        },
        {
            name: t('email'),
            selector: row => row.email || "-",
            sortable: true
        },
        {
            name: t('phone number'),
            selector: row => row.phonenumber,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {
                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deletePlayer(row._id)}><i className='bi bi-trash'></i></button> : ''
                    }
                    {
                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { setUserValue({ username: row.username, email: row.email, phonenumber: row.phonenumber, address: row.address, image: row.image, id: row._id }); handleShow(); setModalTitle('update'); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i className='bi bi-pencil'></i>
                        </button> : ''
                    }

                </>
        },
    ];

    // Remove action while user don`t have any permissions
    if (!permission.edit && !permission.delete) { columns.pop(columns.length - 1); }

    const numberKeyDown = (event) => {
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) & event.keyCode != 8) {
            event.preventDefault();
        }
    }



    const uploadFile = (event) => {
        const formdata = new FormData();
        formdata.append("image", event.target.files[0]);
        setFormData(formdata);
        axios
            .post(apiRoute,
                formdata, +
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                },
            }
            )
            .then((res) => {
                if (res.data.length != 0) {
                    enqueueSnackbar(t('add_success'), { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar(t('name_exist'), { variant: 'error' });
                }
            })
    };


    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('players')}</h3>

                    {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setUserValue({ username: '', email: '', phonenumber: '', address: '', image: '' }); handleShow(); setModalTitle('add') }} className='btn btn-dark float-start'>{t('add')}</button>
                        </div> : ''
                    }
                </div>
                {
                    Loading ? <Loader /> : <>
                        <div className={lang == "en" ? "float-end" : "float-start"}>
                            <CSVLink data={CsvArray} download="Players List" ><button className="btn btn-dark">{t('export_csv')}</button></CSVLink>
                        </div>

                        <input
                            style={{
                                zIndex: "1",
                                width: "16%",
                                float: "right",
                                position: "absolute",
                                right: "135px",
                                opacity: "0"
                            }}
                            accept=".csv"
                            onChange={(e) => {
                                uploadFile(e);
                                // setUpdateId(row._id);
                            }}
                            type="file"
                            className="form-control me-2"
                        />



                        {/* {RoundLoading ? <RoundLoader /> : ''} */}

                        <Datatable columns={columns} data={userData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} loadertop="t506" />
                    </>
                }

            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t(modalTitle)} {t('players')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>
                        <div className='form-group'>
                            <input type="text" name="username" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Username' onChange={inputHandle} value={userValue.username} />
                            <span className='invalid-feedback'>Please Enter Username</span>
                        </div>
                        <div className='form-group'>
                            <input type="email" name="email" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Email' onChange={inputHandle} value={userValue.email} />
                            <span className='invalid-feedback'>Please Enter Email </span>
                        </div>
                        <div className='form-group'>
                            <input type="text" size="8" minlength="8" maxlength="8" name="phonenumber" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Phone number' onKeyDown={(e) => (numberKeyDown(e))} onChange={inputHandle} value={userValue.phonenumber} />
                            <span className='invalid-feedback'>Please Enter Phone Number</span>
                        </div>
                        <div className='form-group'>
                            <input type="text" name="address" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder='Address' onChange={inputHandle} value={userValue.address} />
                            <span className='invalid-feedback'>Please Enter Your address</span>
                        </div>
                        <div className='form-group'>
                            {userValue.image != "" ?
                                <>
                                    <div>

                                        <button className='btn-sm btn-danger' onClick={removeImage} type='button'>Remove <i className='bi bi-x'></i></button>
                                    </div>
                                    <img src={userValue.image} alt="" width={400} />
                                </> : ''
                            }

                        </div>
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default Index
