import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { json, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import swal from 'sweetalert';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
import QRCode from 'react-qr-code'
const axios = require("axios");



function Index() {
    const [qrText, setQrText] = useState({});
    var test = {
        id: 434343324343,
        diwaniya: "Test"
    }
    const [show, setShow] = useState(false);
    const [qrshow, setQRShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setFiledata("");
    }
    const handleQRShow = () => setQRShow(true);
    const handleQRClose = () => setQRShow(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()
    const [diwaniya, setDiwaniya] = useState({ name: '', address: '', id: '', image: '' });
    const [diwaniyaData, setDiwaniyaData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [progressPending, setprogressPending] = useState(true);
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const [uploadImageLoader, setUploadImageLoader] = useState(false);
    const [formdata, setFormData] = useState([]);
    const [filedata, setFiledata] = useState();


    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/diwaniya';
    // for get data
    const callData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setDiwaniyaData(response.data);
            setTotalRows(response.total_records);
        }
        setLoading(false);
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        callData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        callData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        callData(perPage, 1, val);
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('diwaniya');
        setPermission(page_permission);
    }

    const uploadFile = (e) => {
        setFiledata(URL.createObjectURL(e.target.files[0]));
        if(!e.target.files[0].type.includes("image")){
            setFiledata("");
            e.target.value = "";
           return enqueueSnackbar("Please Select Image Only!", { variant: 'error' });
        }
        const formdata = new FormData();

        formdata.append("id", diwaniya.id);
        formdata.append("name", diwaniya.name);
        formdata.append("address", diwaniya.address);
        // formdata.append("player_id", localStorage.getItem('user'));
        formdata.append("image", e.target.files[0]);
        setFormData(formdata);
    };

    const removeImage = (e) => {
        setUploadImageLoader(true);
        const formdata = new FormData();
        setUploadImageLoader(true);
        formdata.append("id", diwaniya.id);
        formdata.append("name", diwaniya.name);
        formdata.append("address", diwaniya.address);
        // formdata.append("player_id", localStorage.getItem('user'));
        formdata.append("image", "");
        setFormData(formdata);
        axios
            .put(apiRoute + '/' + diwaniya.id,
                formdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.M3RRMHdLTWZ6dQ.3KYGKye5acseMF2meWYZ2TvE_UvVaPei2yZS3t3T160"
                    },
                }
            )
            .then((res) => {
                if (res.data.length != 0) {
                    callData();
                    enqueueSnackbar('Image Add Successfully.', { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar("Image name Already Exist !", { variant: 'error' });
                }
                setUploadImageLoader(false);

            })
    };

    useEffect(() => {
        setQrText(JSON.stringify(test));
        setPermissoin();
        callData(perPage, page, search);
    }, [])

    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setDiwaniya({ ...diwaniya, [name]: value });
    }

    const datapost = async (e) => {
        e.preventDefault();
        setUploadImageLoader(true);
        if (diwaniya.name.length < 5 || diwaniya.name.trim() == "") {
            setUploadImageLoader(false);
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }
        if (diwaniya.name.length > 25 || diwaniya.name.trim() == "") {
            setUploadImageLoader(false);
            return enqueueSnackbar(t('less_than_25'), { variant: 'error' });
        }
        const data = { id: diwaniya.id, name: diwaniya.name, address: diwaniya.address, image: diwaniya.image };
        if (diwaniya.id) {
            axios
                .put(apiRoute + '/' + diwaniya.id,
                    formdata.length == 0 ? data : formdata,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.M3RRMHdLTWZ6dQ.3KYGKye5acseMF2meWYZ2TvE_UvVaPei2yZS3t3T160"
                        },
                    }
                )
                .then((res) => {
                    if (res.data.length != 0) {
                        callData();
                        enqueueSnackbar('Image Add Successfully.', { variant: 'success' });
                        handleClose();
                        setUploadImageLoader(false);
                    } else {
                        enqueueSnackbar("Image name Already Exist !", { variant: 'error' });
                    }
                })
            // const response = await Database.update(apiRoute + '/' + diwaniya.id, data);
            // if (response.status) {
            //     callData(perPage, page, search);
            //     enqueueSnackbar(t('update_success'), { variant: 'success' });
            //     handleClose();
            // } else {
            //     enqueueSnackbar(response.message, { variant: 'error' });
            // }
        }
        else {
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                callData(perPage, page, search);
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }

    }

    const deleteDiwaniya = async (id) => {

        swal({
            title: t('delete_comfirmation'),
            text: t('delete_text'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {

            if (willDelete) {
                const response = await Database.delete(apiRoute + '/', id);
                if (response.status) {
                    callData(perPage, page, search);
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.message,
                        icon: "error",
                    });

                }
                swal(t('delete_success'), {
                    icon: "success",
                });

            }
        });

    }

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true
        },
        // {
        //     name: t('address'),
        //     selector: row => row.address ? row.address : '_ _ _ _ _ _',
        //     sortable: true
        // },
        {
            name: t('teams'),
            selector: row => <>
                <Link to={`/admin/diwaniya/teams/${row._id}`} className='btn btn-primary btn-sm'><i className='bi bi-people'></i></Link>
            </>,
        },
        {
            name: t('created_at'),
            selector: row => moment.utc(row.createdAt).format("MMMM DD YYYY"),
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {
                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteDiwaniya(row._id)}><i className='bi bi-trash'></i></button> : ''
                    }
                    {
                        permission.edit ? <button className='btn btn-secondary btn-sm mx-1' onClick={() => { setQrText(JSON.stringify({ id: row._id, name: row.name })); handleQRShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-qr-code'></i></button> : ''
                    }
                    {
                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { setDiwaniya({ name: row.name, address: row.address, id: row._id, image: row.image }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button> : ''
                    }
                    {
                        row.player_id.length ? (
                            <Link to={`/admin/diwaniya/players/${row._id}`} className='btn btn-primary btn-sm mx-1'><i className='bi bi-eye'></i></Link>
                        ) : (
                            <button onClick={() => enqueueSnackbar(t('player_not_found'), { variant: 'error' })} className='btn btn-primary btn-sm mx-1'><i className='bi bi-eye'></i></button>
                        )
                    }
                    {
                        permission.add ? <Link to={`/admin/diwaniya/add/${row._id}`} className='btn btn-dark btn-sm mx-1'><i className='bi bi-person-plus'></i></Link> : ''
                    }
                </>
        },
    ];

    if (!permission.add) { columns.pop(3); }
    return (
        <>

            <div className='container bg-white p-3 mt-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <img src="/assets/images/Diwaniya.png" alt='' className='w-40 bg-warning p-1 mx-3' />{t('diwaniya')}</h3>
                    <div className='px-3 d-none'>
                        <button data-bs-toggle="modal" onClick={() => { setDiwaniya({ name: '', address: '' }); handleShow(); }} data-bs-target="#staticBackdrop" className='btn btn-dark'>{t('add')} {t('diwaniya')}</button>
                    </div>
                </div>
                {
                    Loading ? <Loader /> :
                        <>
                            {/* {RoundLoading ? <RoundLoader /> : ''} */}
                            <Datatable columns={columns} data={diwaniyaData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} />
                        </>
                }
            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t('edit') + " " + t('diwaniya')}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <form className='form' method='post' id='diwniyaForm' onSubmit={datapost} encType="multipart/form-data" >
                        <div className='form-group'>
                            <input type="text" name="name" id='diwaniyaName' className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('diwaniya') + " " + t('name')} onChange={inputHandle} value={diwaniya.name} />
                            <span className='invalid-feedback'>Please Enter Diwaniya Name</span>
                        </div>
                        <div className='form-group'>
                            <input type="text" name="address" id='diwaniyaAddress' className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('address')} onChange={inputHandle} value={diwaniya.address} />
                            <span className='invalid-feedback'>Please Enter Diwaniya Address</span>
                        </div>
                        <div className='form-group'>

                            <span className='invalid-feedback'>Please Enter Diwaniya Address</span>
                        </div>
                        <div className='form-group'>
                            <input type="file" accept="image/*" name="image" id='diwaniyaAddress' className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('address')} onChange={(e) => uploadFile(e)} />
                            <img src={filedata} width={450} />
                            {diwaniya.image != "" && filedata == "" ?
                                <>
                                    <div>
                                        <button type="button" className="  btn-danger bg-danger position-absolute top-40 end-0 me-5 mt-2" onClick={removeImage} aria-label="Close">X</button>


                                    </div>
                                    <img src={diwaniya.image} alt="" width={450} />
                                </> : ''
                            }

                        </div>
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            {uploadImageLoader ? <RoundLoader position="relative" /> : <button type="submit" className="btn btn-danger rounded-0 px-4">{t('save')}</button>}


                        </div>
                    </form>

                </Modal.Body>
            </Modal>
            <Modal show={qrshow}>
                <Modal.Header closeButton onClick={() => { handleQRClose(); }}>
                    <Modal.Title>QR CODE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row m-auto align-cener">
                            <QRCode size="400" value={qrText} />

                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Index