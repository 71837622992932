import React, { useEffect, useState } from 'react'
import '../History/DataTableDemo.css';
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import Database from '../../Database/Index';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import './card_qr.css';

const axios = require("axios");

const API_URL = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
const UPLOAD_ENDPOINT = "upload";
function CardQR() {
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [ValidCode, setValidCode] = useState(false);
    const [FileValid, setFileValid] = useState(true);
    const [CodeMessage, setCodeMessage] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [blog, setBlog] = useState([]);
    const [get_blog, getBlog] = useState([]);
    const [description, setdescription] = useState("");
    const [TempCode, setTempCode] = useState("");
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const [CardQr, setCardQr] = useState({ id: '', type: '', name: '', url: '', code: '' });
    const [CardNameList, setCardNameList] = useState([]);
    const [Image, setImage] = useState('');
    const [FileType, setFileType] = useState('url');
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/card_qr';

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    // get blog data
    const fetchBlogData = async (per_Page = perPage, Page = page, filtersearch = search) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setBlog(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }

    }

    // delete
    const deleteBlog = async (id) => {
        enqueueSnackbar(t('delete_comfirmation'), {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(id); closeSnackbar(key); }}>
                        {t('delete')}
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        {t('dismiss')}
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (id) => {
        const response = await Database.delete(apiRoute + '/', id);
        if (response.status) {
            enqueueSnackbar(response.message, { variant: 'success' });
            fetchBlogData();
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchBlogData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchBlogData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchBlogData(perPage, 1, val);
    }
    const setPermissoin = async () => {
        var page_permission = await get_permission('card_qr');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        fetchBlogData(perPage, page, search);
    }, [])

    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setCardQr({ ...CardQr, [name]: value });
    }


    const datapost = async (e) => {
        e.preventDefault();
        setspinnerIsActive(true);
        if (!ValidCode) {
            setspinnerIsActive(false);
            return enqueueSnackbar(CodeMessage, { variant: 'error' });
        }
        if (CardQr.type.trim() == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('Please_select_card_type'), { variant: 'error' });
        }

        if (CardQr.name.trim() == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('Please_select_card_name'), { variant: 'error' });
        }

        if (CardQr.file_type == undefined) {
            setCardQr({ ...CardQr, file_type: 'url' });
        }

        if (CardQr.file_type == 'url' && CardQr.image != undefined &&  CardQr.image.trim() == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('Please_enter_link'), { variant: 'error' });
        }

        if (CardQr.file_type != 'url') {
            if (e.target.elements.url.files == 0) {
                if (CardQr.file_type != 'image') {
                    setspinnerIsActive(false);
                    return enqueueSnackbar(t('Please_choose_image'), { variant: 'error' });
                } else {
                    setspinnerIsActive(false);
                    return enqueueSnackbar(t('Please_choose_video'), { variant: 'error' });
                }
            }
        }

        if (CardQr.code.trim() == "") {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('Please_select_card_code'), { variant: 'error' });
        }

        const formdata = new FormData(e.target);
        formdata.append("type", CardQr.type);
        if (CardQr._id != undefined && CardQr._id != '') {
            formdata.append("id", CardQr._id);
        }

        if (CardQr.file_type != 'url' && !FileValid) {
            setspinnerIsActive(false);
            return enqueueSnackbar(t('please_select_valid_file'), { variant: 'error' });
        }
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
            'language': localStorage.getItem('lang')
        }

        await axios.put(apiRoute, formdata, {
            headers: headers
        })
            .then((res) => {
                if (res.data.status) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    handleClose();
                    fetchBlogData();
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' });
                }
            })
            .catch((error) => {

            });
            setspinnerIsActive(false);
    }

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('type'),
            selector: row => row.type,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true
        },
        {
            name: t('file_type'),
            selector: row => (row.file_type).toUpperCase(),
            sortable: true
        },
        {
            name: t('code'),
            selector: row => row.code,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {

                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteBlog(row._id)}><i className='bi bi-trash'></i></button> : ''
                    }
                    {

                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { edit_card_qr(row); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i className='bi bi-pencil'></i>
                        </button> : ''
                    }

                </>
        },
    ];

    const card_name = ['Ace', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Jack', 'Queen', 'King',];
    const joker_card_name = ['Joker 1', 'Joker 2'];
    const qr_codes = ['QRPCDA', 'QRPCD2', 'QRPCD3', 'QRPCD4', 'QRPCD5', 'QRPCD6', 'QRPCD7', 'QRPCD8', 'QRPCD9', 'QRPCD10', 'QRPCDJ', 'QRPCDQ', 'QRPCDK', 'QRPCCA', 'QRPCC2', 'QRPCC3', 'QRPCC4', 'QRPCC5', 'QRPCC6', 'QRPCC7', 'QRPCC8', 'QRPCC9', 'QRPCC10', 'QRPCCJ', 'QRPCCQ', 'QRPCCK', 'QRPCSA', 'QRPCS2', 'QRPCS3', 'QRPCS4', 'QRPCS5', 'QRPCS6', 'QRPCS7', 'QRPCS8', 'QRPCS9', 'QRPCS10', 'QRPCSJ', 'QRPCSQ', 'QRPCSK', 'QRPCHA', 'QRPCH2', 'QRPCH3', 'QRPCH4', 'QRPCH5', 'QRPCH6', 'QRPCH7', 'QRPCH8', 'QRPCH9', 'QRPCH10', 'QRPCHJ', 'QRPCHQ', 'QRPCHK', 'QRPCJJO1', 'QRPCJJO2'];

    const card_type = [
        {
            type: 'Diamond',
            names: card_name
        },
        {
            type: 'Clover',
            names: card_name
        },
        {
            type: 'Spade',
            names: card_name
        },
        {
            type: 'Heart',
            names: card_name
        },
        {
            type: 'Joker',
            names: joker_card_name
        },

    ];

    const card_type_change = (e) => {
        var value = e.target.value;
        var type = '';
        var names = [];
        if (value != '') {
            type = card_type[value].type;
            names = card_type[value].names;
        }
        setCardNameList(names);
        setCardQr({ ...CardQr, type: type });

    }

    const validate_code = async (e) => {
        var code = e.target.value;
        if (CardQr.id == undefined) {
            setValidCode(false);
            if (!qr_codes.includes(code)) {
                setCodeMessage(t('please_enter_valid_code'));
                enqueueSnackbar(t('please_enter_valid_code'), { variant: 'error' });
                return;
            }

            const response = await Database.get(apiRoute + '/code_exist?code=' + code);
            if (response.status) {
                setValidCode(response.exist);
                if (!response.exist) {
                    setCodeMessage(response.message);
                    enqueueSnackbar(response.message, { variant: 'error' });
                    return;
                }
            }
        }
        setValidCode(true);
        setCodeMessage('');

    }
    function fileValidation(e) {
        setFileValid(true);
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        var type = e.target.files['0'].type;
        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
        let allowedExtensionvideo = ['video/webm', 'video/mp4'];
        if (CardQr.file_type == 'image') {
            if (allowedExtension.indexOf(type) == -1) {
                setFileValid(false);
                return enqueueSnackbar(t('please_select_valid_image'), { variant: 'error' });
            }
        }

        if (CardQr.file_type == 'video') {
            if (allowedExtensionvideo.indexOf(type) == -1) {
                setFileValid(false);
                return enqueueSnackbar(t('please_select_valid_video'), { variant: 'error' });
            }
        }
    }

    const edit_card_qr = (data) => {
        setspinnerIsActive(false);
        setFileValid(true);
        setCardQr(data)
        for (let index = 0; index < card_type.length; index++) {
            if (card_type[index].type == data.type) {
                var names = card_type[index].names;
                setCardNameList(names);
            }
        }

        if (data.file_type == 'image') {
            setImage(API_URL+'/'+data.url);
        } else {
            setImage('');

        }

        // setCardQr({ ...CardQr, image: [data.url] })
        setTempCode(data.code);
        setValidCode(true);
        setCodeMessage('');
    }

    // Remove action while user don`t have any permissions
    if (!permission.edit && !permission.delete) { columns.pop(columns.length - 1); }

    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-qr-code-scan ms-2`}></i>Card QR Codes</h3>

                    {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className='btn btn-dark float-start' onClick={() => { setCardQr({}); handleShow(); setdescription(""); setImage(''); }}>{t('add')}</button>
                        </div> : ''
                    }
                </div>

                {
                    Loading ? <Loader /> :
                        <>
                            {/* {RoundLoading ? <RoundLoader /> : ''} */}
                            <Datatable columns={columns} data={blog} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} />
                        </>
                }

            </div>
            <Modal show={show} fullscreen="xxl-down">
                <Modal.Header className='bg-danger text-light custom_close_btn_header' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{CardQr._id == undefined ? t('add') : t('update')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>
                        <div className='form-group mb-3'>
                            <select className="form-control  w-100 d-inline" aria-label="Default select example" name='card_type' onChange={(e) => { card_type_change(e); }} required>
                                <option value="">{t('choose_card_type')}</option>
                                {
                                    card_type.map((value, index) => (
                                        <option value={index} selected={CardQr.type == value.type}>{value.type}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className='form-group mb-3'>
                            <select className="form-control  w-100 d-inline" aria-label="Default select example" name='name' onChange={inputHandle} required>
                                <option value="">{t('choose_card_name')}</option>
                                {
                                    CardNameList.length ? CardNameList.map((value, index) => (
                                        <option value={value} selected={CardQr.name == value}>{value}</option>
                                    )) : ''
                                }
                            </select>
                        </div>

                        <div className='form-group mb-3'>
                            <select className="form-control  w-100 d-inline" aria-label="Default select example" name='file_type' onChange={(e) => { setFileType(e.target.value); inputHandle(e); }} data-filetype={CardQr.file_type} required>
                                <option selected={CardQr.file_type == 'url'} value="url">{t('url')}</option>
                                <option selected={CardQr.file_type == 'image'} value="image">{t('image')}</option>
                                <option selected={CardQr.file_type == 'video'} value="video">{t('video')}</option>
                            </select>
                        </div>

                        <div className='form-group mb-3'>
                            {
                                CardQr.file_type == 'url' || CardQr.file_type == undefined ?
                                    <input type="text" name="url" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('card_link')} onChange={(e) => { inputHandle(e); }} value={CardQr.url} required />
                                    :
                                    <div className='row'>
                                        <div className={CardQr.file_type == 'image' ? 'col-8' : 'col-12'}>
                                            <input type="file" name="url" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('card_link')} onChange={(e) => { fileValidation(e) }} required={CardQr._id != undefined && CardQr._id != undefined ? false : true} />
                                        </div>
                                        {CardQr.file_type == 'image' ?
                                            <div className='col-4'>
                                                <img className='w-100' src={Image} />
                                            </div>
                                            : ''
                                        }
                                    </div>

                            }

                            <span className='invalid-feedback'>{t('card_link')}</span>
                        </div>

                        <div className='form-group mb-3'>
                            <input type="text" name="code" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('card_code')} onChange={(e) => { inputHandle(e); }} onBlur={validate_code} value={CardQr.code} required />
                            <span className='invalid-feedback'>{t('card_code')}</span>
                        </div>
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" disabled={!ValidCode} className="btn btn-danger rounded-0 px-4">
                            {spinnerIsActive ?
                                        <><i className="spinner-border custom-spinner me-2" role="status"></i>
                                        </>
                                        : ""}
                                    {t('save')}
                         </button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default CardQR