import React from 'react'
import DataTable from '../../Components/Datatable/WsDatatable'
import PageHeader from '../../Components/PageHeader'

function Score() {

    const data = [];
    const GameList = ['Hand', 'Trix', 'Spade']
for (let index = 0; index < 10; index++) {
    var game_index = Math.floor(Math.random() * 3);
    var key_index = index+1;
    data.push({ Date: '1'+index+'-Aug-2022', Diwaniya_Name: 'Diwaniya Name'+key_index, Game_Type: GameList[game_index], Score: Math.floor(Math.random() * 990 + 10)});
}

    return (
        <>
            <div className='container bg-white pb-8 my-3'>
                <PageHeader title="History" text="" icon="table" />
                <DataTable data={data} />
            </div>
        </>
    )
}

export default Score