import React, { useEffect, useState, useRef } from 'react'
import './chatbox.css';
import Database from '../../Database/Index';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom'
import Loader from '../../Components/Loader';
import get_permission from '../../Components/Permission';


const ChatBox = () => {
    const ids = window.location.href.split('chatbox/')[1];
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [faq, setFaq] = useState([]);
    const [userDetail, setuserDetail] = useState([]);
    const [AdminReply, setAdminReply] = useState("");
    const [Loading, setLoading] = useState(true);
    const [sendButton, setsendButton] = useState(false);
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

    const { t } = useTranslation()
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/reportdeskmanagement';
    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    // get faq data
    const fetchFaqData = async () => {
        const response = await Database.get(apiRoute + '/' + ids);
        if (response.status) {
            setFaq(response.data.report_desk_management_logs);
            setuserDetail(response.data);
            setLoading(false);
        }

    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('report_desk_management');
        if(!page_permission.edit) {
            navigate('/admin/report_desk_management');
        }
        setPermission(page_permission);
    }


    useEffect(() => {
        setPermissoin();
        fetchFaqData();
    }, [])

    const handleOnChange = (val) => {
        setAdminReply(val.target.value);


    }
    const TextArea = useRef("");

    const datapost = async (e) => {
        setsendButton(true);
        TextArea.current.focus();

        e.preventDefault();
        if (AdminReply.length < 0 || AdminReply.trim() == "") {
            return enqueueSnackbar(t('not_empty'), { variant: 'error' });
        }

        const data = { replied_from: "admin", reply: AdminReply };
        const response = await Database.add(apiRoute + '/' + ids, data);
        if (response.status) {
            setsendButton(false);

            fetchFaqData();
            setAdminReply("");
            TextArea.current.value = "";
        }
        else {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    }

    const listItems = faq.map((faq_data, index) =>
        <>
            <li className={`${faq_data.replied_from != "admin" ? "in" : "out pe-2"}`}>
                <div className="chat-img">
                    <img alt="Avtar" src="https://bootdey.com/img/Content/avatar/avatar1.png" />
                </div>
                <div className="chat-body">
                    <div className="chat-message">
                        {/* <h5>Jimmy Willams</h5> */}
                        <p>{faq_data.reply}</p>
                    </div>
                </div>
            </li>
        </>
    );
    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-3">
                    <div className="card">
                        <div className="card-header">{t('chat_with')} {userDetail.length != 0 ? userDetail.players.username : ''}</div>
                        <div className="card-body height3">
                            {Loading ? <Loader /> : <ul className="chat-list overflow-auto scrollbar scrollbar-danger" style={{ height: "50vh" }}>
                                {listItems}
                            </ul>}

                            <form className='form' method='post' onSubmit={datapost}>
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="form-outline">
                                            <textarea className="form-control" onChange={(e) => { handleOnChange(e);setsendButton(false); }} placeholder={t('type_your_message')} ref={TextArea} id="textAreaExample" rows="4"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <button type="submit" disabled={sendButton} data-bs-dismiss="modal1" className="btn btn-danger rounded-circle mt-4 "><i class="bi bi-send"></i></button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>


            </div>
        </>

    )
}

export default ChatBox