import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import Database from '../../Database/Index';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';
function AddUser() {
    const navigate = useNavigate();

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();
    const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setpage] = useState(1);
	const [search, setSearch] = useState('');
    const [Loading, setLoading] = useState(true);

    const columns = [
        {
            name: t('username'),
            selector: row => row.username,
            sortable: true,
        },
        {
            name: t('email'),
            selector: row => row.email,
            sortable: true,
        },
        {
            name: t('phone number'),
            selector: row => row.phonenumber,
            sortable: true,
        },
        {
            name: t('address'),
            selector: row => row.address,
            sortable: true,
        },
    ];

    const [selectedPlayer, setSelectedPlayer] = useState([])
    const [userData, setUserData] = useState([]);
    const [diwaniyaData, setDiwaniyaData] = useState([]);

    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    // Users
    const feachDiwaniyaData = async () => {
        const response = await Database.get(apiRoute + '/diwaniya');
        if (response.status) {
            const _id = window.location.href.split('add/')[1];
            const diwaniyResponse = await Database.get(apiRoute + '/diwaniya/', _id);
            if (diwaniyResponse.status) {
                setDiwaniyaData(diwaniyResponse.data);
            }
        }
        setLoading(false);
    }

    const fetchUserData = async (perPage, page, filtersearch) => {
        const response = await Database.get(apiRoute + '/player'+'?page='+page+'&per_page='+perPage+'&search='+filtersearch);
        if (response.status) {
            response.data.diwaniyaData = diwaniyaData;
            setTotalRows(response.total_records);
            setUserData(response.data);
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
		fetchUserData(newPerPage, page, search);
	};

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
		fetchUserData(perPage, page, search); 
	};

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchUserData(perPage, 1, val);
    }

    useEffect(() => {
        feachDiwaniyaData();
        fetchUserData(perPage, page, search);
    }, [])

    // functions

    const selectUSerData = (e) => {
        // console.log('selectedRows = ',e.selectedRows);
        setSelectedPlayer(e.selectedRows)
    }
    const addUsersInDiwaniya = async () => {

        var players = [];
        selectedPlayer.forEach(element => {
            players.push(element._id);
        });

        const diwaniya_id = window.location.href.split('add/')[1];
        const data = { diwaniya_id, players };
        const response = await Database.add(apiRoute + '/diwaniya/player/' + diwaniya_id, data);
        if (response.status) {
            feachDiwaniyaData();
            fetchUserData(perPage, page, search);
            enqueueSnackbar(response.message, { variant: 'success' });
            navigate(-1);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    }
    return (
        <div className='container bg-white pb-8 my-3 p-3'>
            <div className='header py-1 pb-2 position-relative'>
                <div className='row'>
                    <div className='col-8'>
                        <h4 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i><Link to="/admin/diwaniya">{diwaniyaData.length != 0 ? diwaniyaData.name : ''}</Link>/{t('add')} {t('players')}</h4>
                    </div>
                </div>
            </div>

            {
                Loading ? <Loader /> : <Datatable title={`Select Players for add in ${diwaniyaData.length != 0 ? diwaniyaData.name : ''} Diwaniya`} columns={columns} data={userData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} isCheckbox={true} onSelect={selectUSerData} DatatableClass={`selectDatatable`} />
            }
            <div className='pb-5 pt-3'>
                {
                    selectedPlayer.length ? (
                        <button onClick={addUsersInDiwaniya} className='btn btn-dark float-end rounded-0 px-4'>{t('save')}</button>
                    ) : (
                        ""
                    )
                }
            </div>
        </div>
    )
}

export default AddUser