import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import { Link } from 'react-router-dom';
import Database from '../../Database/Index';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
import { useSnackbar } from 'notistack';
import { Modal } from "react-bootstrap";

function CardOrder() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const { t } = useTranslation();
    const [reportData, setReportData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/ordercard';
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

    // for get data
    const callData = async (perPage, page, filtersearch) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + page + '&per_page=' + perPage + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setReportData(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        callData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        callData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        callData(perPage, 1, val);
    }

    const datapost = async (id, status) => {
        if (status == "complete") {
            return enqueueSnackbar(t('status_already_updated'), { variant: 'warning' });
        }
        const data = { status: "complete" };
        const response = await Database.put(apiRoute + '/' + id, data);
        if (response.status) {
            callData(perPage, page, search);
            enqueueSnackbar(response.message, { variant: 'success' });
        }
        else {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('card_order');
        setPermission(page_permission);
    }
    useEffect(() => {
        setPermissoin();
        callData(perPage, page, search);
    }, [])

    const [selectFilter, SetSelectFilter] = useState('updatedAt');


    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('email'),
            selector: row => row.email ? row.email : "-",
            sortable: true
        },
        // {
        //     name: 'Address',
        //     selector: row => row.address,
        //     sortable: true
        // },
        {
            name: t('Status'),
            selector: row => <>{row.status == "complete" ? (<span className='text-success'>{t('Complete')}</span>) : (row.status == "processing") ? (<span className='text-warning'>{t('Processing')}</span>) : (<span className='text-danger'>{t('Pending')}</span>)}</>,
            sortable: true
        },
        {
            name: t('date'),
            selector: row => moment.utc(row.updatedAt).format("MMMM DD YYYY"),
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {

                        permission.edit ? <button className={`btn btn-${row.status == "complete" ?'success' : 'warning'} btn-sm mx-1`} onClick={() => datapost(row._id, row.status)}><i className={`bi bi-${row.status == "complete" ? 'check-all' : 'clock'}`}></i></button> : ''
                    }
                    <button className='btn btn-primary btn-sm mx-1' type='button' onClick={() => { setModalData(row); handleShow(); }}><i className='bi bi-info-lg'></i></button>

                </>
        },
    ];

    return (
        <>
            <div className='container bg-white pb-8 my-3 p-3'>
                <div className='header py-1 pb-2 position-relative'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('card_order')}</h3>
                </div>

                {Loading ? <Loader /> :
                    <>
                        {/* {RoundLoading ? <RoundLoader /> : ''} */}
                        <Datatable filterBy={selectFilter == 'updatedAt' ? 'updatedAt' : 'status'} columns={columns} data={reportData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} />
                    </>
                }

            </div >
            <Modal show={show}>
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title className='ps-4'>{t('Details')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-3"><h5>{t('email')}</h5> </div>
                            <div className="col-9"><p className='text-break'>{modalData.email}</p></div>
                        </div>
                        <div className="row">
                            <div className="col-3"><h5>{t('address')}</h5> </div>
                            <div className="col-9"><p className='text-break'>{modalData.address}</p></div>
                        </div><div className="row">
                            <div className="col-3"><h5>{t('Status')}</h5> </div>
                            <div className="col-9"><p>{modalData.status}</p></div>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        </>
    )
}

export default CardOrder