import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import { Modal, Checkbox } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import './DataTableDemo.css';

function GameScore() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [ScoreData, setScoreData] = useState([]);
    const [PlayerData, setPlayerData] = useState([]);
    const [PlayerScore, setPlayerScore] = useState([]);
    const [PlayerheaderListing, setPlayerheaderListing] = useState([]);
    const [RoundData, setRoundData] = useState([]);
    const [TotalScore, setTotalScore] = useState({});
    const [CardCountData, setCardCountData] = useState({});
    const [editTable, setEditTable] = useState(true);
    const [Loading, setLoading] = useState(true);
    const [AllCards, setAllCards] = useState(false);
    const [KeySet, setKeySet] = useState(true);
    const [SelectedRound, setSelectedRound] = useState(0);
    const [WinningType, setWinningType] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const handleClose = () => setShow(false);

    const id = window.location.href.split('spide/')[1];
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/score/' + id;

    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            extractData(response.data);
            setWinningType(response.data.game.winning_type);
            setLoading(false);
        }
    }

    const extractData = (data) => {
        var score_data = data.score_data;
        score_data = JSON.parse(score_data);
        var spide_data = spide(score_data);
        setPlayerScore(spide_data.score_listing);
        setTotalScore(spide_data.total_score_listing);
        console.log('TotalScore = ', TotalScore);
        setScoreData(data);
        setPlayerData(score_data);
        var player_header_listing = score_data.playerList.length ? score_data.playerList : score_data.apiTeamList;
        setPlayerheaderListing(player_header_listing);
    }

    function spide(data) {
        var dataArr = { score_listing: [], total_score_listing: [] };
        // console.log('(data= ', data);
        if (data.playerList != undefined || data.apiTeamList != undefined) {
            var playerList = data.playerList.length ? data.playerList : data.apiTeamList;
            var scores = data.data[0].scores;
            var total_score = [];
            console.log('playerList = ',data);
            for (let index = 0; index < playerList.length; index++) {
                var playerListData = playerList[index];
                if (playerListData.playerId != undefined || playerListData._id != undefined) {
                    var score = 0;
                    for (let i = 0; i < scores.length; i++) {
                        var score_data = scores[i].scores[index];
                        if (score_data != undefined) {
                            var scoreData = score_data.h + score_data.q + score_data.d + score_data.n + score_data.exception;
                            score = score + scoreData;
                        }

                    }
                    total_score.push(score);
                }
            }

            var temp_score_data = [];
            var temp_score_data_temp = [];
            var temp_round_data = [];
            for (let i = 0; i < scores.length; i++) {
                var score_data = scores[i].scores;
                temp_score_data[i] = [];
                var temp_round_score = [];
                var allCard = 0;

                for (let j = 0; j < score_data.length; j++) {
                    var tempdata = score_data[j];
                    temp_round_score.push(tempdata);
                    var temp_score = tempdata.h + tempdata.q + tempdata.d + tempdata.n + tempdata.exception;
                    allCard = allCard + tempdata.exception;
                    temp_score_data[i].push(temp_score);
                }

                for (let j = 0; j < score_data.length; j++) {
                    temp_round_score[j].allCard = allCard == 78 ? allCard : 0;
                }
                temp_round_data.push(temp_round_score);
                temp_score_data_temp.push(temp_score_data[i]);
            }
            setRoundData(temp_round_data);
            dataArr.score_listing = temp_score_data_temp;
            dataArr.total_score_listing = total_score;
        }
        return dataArr;
    }

    const datapost = async () => {
        var winner_details = WinnerDetails();
        if (ScoreData._id) {
            var score_data_stringfy = JSON.stringify(PlayerData);
            const response = await Database.update(apiRoute, { 'score_data': score_data_stringfy, winner_name: winner_details.winner_name, winner_score: winner_details.winner_score });
            if (response.status) {
                enqueueSnackbar(t('score_update_success'), { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        handleClose();
        setspinnerIsActive(false);
        setEditTable(true);
    }

    const WinnerDetails = () => {
        if (WinningType == 'low') {
            var max_score = Math.min(...TotalScore);
        } else {
            var max_score = Math.max(...TotalScore);
        }
        var winnerName = [];
        for (let index = 0; index < TotalScore.length; index++) {
            const score = TotalScore[index];
            if (max_score == score) {
                winnerName.push(PlayerheaderListing[index].name);
            }
        }
        return { winner_name: winnerName.toString(), winner_score: max_score };
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('history/game');
        setPermission(page_permission);
    }

    const scoreClick = (e) => {
        var round = e.target.attributes.dataround.value;
        // Check All card Status
        var checkstatus = true;
        for (let index = 0; index < RoundData[round].length; index++) {
            const element = RoundData[round][index];
            if (element.q != 0 || element.d != 0 | element.h != 0 || element.n != 0) {
                checkstatus = false;
            }
        }
        setAllCards(checkstatus);

        // set round keydata
        var scores = PlayerData.data[0].scores;
        var cardCount = getCardDetails(scores[round].scores);
        setCardCountData(cardCount);
        setSelectedRound(round);
        setShow(true);
    }

    const AddScore = (e) => {
        var attributes = e.target.attributes;
        var dataRound = attributes.dataround.value;
        var datakey = attributes.datakey.value;
        var datatype = attributes.datatype.value;
        var buttontype = attributes.buttontype.value;
        var scores = PlayerData.data[0].scores;
        var cardCount = getCardDetails(scores[dataRound].scores);
        var q = cardCount.q;
        var h = cardCount.h;
        var d = cardCount.d;
        var n = cardCount.n;
        var sumOfHearts = cardCount.sumOfHearts;

        if (!n.includes(parseInt(datakey)) && datatype == 'q' && (buttontype == 'plus' || buttontype == "minus") && RoundData[dataRound][datakey].q != 26) {
            if (q.includes(parseInt(datakey)) || !q.length) {
                if (buttontype == 'plus') {
                    RoundData[dataRound][datakey].q = 13;
                } else {
                    RoundData[dataRound][datakey].q = 0;
                }
            }
        }

        if (!n.includes(parseInt(datakey)) && datatype == 'd' && (buttontype == 'plus' || buttontype == "minus") && RoundData[dataRound][datakey].d != 20) {
            if (d.includes(parseInt(datakey)) || !d.length) {
                if (buttontype == 'plus') {
                    RoundData[dataRound][datakey].d = 10;
                } else {
                    RoundData[dataRound][datakey].d = 0;
                }
            }
        }

        if (!n.includes(parseInt(datakey)) && datatype == 'h') {
            if (sumOfHearts < 13 && buttontype == 'plus') {
                if (buttontype == 'plus') {
                    RoundData[dataRound][datakey].h = h[datakey] + 1;
                }
            } else if (buttontype == "minus" && h[datakey] != 0) {
                RoundData[dataRound][datakey].h = h[datakey] - 1;
            }
        }

        if (datatype == 'n' && (buttontype == 'plus' || buttontype == "minus")) {
            if (!q.includes(parseInt(datakey)) && !d.includes(parseInt(datakey)) && h[datakey] == 0) {
                var n_data = RoundData[dataRound][datakey].n;
                if (buttontype == 'plus') {
                    RoundData[dataRound][datakey].n = n_data == 0 ? 10 : (n_data == 10 ? 10 : 0);
                } else {
                    RoundData[dataRound][datakey].n = n_data == 0 ? -10 : (n_data == 10 ? 0 : -10);
                }
            }
        }

        if (buttontype == 'declairation') {
            var round_DATA = RoundData[dataRound][datakey];
            if (datatype == 'q' && (!n.includes(parseInt(datakey)) && (!q.length || q.includes(parseInt(datakey))) || q.includes(parseInt(datakey)))) {
                round_DATA.q = round_DATA.q == 26 ? 0 : 26;
            } else if (!n.includes(parseInt(datakey)) && datatype == 'd' && (!d.length || d.includes(parseInt(datakey)))) {
                round_DATA.d = round_DATA.d == 20 ? 0 : 20;
            }
        }

        // Check All card Status
        var checkstatus = true;
        for (let index = 0; index < RoundData[dataRound].length; index++) {
            const element = RoundData[dataRound][index];
            if (element.q != 0 || element.d != 0 | element.h != 0 || element.n != 0) {
                checkstatus = false;
            }
        }
        setAllCards(checkstatus);

        for (let index = 0; index < RoundData[dataRound].length; index++) {
            var edata = 0;
            if (datatype == 'exception' && checkstatus) {
                edata = index == datakey ? 0 : 26;
                RoundData[dataRound][index].q = 0;
                RoundData[dataRound][index].d = 0;
                RoundData[dataRound][index].h = 0;
                RoundData[dataRound][index].n = 0;
            }
            RoundData[dataRound][index].exception = edata;
        }

        var scores = PlayerData.data[0].scores;
        var cardCount = getCardDetails(scores[dataRound].scores);
        setCardCountData(cardCount);

        var spide_data = spide(PlayerData);
        setPlayerScore(spide_data.score_listing);
        setTotalScore(spide_data.total_score_listing);
        setPlayerData({ ...PlayerData, PlayerData });
        if (datatype == 'exception') {
            datapost(); setspinnerIsActive(true);
        }
    }

    function getCardDetails(data) {
        var q = [];
        var h = [];
        var d = [];
        var n = [];
        var exception = [];
        var sumOfHearts = 0;
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.q != 0) { q.push(index); }
            if (element.d != 0) { d.push(index); }
            // if (element.h != 0) { }
            h.push(element.h);
            if (element.n != 0) { n.push(index); }
            if (element.exception != 0) { exception.push(index); }
            sumOfHearts += element.h;
        }
        return { q: q, h: h, d: d, n: n, exception: exception, sumOfHearts: sumOfHearts };
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {
                Loading ? <Loader /> :
                    <>
                        <div className='container bg-white pb-8 my-3 p-3'>
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{ScoreData.game.name}</h3>
                                    <br />
                                    <h5 className={`text-dark`}>
                                        {ScoreData.game.name} - ({ScoreData.diwaniya.name})
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='text-center b-white w-25'>{t('Rounds')}</th>
                                                <>
                                                    {PlayerheaderListing.map((player_name) => (
                                                        <>
                                                            <th className='text-center b-white'>{player_name.name}</th>
                                                        </>
                                                    ))}
                                                </>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {

                                                    PlayerScore.map((score, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td className='text-center p-2 roundColor'>
                                                                    <input
                                                                        disabled
                                                                        name="bid"
                                                                        value={index + 1}
                                                                        type="text"
                                                                        placeholder="Round"
                                                                    />
                                                                </td>

                                                                {
                                                                    score.map((data, i) => (
                                                                        <td className={'text-center roundColor'}>
                                                                            <input
                                                                                disabled={editTable ? true : false}
                                                                                className={`w-100 p-2 score_fields cursor-pointer`}
                                                                                name={'team_'}
                                                                                value={data}
                                                                                type="text"
                                                                                onClick={(e) => (scoreClick(e))}
                                                                                placeholder="Score"
                                                                                datakey={i}
                                                                                dataround={index}
                                                                                readOnly
                                                                                maxlength="3"
                                                                            />
                                                                        </td>
                                                                    ))
                                                                }


                                                            </tr>
                                                        </>
                                                    ))}
                                            </>
                                        </tbody>
                                        <br />
                                        <tfoot>
                                            <tr>
                                                <th className='mt-2 bg-dark text-center b-white'> {t('total')} </th>
                                                {
                                                    TotalScore.map((total, index) => (
                                                        <>
                                                            <th className='mt-2 bg-dark text-center b-white'> {total} </th>
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div >
                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : setEditTable(true); }} data-bs-target="#staticBackdrop" className={'btn float-end' + (editTable ? ' btn-dark' : '  btn-danger')}>{editTable ? "Edit" : " Cancel"}</button>
                            </div> : ''
                        }
                    </>
            }

            <Modal show={show} fullscreen="xxl-down">
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleClose(); callData(); }}>
                    <Modal.Title className=''>{t('score')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div className='row'>
                        <>
                            {PlayerheaderListing.map((player_name, i) => (
                                <>
                                    <div className='col-4 mb-4 m-auto custominput'>
                                        <div className="user_score_box">
                                            <div className='score_box_user_name bg-danger text-white pt-2 pb-2 text-center rounded-top'>{player_name.name}</div>
                                            <div className='score_select_box border rounded-bottom'>

                                                <div className='row p-2 ms-2 me-2'>
                                                    <div className='col-6 ps-1 pe-1'>
                                                        <div className='bg-warning rounded-3 pb-2'>
                                                            <div className='p-2 text-center'>
                                                                <img src="/assets/icons/queen_crown2.png" className='w-15' alt="" />
                                                                <span className='ms-2 fw-6'>{t('Q_of_Spide')}</span>
                                                            </div>

                                                            <div className='d-flex w-55 m-auto'>
                                                                <i className={'bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + ' all_q_' + i + (CardCountData.q != undefined && (CardCountData.q.length && !CardCountData.q.includes(parseInt(i)) || RoundData[SelectedRound][i].q == 26 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="q" buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className={'m-auto text-dark fw-6 qofspide_' + i} dataround={SelectedRound} datakey={i}>{RoundData[SelectedRound][i].allCard != 0 ? 0 : RoundData[SelectedRound][i].q}</span>
                                                                <i className={'bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + ' all_q_' + i + (CardCountData.q != undefined && (CardCountData.q.length && !CardCountData.q.includes(parseInt(i)) || RoundData[SelectedRound][i].q == 26 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="q" buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>

                                                            <div className='d-flex w-50 m-auto'>
                                                                <i className={`bi bi-check  cursor-pointer text-white rounded-circle score_btn_spide m-auto mt-2 all_disable_` + i + (RoundData[SelectedRound][i].q == 26 && RoundData[SelectedRound][i].allCard == 0 ? ' bg-danger ' : ' bg-dark ' + ' all_q_' + i) + (CardCountData.q != undefined && ((CardCountData.q.length && !CardCountData.q.includes(parseInt(i))) || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="q" buttontype="declairation" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-6 ps-1 pe-1'>
                                                        <div className='bg-warning rounded-3 pb-2'>
                                                            <div className='p-2 text-center'>
                                                                <img src="/assets/icons/diamond.png" className='w-15' alt="" />
                                                                <span className='ms-2 fw-6'>{t('10_of_Diamonds')}</span>
                                                            </div>

                                                            <div className='d-flex w-55 m-auto'>
                                                                <i className={'bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + (CardCountData.d != undefined && (CardCountData.d.length && !CardCountData.d.includes(parseInt(i)) || RoundData[SelectedRound][i].d == 20 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="d" buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className={'m-auto text-dark fw-6 sodimand_' + i} dataround={SelectedRound} datakey={i}>{RoundData[SelectedRound][i].allCard != 0 ? 0 : (RoundData[SelectedRound][i].d)}</span>
                                                                <i className={'bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + (CardCountData.d != undefined && (CardCountData.d.length && !CardCountData.d.includes(parseInt(i)) || RoundData[SelectedRound][i].d == 20 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="d" buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>

                                                            <div className='d-flex w-50 m-auto'>
                                                                <i className={`bi bi-check  cursor-pointer text-white rounded-circle score_btn_spide m-auto mt-2 all_disable_` + i + (RoundData[SelectedRound][i].d == 20 && RoundData[SelectedRound][i].allCard == 0 ? ' bg-danger ' : ' bg-dark ') + (CardCountData.d != undefined && (CardCountData.d.length && !CardCountData.d.includes(parseInt(i)) || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="d" buttontype="declairation" onClick={(e) => (AddScore(e))}></i>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='col-6 mt-2 ps-1 pe-1'>
                                                        <div className='bg-warning rounded-3 pb-2'>
                                                            <div className='p-2 text-center'>
                                                                <img src="/assets/icons/ic_heart.png" className='w-15' alt="" />
                                                                <span className='ms-2 fw-6'>{t('Hearts')}</span>
                                                            </div>

                                                            <div className='d-flex w-55 m-auto'>
                                                                <i className={'bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + (CardCountData.sumOfHearts != undefined && (CardCountData.sumOfHearts == 13 && CardCountData.h[i] == 0 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="h" buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className={'m-auto text-dark fw-6 heartsofspide_' + i} dataround={SelectedRound} datakey={i}>{RoundData[SelectedRound][i].allCard != 0 ? 0 : (RoundData[SelectedRound][i].h)}</span>
                                                                <i className={'bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide all_disable_' + i + (CardCountData.sumOfHearts != undefined && (CardCountData.sumOfHearts == 13 && CardCountData.h[i] == 0 || CardCountData.n.includes(parseInt(i))) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="h" buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>

                                                            <div className='d-flex w-50 m-auto opacity-0'>
                                                                <i className='bi bi-check  cursor-pointer bg-dark text-white rounded-circle score_btn_spide m-auto mt-2'></i>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='col-6 mt-2 ps-1 pe-1'>
                                                        <div className='bg-warning rounded-3 pb-2'>
                                                            <div className='p-2 text-center position-relative'>
                                                                <img src="/assets/icons/ic_heart.png" className='w-15 opacity-0' alt="" />
                                                                <span className='ms-2 fw-6 position-absolute start-0 end-0'>{t('Negative')}</span>
                                                            </div>

                                                            <div className='d-flex w-55 m-auto'>
                                                                <i className={'bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide' + (CardCountData.q != undefined && CardCountData.d != undefined && CardCountData.h != undefined && (CardCountData.q.includes(parseInt(i)) || CardCountData.d.includes(parseInt(i)) || CardCountData.h[i] != 0) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="n" buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className={'m-auto text-dark fw-6 nofspide_' + i} dataround={SelectedRound} datakey={i}>{RoundData[SelectedRound][i].allCard != 0 ? 0 : (RoundData[SelectedRound][i].n)}</span>
                                                                <i className={'bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide' + (CardCountData.q != undefined && CardCountData.d != undefined && CardCountData.h != undefined && (CardCountData.q.includes(parseInt(i)) || CardCountData.d.includes(parseInt(i)) || CardCountData.h[i] != 0) ? ' disabled' : '')} dataround={SelectedRound} datakey={i} datatype="n" buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>

                                                            <div className='d-flex w-50 m-auto  opacity-0'>
                                                                <i className='bi bi-check  cursor-pointer bg-dark text-white rounded-circle score_btn_spide m-auto mt-2'></i>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='mt-3 mb-2 d-flex'>
                                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className={'btn btn-danger float-end m-auto ps-4 pe-4 allCards' + (AllCards ? '' : ' disabled')} dataround={SelectedRound} datakey={i} datatype="exception" buttontype="all" onClick={(e) => (AddScore(e))}>{t('All_Cards')}</button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                    </div>

                    <div className="modal-footer border-top">
                        <button onClick={() => { handleClose(); callData(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                        <button type="button" onClick={() => { datapost(); setspinnerIsActive(true); }} className="btn btn-danger rounded-0 px-4">
                            {spinnerIsActive ?
                                <><i className="spinner-border custom-spinner" role="status"></i>
                                </>
                                : ""} {t('save')}</button>
                    </div>

                </Modal.Body>
            </Modal>

        </>

    )
}

export default GameScore