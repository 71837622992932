import React, { useEffect, useRef, useState } from 'react'
import Datatable from '../Components/Datatable/WsDatatableAjax';
import Loader from '../Components/Loader';
import RoundLoader from '../Components/RoundLoader';
import Database from '../Database/Index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Leaderboard() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [LeaderboardData, setLeaderboardData] = useState([]);
    const [DiwaniyaData, setDiwaniyaData] = useState([]);
    const [GameData, setGameData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const [Diwaniyaid, setDiwaniyaid] = useState('');
    const [Gameid, setGameid] = useState('');
    const [teamPlayer, setTeamPlayer] = useState('');
    const selectPlayer = useRef("");
    const selectDiwaniya = useRef("");
    const selectGame = useRef("");

    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    const callData = async (per_Page = perPage, Page = page, filtersearch = search, diwaniya_id = Diwaniyaid, game_id = Gameid, team_player_id = teamPlayer) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '/leaderboard?page=' + Page + '&per_page=' + per_Page + '&search=' + filtersearch + '&diwaniya_id=' + diwaniya_id + '&game_id=' + game_id + `&team_player=` + team_player_id);
        if (response.status) {
            setRoundLoading(false);
            // setLeaderboardData(response.data);
            setTotalRows(response.total_records);
            setLeaderboardData(response.data)
            // setLeaderboardData(playerData)

        }
        setLoading(false);
    }
    // for get data
    const callDiwaniyaGAme = async () => {
        const diwaniya_response = await Database.get(apiRoute + '/diwaniya');
        if (diwaniya_response.status) {
            setDiwaniyaData(diwaniya_response.data);
        }
        const game_response = await Database.get(apiRoute + '/game');
        if (game_response.status) {
            setGameData(game_response.data);
            callData(perPage, page, search);
        }
    }



    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        var DiwaniayGameId = getDiwaniayGameId();
        callData(newPerPage, page, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id, DiwaniayGameId.team_player_type);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, page, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id, DiwaniayGameId.team_player_type);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, 1, val, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id, DiwaniayGameId.team_player_type);
    }

    const getDiwaniayGameId = () => {
        var game_id = '';
        var diwaniya_id = '';
        var team_player_type = '';
        var elems = document.querySelectorAll(".diwaniya_id");
        [].forEach.call(elems, function (el) {
            diwaniya_id = el.value;
        });

        var elems = document.querySelectorAll(".game_id");
        [].forEach.call(elems, function (el) {
            game_id = el.value;
        });
        var elems = document.querySelectorAll(".team_player_id");
        [].forEach.call(elems, function (el) {
            team_player_type = el.value;
        });
        return { diwaniya_id: diwaniya_id, game_id: game_id, team_player_type: team_player_type };
    }

    // Select Diwaniya/Game filter
    const FilterChange = (val) => {
        var DiwaniayGameId = getDiwaniayGameId();
        callData(perPage, 1, search, DiwaniayGameId.diwaniya_id, DiwaniayGameId.game_id, DiwaniayGameId.team_player_type);
    }

    useEffect(() => {
        callDiwaniyaGAme();
    }, [])

    // columns

    const clearFilters = () => {
        callData(perPage, page, search);
        selectPlayer.current.value = "Player";
        selectDiwaniya.current.value = "";
        selectGame.current.value = "";
        document.getElementById('search').value = "";
        setSearch("");
    }

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Score',
            selector: row => row.score,
            sortable: true
        },
    ];
    const FilterComponent = (
        <>
            <div class="form-group col-2 float-end me-2">
                <select class="form-control rounded-0 team_player_id" ref={selectPlayer} name="" id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="Player">{t('player')}</option>
                    <option value="team">{t('team')}</option>
                </select>
            </div>
            <div class="form-group col-3 float-end me-3">
                <select class="form-control rounded-0 diwaniya_id" ref={selectDiwaniya} name="" id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="">{t('--select_diwaniya--')}</option>
                    {
                        DiwaniyaData.map((diwaniya_data) => (
                            <option value={diwaniya_data._id}>{diwaniya_data.name}</option>
                        ))
                    }
                </select>
            </div>
            <div class="form-group col-3 float-end me-3">
                <select class="form-control rounded-0 game_id" name="" ref={selectGame} id="" onChange={e => { FilterChange(e.target.value) }}>
                    <option value="">{t('--select_game--')}</option>
                    {
                        GameData.map((game_data) => (
                            <option value={game_data._id}>{game_data.name}</option>
                        ))
                    }
                </select>
            </div>
        </>
    )

    return (
        <div className='container bg-white mt-3 p-4 position-relative'>
            <div className="row">
                <div className="col-11">
                    <div className='header py-1 pb-2 position-relative'>
                        <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('leaderboard')}</h3>
                    </div>
                </div>
                <div className="col-1">
                    <button onClick={clearFilters} className="btn btn-danger">{t('reset')}</button>
                </div>
            </div>

            {
                Loading ? <Loader /> :
                    <>
                        {/* {RoundLoading ? <RoundLoader /> : ''} */}
                        <Datatable columns={columns} data={LeaderboardData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} CustomFilter={FilterComponent} RoundLoading={RoundLoading}/>
                    </>
            }
        </div>
    )
}

export default Leaderboard