import React, { useEffect } from 'react';
import MyRoutes from './Routes/Index'


import './Assets/Css/Layout.css'
import './Assets/Css/Style.css'
import { SnackbarProvider } from 'notistack';


function App() {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
      <MyRoutes />
    </SnackbarProvider>
  );
}

export default App;


// preventDuplicate