import React, { useState, useEffect } from 'react'
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom'
import UpdateAuth from '../../Components/Update_Authentication';
import RoundLoader from '../../Components/RoundLoader';
import Loader from '../../Components/Loader';



export default function Login() {
    const navigate = useNavigate();
    const JwtDetails = localStorage.getItem('jwt');

    useEffect(() => {
        if (JwtDetails != null) {
            navigate('/admin/dashboard');
        }
    }, [])

    const { enqueueSnackbar } = useSnackbar();
    const [Loading, setLoading] = useState(true);

    const [user, setUser] = useState({ email: '', password: '' });
    const [otpbox, setOtpbox] = useState(false);
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    // const [otp, setotp] = useState('');
    const [viewPassword, setviewPassword] = useState(false);

    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUser({ ...user, [name]: value })
    }

    const dataPost = async (e) => {

        e.preventDefault();


        const { email, password } = user;
        if (!email) {
            enqueueSnackbar('Please Enter your Email address', { variant: 'error' });
            return false;
        }

        if (!password) {
            enqueueSnackbar('Please Enter your Password', { variant: 'error' });
            return false;
        }
        setLoading(false);

        const response = await Database.login(apiRoute + '/subadmin/login', user);
        if (response.status) {
            // const JsonData = JSON.stringify(response);
            localStorage.setItem('jwt', JSON.stringify(response));
            localStorage.setItem('token', response.token);
            localStorage.setItem('permissions', response.permissions);
            localStorage.setItem('role', response.role);
            localStorage.setItem('user', response.user_id);
            localStorage.setItem('username', response.username);
            localStorage.setItem('image', response.image);
            var data = { loginToken: response.token };
            const response1 = await Database.update(apiRoute + '/subadmin/' + response.user_id, data, response.token);
            localStorage.setItem('loginToken', response.token);

            enqueueSnackbar(response.message, { variant: 'success' });
            // navigate('/admin/dashboard');
            window.location.href = "/admin/dashboard";
            // setOtpbox(true);

        }
        else {
            setLoading(true);
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    }
    // const otpVerify = async (e) => {

    //     e.preventDefault();
    //     if (!otp) {
    //         e.target.otp.classList.add('is-valid');
    //         return false;
    //     }
    //     const data = { otp: otp, email: user.email };
    //     const response = await Database.otpVerify(apiRoute + '/admin/verify-otp', data);
    //     if (response.status) {
    //         navigate('/admin/dashboard')
    //     }
    //     else {
    //         enqueueSnackbar(response.message, { variant: 'error' });
    //     }
    // }

    // const resendOtp = async () => {

    //     if (!user.email) {
    //         setOtpbox(false);
    //     }

    //     const data = { email: user.email };
    //     const response = await Database.otpVerify(apiRoute + '/admin/resend-otp', data);
    //     if (response.status) {
    //         enqueueSnackbar(response.message, { variant: 'success' });
    //     }
    // }

    return (
        <div className='row px-0 mx-0 px-3 px-md-0 h-100vh'>
            <div className='col-12 offset-md-3 col-md-6 my-4'>
                <div className='d-block'>
                    <div className='d-flex justify-content-center m-2'>
                        <img src='/assets/Login/images/app_icon.png' alt='' className='w-20 mb-2' />
                    </div>
                    <div className='shadow-lg'>
                        <div className='heading bg-danger text-center py-2'>
                            <h2 className='text-white fw-bold'>SIGN IN</h2>
                        </div>
                        <div className={`px-4 py-2  ${otpbox ? 'd-none' : ''}`}>
                            <form className='form' method='post' onSubmit={dataPost}>
                                <input type="text" name="email" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Email Address' value={user.email} onChange={inputHandle} />
                                <span className='invalid-feedback'>Invalid Username and password</span>
                                <div className="position-relative">
                                    <input type={viewPassword ? "text" : "password"} name="password" className='form-control mb-3 mt-4 border-0 rounded-0 border-bottom' placeholder='Password' value={user.password} onChange={inputHandle} />
                                    <span onClick={(e) => { viewPassword ? setviewPassword(false) : setviewPassword(true); }} className='bi bi-eye position-absolute top-20 end-0 pe-3'></span>
                                </div>
                                {Loading ? <button className='btn btn-dark px-5 py-2 rounded-1 my-3'>Login</button> : <button disabled className='btn btn-dark px-5 p-1 rounded-1 my-3 position-relative'><div class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></button>}
                            </form>
                        </div>
                        {/*  */}
                        {/* <div className={`px-4 py-2 ${otpbox ? '' : 'd-none'}`}>
                            <form className='form' method='post' onSubmit={otpVerify}>
                                <input type="text" onChange={(e) => setotp(e.target.value)} max={6} min={6} name="otp" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Enter otp' />
                                <span className='invalid-feedback'>Enter otp</span>
                                <p className='my-3'>OTP not get? <span className='text-danger' onClick={resendOtp} style={{ cursor: 'pointer' }}>resend otp</span></p>
                                <button className='btn btn-dark px-5 py-2 rounded-1 my-3'>Login</button>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    )
}
