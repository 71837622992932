import React, { useEffect, useState } from 'react'
import Database from '../Database/Index';
import { useTranslation } from 'react-i18next'
import RoundLoader from '../Components/RoundLoader';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';


function Profile() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(true);
  const [RoundLoading, setRoundLoading] = useState(true);
  const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/subadmin';
  const [Name, setName] = useState(null);
  const [Email, setEmail] = useState(null);
  const [Phone, setPhone] = useState(null);
  const [Role, setRole] = useState(null);

  const [SupportEmail, setSupportEmail] = useState(null);
  const [SupportPhone, setSupportPhone] = useState(null);

  const [ID, setID] = useState(null);
  const [image, setImage] = useState(null);
  const [password, setPassword] = useState("");
  const [comfirm_password, setComfirmPassword] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [Check, setCheck] = useState(false);
  const [viewPassword1, setviewPassword1] = useState(false);
  const [viewPassword2, setviewPassword2] = useState(false);
  const [viewPassword3, setviewPassword3] = useState(false);
  const [SelectedLang, setSelectedLang] = useState('ar');
  const axios = require("axios");

  const fetchUserData = async () => {
    let userId = localStorage.getItem('user');
    var lang = localStorage.getItem('lang');
    setSelectedLang(lang);
    setRoundLoading(true);
    const response = await Database.get(apiRoute + '/' + userId);
    console.log('response = ',response);
    if (response.status) {
      if (Name == null) setName(response.data.name);
      if (Email == null) setEmail(response.data.email);
      if (Phone == null) setPhone(response.data.phonenumber);
      if (ID == null) setID(response.data._id);
      if (image == null) setImage(response.data.image);
      if (Role == null) setRole(response.data.role);
      if (SupportPhone == null) setSupportPhone(response.data.SupportPhone);
      if (SupportEmail == null) setSupportEmail(response.data.role);
      setRoundLoading(false);
      // setUserData(response.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  const datapost = async (e) => {
    e.preventDefault();
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (Name.length < 5 || Name.trim() == "") {
      return enqueueSnackbar(t('name_must_be_five'), {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    if (Phone.length < 8 || Phone.length > 8) {
      return enqueueSnackbar(t('invalid_phone'), { variant: 'error' });
    }
    if (!SupportEmail.match(validRegex)) {
      return enqueueSnackbar('email validation', { variant: 'error' });
    } 

    if(Check) {
        if (password.length < 8) {
          return enqueueSnackbar("Password must be greater than 8 characters", { variant: 'error' });
        }
        var Matcheduser = {
          email: Email,
          password: old_password
        }
        const loginResponse = await Database.login(apiRoute + '/login', Matcheduser);
        if (!loginResponse.status && Check) {
          return enqueueSnackbar(loginResponse.message, {
            variant: 'error',
            autoHideDuration: 2000
          });
        }
        if (old_password == comfirm_password) {
          return enqueueSnackbar("New and Old Password cannot be same!", { variant: 'error', autoHideDuration: 2000 });
        }
        if (password !== comfirm_password) {
          return enqueueSnackbar("New and Confirm Password Not Matched!", { variant: 'error', autoHideDuration: 2000 });
        }
    }
    var data = { name: Name, phonenumber: Phone, password: password, SupportPhone:SupportPhone, SupportEmail:SupportEmail };
    const response = await Database.update(apiRoute + '/' + ID, data);
    if (response.status) {
      localStorage.setItem('username', response.update_data.name);

      enqueueSnackbar(t('profile_update_success'), { variant: 'success', autoHideDuration: 2000 });
      navigate('/admin/profile');
    }
    else {
      enqueueSnackbar(response.message, { variant: 'error', autoHideDuration: 2000 });
    }

  }


  const [uploadProfileLoader, setUploadProfileLoader] = useState(false);
  const uploadFile = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setUploadProfileLoader(true);

    const formdata = new FormData();
    formdata.append("image", event.target.files[0]);
    formdata.append("id", localStorage.getItem('user'));
    axios
      .put(apiRoute + '/' + ID,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.M3RRMHdLTWZ6dQ.3KYGKye5acseMF2meWYZ2TvE_UvVaPei2yZS3t3T160"
          },
        }
      )
      .then((res) => {
        if (res.data.length != 0) {
          // setImage(res.data.data.image);
          localStorage.setItem('image', res.data.update_image_data);
          enqueueSnackbar(t('profile_image_update_success'), { variant: 'success', autoHideDuration: 2000 });
          navigate('/admin/profile');
        } else {
          console.log(res);
          enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000 });
        }
      }).catch(err => enqueueSnackbar("Image Size Must Be Less Than 5 MB", { variant: 'error', autoHideDuration: 2000 }))
  };
  return (
    <>
      <div className="container-fluid pt-3 overflow-auto scrollbar-hidden">
        <div className="row my-2 dashboard_card_box">
          <form>
            <div className="row">
              <div className="col-lg-12 card">
                <div className="">
                  <div className="card-body">
                    <form
                      action=""
                      method="post"
                      enctype="multipart/form-data"
                      style={{ opacity: "0" }}
                    >
                      <input
                        onChange={(e) => uploadFile(e)}
                        style={{
                          position: "absolute",
                          top: "15px",
                          right: "340px",
                          height: "35%",
                          width: "30%",
                        }}
                        type="file"
                        name=""
                        id="input_file"
                        accept=".jpg,.jpeg,.png"
                        className="form-control cursor-pointer"
                        value=""
                      />
                    </form>
                    <div className="d-flex flex-column align-items-center text-center">
                      {RoundLoading
                        ?
                        <RoundLoader position='absolute'/>
                        :
                        <>
                          <img
                            style={{ height: "100px" }}
                            src={image}
                            alt="Admin"
                            className="rounded-circle p-1 bg-danger "
                            width="110"
                          />
                          <div className="position-absolute top-30 ms-5 mt-2">
                            <i className='bi bi-pencil bg-light text-dark rounded-circle p-1 border border-danger cursor-pointer'></i>

                          </div>


                          <div className="mt-3">
                            <h4>{Name  || "-"}</h4>
                            <p className="mb-1">{Email}</p>
                            <p className="font-size-sm">
                              {t('phone_number')}: {Phone}
                            </p>

                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              Role == 'admin' ? 
                  <div className="row mt-3">
                    <div className="col-lg-12 card">
                    <label className='mt-2 mb-2'><strong>{t('support_details')}:-</strong></label>
                        <div className="card-body form-group">
                          <div className="row mb-3">
                            <div className="col-sm-3">
                              <h6 className="mb-0">{t('email')}</h6>
                            </div>
                            <div className="col-sm-9">
                              <input
                                type="email"
                                className="form-control"
                                onChange={(e) => setSupportEmail(e.target.value)}
                                value={SupportEmail}

                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-sm-3">
                              <h6 className="mb-0">{t('mobile')}</h6>
                            </div>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                onChange={(e) => setSupportPhone(e.target.value)}
                                value={SupportPhone}
                              />
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
              :''
            }

          <div className="row mt-3">
            <div className="col-lg-12 card">
            <label className='mt-2 mb-2'><strong>{t('account_details')}:-</strong></label>
                <div className="card-body form-group">
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">{t('email')}</h6>
                    </div>
                    <div className="col-sm-9">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        value={Email}

                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">{t('fullname')}</h6>
                    </div>
                    <div className="col-sm-9">
                      <input
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">{t('mobile')}</h6>
                    </div>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setPhone(e.target.value)}
                        value={Phone}
                      />
                    </div>
                  </div>
                  {
                    ID != null
                      ?
                      <>
                        <label className='mt-4'></label>
                        <div class="form-check">
                          <input class={"form-check-input" + (SelectedLang == 'en' ? '' : ' float-end ms-2')} onClick={() => { setPassword(""); setComfirmPassword(""); setOldPassword("") }} onChange={(e) => { setCheck(e.target.checked); }} type="checkbox" name="type" id="flexRadioDefault1" />
                          <label class="form-check-label" for="flexRadioDefault1">
                            {t('do_you_want_to_change_pass')}
                          </label>
                        </div>
                      </>
                      :
                      ""
                  }

                  {Check ?
                    <>
                      <div className='form-group position-relative'>
                        <input type={viewPassword1 ? "text" : "password"} name="old_password" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('old_password')} onChange={(e) => { setOldPassword(e.target.value); }} />
                        <span onClick={(e) => { viewPassword1 ? setviewPassword1(false) : setviewPassword1(true); }} className={'bi bi-eye position-absolute top-0 cursor-pointer'+ (SelectedLang == 'en' ? ' end-0' : ' start-0')}></span>
                      </div>
                      <div className='form-group position-relative'>
                        <input type={viewPassword2 ? "text" : "password"} name="comfirm_password" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('new_password')} onChange={(e) => { setComfirmPassword(e.target.value); }} />
                        <span onClick={(e) => { viewPassword2 ? setviewPassword2(false) : setviewPassword2(true); }} className={'bi bi-eye position-absolute top-0 cursor-pointer'+ (SelectedLang == 'en' ? ' end-0' : ' start-0') }></span>
                      </div>
                      <div className='form-group position-relative'>
                        <input type={viewPassword3 ? "text" : "password"} name="password" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('comfirm_password')} value={password} onChange={(e) => { setPassword(e.target.value); }} />
                        <span onClick={(e) => { viewPassword3 ? setviewPassword3(false) : setviewPassword3(true); }} className={'bi bi-eye position-absolute top-0 cursor-pointer'+ (SelectedLang == 'en' ? ' end-0' : ' start-0')}></span>
                      </div>
                    </>
                    : ""}
                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        onClick={(e) => {
                          datapost(e);
                        }}
                        className={"btn btn-danger px-4"+(SelectedLang == 'en' ? ' float-end' : ' float-start')}
                      >
                        {t('save')}
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
              </form>


        </div>
      </div>
    </>
  );
}

export default Profile;
