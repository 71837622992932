import { redirect } from 'react-router-dom';
import Database from '../Database/Index';
const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
// Users



const logout = () => {

    window.location.href = "/admin/login";
}
const Update_auth = async () => {
    const SubAdminResponse = await Database.get(apiRoute + '/subadmin/' + localStorage.getItem('user'));

    if (SubAdminResponse.data.loginToken != localStorage.getItem('loginToken')) {
        localStorage.removeItem('loginToken');
        localStorage.removeItem('jwt');
        logout();
        // logout();
    } else {

    }
    const menuRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/subadmin/update_auth';
    const response = await Database.updateAuth(menuRoute);
    if (response.user == "notfound") {
        localStorage.removeItem('jwt');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('role');
        logout();
    } else {

        if (response.status) {
            const JsonData = response.token;
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            localStorage.removeItem('role');
            localStorage.setItem('token', JsonData);
            localStorage.setItem('permissions', response.permissions);
            localStorage.setItem('role', response.role);
        }
    }
}

export default Update_auth;
