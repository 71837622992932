import React from 'react'

function RoundLoader({position = 'fixed', fullheight, t}) {
    return (
        <>
            <div className={`text-center  justify-content-center align-items-center position-absolute ajax_datatable_round_loader`+ (fullheight ? ' t-0 b-0' : '') + ' '+ t } style={{ zIndex: '99' }}>
                <div class="spinner-border text-danger position-absolute t-50" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default RoundLoader