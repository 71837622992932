import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import "../../../node_modules/flag-icons/css/flag-icons.min.css";
import UpdateAuth from '../../Components/Update_Authentication';



const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
]

function Index() {
  const navigate = useNavigate();
  const remove = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    localStorage.removeItem('role');
    navigate('/admin/');
  };
  const profile = () => {
    if (localStorage.getItem('token') != null) {
      UpdateAuth();  // localStorage.setItem('jwt', JSON.stringify(response));
    } else {
      localStorage.setItem('token', localStorage.getItem('token'));
      localStorage.setItem('permissions', localStorage.getItem('permissions'));
      localStorage.setItem('role', localStorage.getItem('role'));
    }
    navigate('/admin/profile');
  };
  const username = localStorage.getItem('username');
  const image = localStorage.getItem('image');

  const currentUser = JSON.parse(localStorage.getItem('jwt'));
  // if(currentUser.permissions !== ""){

  // const permissions = JSON.parse(localStorage.getItem('jwt')).permissions;
  // localStorage.setItem('permissions', JSON.parse(permissions));
  // }
  // console.log("Current User", currentUser);

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  // const localLanguage = localStorage.getItem('language') || 'en';
  // const [selectLanguage, setSelectLanguage] = useState(localLanguage);
  useEffect(() => {
    if (localStorage.getItem('sidebartoggle') === 'true') {
      document.body.classList.toggle('flx-sidenav-toggled');
    }
  }, [])

  const toggleSidebar = () => {
    document.body.classList.toggle('flx-sidenav-toggled');
    localStorage.setItem('sidebartoggle', document.body.classList.contains('flx-sidenav-toggled'));
  }

  return (
    <>
      <nav className="navbar navbar-expand bg-dark border-bottom position-sticky top-0" style={{ zIndex: 999 }}>
        <div className="container-fluid">
          <Link to="#" onClick={toggleSidebar} className="ms-3 text-white fsp-25" id="sidebarToggle"><i className="bi bi-list fs-2"></i></Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav ${currentLanguageCode == 'en' ? 'ms-auto' : 'me-auto'} mt-2 mt-lg-0`}>
              <li className="nav-item dropdown me-5 pt-1">
                <Link className="nav-link text-light" to="#" data-bs-toggle="dropdown">
                  <i className="bi bi-translate mx-2"></i>
                  {t('language')}
                  <i className="bi bi-chevron-down mx-2"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  {languages.map(({ code, name, country_code }) => (
                    <li key={country_code}>
                      <a
                        href="/admin/"
                        className={classNames('dropdown-item', {
                          disabled: currentLanguageCode === code,
                        })}
                        onClick={() => {
                          i18next.changeLanguage(code)
                          localStorage.setItem("lang", code);
                        }}
                      >
                        <span className={`flag-icon flag-icon-${country_code} mx-2`}
                          style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                        ></span>
                        {name}
                      </a>
                    </li>
                  ))}
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link text-light" to="#" data-bs-toggle="dropdown">
                  <img style={{ height: "48px" }} width="110" src={image != null ? image : ""} className=" rounded-circle me-2 top_bar_logo ms-2" alt="" />
                  {username != null ? username : ""}
                  <i className="bi bi-chevron-down ms-2 me-2"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">

                  <span className="dropdown-item" onClick={profile}>{t('profile')}</span>
                  <div className="dropdown-divider"></div>
                  <span className="dropdown-item" onClick={remove}>{t('logout')}</span>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Index;
