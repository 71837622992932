import React from 'react'

function PageHeader(props) {
    return (
        <div className='header py-4 position-relative'>
            <h3 className={`text-dark ${props.newBtn ? 'w-fitContent float-start pe-2' : ''}`}>
                {
                    props.newBtn && props.backgroundImage ? (
                        <div className='background_image float-start me-2' style={{ backgroundImage: "url(/assets/images/Diwaniya.png)", backgroundColor: props.newButtonBgColor }}></div>
                    ) : (
                        <i className={`pe-2 bi bi-${props.icon}`}></i>
                    )

                }
                {props.title}
            </h3>
            {
                props.newBtn ? (
                    <button onClick={props.btnClick} className="btn mx-1 btn-sm btn-dark header_new_btn">{props.newBtn}</button>
                ) : ('')
            }
            <p className='text-muted'>{props.text}</p>
            {props.children}
        </div>
    )
}

export default PageHeader