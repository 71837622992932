import React, { useState } from 'react'
import Datatable from '../Components/Datatable/WsDatatable'

function Newsletter() {

    const data = [];
    for (let index = 1; index < 100; index++) {
        data.push({ index: index, username: 'Newsletter ' + index, description: 'A Belfast perspective on news, sport, what.....' });
    }

    // columns

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: 'Title',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <>
                <button className='btn btn-dark btn-sm mx-1'><i className='bi bi-eye'></i></button>
                <button className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></button>
                <button className='btn btn-danger btn-sm mx-1'><i className='bi bi-trash'></i></button>
            </>,
        },
    ];


    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i>Leaderboard</h3>
                </div>
                <Datatable columns={columns} data={data} />
            </div>
            {/* <div className='container bg-white pb-8'>
                <PageHeader title="Newsletters" text="" icon="newspaper" />
                <DataTable data={data} type="btn" button={['view', 'edit', 'delete']} ButtonTitle={['View Query', 'edit', 'Remove Query']} onView={viewModa} onEdit={editData} onDelete={() => deleteItem()} />
            </div> */}
        </>
    )
}

export default Newsletter