import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import get_permission from '../../Components/Permission';
import { Link } from 'react-router-dom';
import Database from '../../Database/Index';
import moment from 'moment';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

function Report() {
    const { t } = useTranslation();

    const [reportData, setReportData] = useState([]);
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/reportdeskmanagement';
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });

    // for get data
    const callData = async (perPage, page, filtersearch) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + page + '&per_page=' + perPage + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            var ReportArr = [];
            if (!response.data.length) {
                setReportData([]);
            }
            response.data.forEach((element, index) => {
                if (element.players != undefined) {
                    ReportArr.push(element);
                    setReportData(ReportArr);

                }
            });
            setTotalRows(response.total_records);
            setLoading(false);
        }
    }
    const datapost = async (id, status) => {
        if (status == "complete") {
            return enqueueSnackbar(t('status_already_updated'), { variant: 'warning' });
        }

        const data = { status: "complete" };
        const response = await Database.put(apiRoute + '/' + id, data);
        if (response.status) {
            callData(perPage, page, search);
            enqueueSnackbar(response.message, { variant: 'success' });
        }
        else {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        callData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        callData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        callData(perPage, 1, val);
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('report_desk_management');
        setPermission(page_permission);
    }

    useEffect(() => {
        setPermissoin();
        callData(perPage, page, search);
    }, [])

    const [selectFilter, SetSelectFilter] = useState('updatedAt');


    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('username'),
            selector: row => row.players.username,
            sortable: true
        },
        {
            name: t('Query'),
            selector: row => row.query,
            sortable: true
        },
        {
            name: t('Status'),
            selector: row => <>{row.status == "complete" ? (<span className='text-success'>Complete</span>) : (row.status == "processing") ? (<span className='text-warning'>Processing</span>) : (<span className='text-danger'>Pending</span>)}</>,
            sortable: true
        },
        {
            name: t('date'),
            selector: row => moment.utc(row.updatedAt).format("MMMM DD YYYY"),
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    <button className={`btn btn-${row.status == "complete" ?'success' : 'warning'} btn-sm mx-1`} onClick={() => datapost(row._id, row.status)}><i className={`bi bi-${row.status == "complete" ? 'check-all' : 'clock'}`}></i></button>
                    <Link to={`/admin/report_desk_management/chatbox/` + row._id} className='btn btn-primary btn-sm mx-1' ><i className='bi bi-chat-right'></i></Link>
                </>
        },
    ];

    // Remove action while user don`t have any permissions
    if (!permission.edit) { columns.pop(columns.length - 1); }

    return (
        <div className='container bg-white pb-8 my-3 p-3'>
            <div className='header py-1 pb-2 position-relative'>
                <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people md-2`}></i>{t('report_desk_management')}</h3>
            </div>
            {
                Loading ? <Loader /> :
                    <>
                        {/* {RoundLoading ? <RoundLoader /> : ''} */}
                        <Datatable filterBy={selectFilter == 'updatedAt' ? 'updatedAt' : 'status'} columns={columns} data={reportData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading} />
                    </>
            }

        </div >
    )
}

export default Report