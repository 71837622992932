import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import './DataTableDemo.css';
import { useTranslation } from 'react-i18next';

function GameScore() {
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [ScoreData, setScoreData] = useState([]);
    const [PlayerData, setPlayerData] = useState([]);
    const [PlayerScore, setPlayerScore] = useState([]);
    const [TotalScore, setTotalScore] = useState({});
    const [editTable, setEditTable] = useState(true);
    const [Loading, setLoading] = useState(true);
    const [KeySet, setKeySet] = useState(true);
    const [WinningType, setWinningType] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const id = window.location.href.split('general_scoring/')[1];
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/score/' + id;
    const { t } = useTranslation();
    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            extractData(response.data);
            setWinningType(response.data.game.winning_type);
            setLoading(false);
        }
    }

    const extractData = (data) => {
        var score_data = data.score_data;
        score_data = JSON.parse(score_data);
        setScoreData(data);
        setPlayerData(score_data);
        var player_score_listing = [];

        for (let index = 0; index < score_data.roundList.length; index++) {
            var temp_arr = [];
            for (let i = 0; i < score_data.playerdata.length; i++) {
                var player_score = score_data.playerdata[i].scores[index];
                player_score.key = i;
                temp_arr.push(player_score);
            }
            player_score_listing.push(temp_arr);
        }

        var player_score_total = total_arr(score_data.playerdata);
        setTotalScore(player_score_total);
        setPlayerScore(player_score_listing);
    }

    const total_arr = (playerdata) => {
        var player_score_total = [];
        for (let index = 0; index < playerdata.length; index++) {
            var player_data = playerdata[index];

            // Sum of player total score
            var temp_sum = 0;
            for (let index = 0; index < player_data.scores.length; index++) {
                var temp_score_data = player_data.scores[index];
                temp_sum += temp_score_data.score;
            }
            player_score_total.push(temp_sum);
        }
        return player_score_total;
    }

    const datapost = async () => {
        setspinnerIsActive(true);
        var reset = true;
        var score_data = ScoreData.score_data;
        score_data = JSON.parse(score_data);

        var samevalue_status = false;
        var elems = document.querySelectorAll(".not_valid");
        [].forEach.call(elems, function (el) { samevalue_status = true; });
        if (samevalue_status) {
            enqueueSnackbar(t('Player_score_limit_error'), { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        var winner_details = WinnerDetails();

        if (ScoreData._id) {

            var score_data_stringfy = JSON.stringify(PlayerData);
            const response = await Database.update(apiRoute, { 'score_data': score_data_stringfy, winner_name: winner_details.winner_name, winner_score: winner_details.winner_score });
            if (response.status) {
                enqueueSnackbar(t('game_update_success'), { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        setspinnerIsActive(false);
        setEditTable(true);
    }

    const WinnerDetails = () => {
        if (WinningType == 'low') {
            var max_score = Math.min(...TotalScore);
        } else {
            var max_score = Math.max(...TotalScore);
        }
        var winnerName = [];
        for (let index = 0; index < TotalScore.length; index++) {
            const score = TotalScore[index];
            if (max_score == score) {
                winnerName.push(PlayerData.playerList[index].name);
            }
        }
        return { winner_name: winnerName.toString(), winner_score: max_score };
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('history/game');
        setPermission(page_permission);
    }

    const onChangeInput = (e) => {
        var player_data = PlayerData;
        var round = e.target.attributes.dataround.value;
        var key = e.target.attributes.datakey.value;
        var value = e.target.value;

        if (value == '' || value == undefined || value == null) {
            value = 0;
        }

        if (value < 1 || value > 999) {
            enqueueSnackbar(t('Player_score_limit_error'), { variant: 'error' });
        }

        // Make danger score field
        var elems = document.querySelectorAll(".score_fields");
        [].forEach.call(elems, function (el) {
            var score_round = el.attributes.dataround.value;
            var score_key = el.attributes.datakey.value;
            var class_lists = el.className.replace(" not_valid", '');
            if (score_round == round && score_key == key) {
                if (value == 0 || value > 999) {
                    el.className = class_lists + ' not_valid';
                } else {
                    el.className = class_lists;
                }
            }
        });

        player_data.playerdata[key].scores[round].score = parseInt(value);
        var player_score_total = total_arr(player_data.playerdata);
        setTotalScore(player_score_total);
    }

    const KeyDown = (event) => {
        if (!KeySet) {
            event.preventDefault();
        }
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) && event.keyCode != 8) {
            event.preventDefault();
        }
        if (KeySet) {
            setKeySet(false);
        }
    }

    const keyUp = (event) => {
        setKeySet(true);
    }



    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {
                Loading ? <Loader /> :
                    <>
                        <div className='container bg-white pb-8 my-3 p-3'>
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people sm-2`}></i>{t('general_scroing')}</h3>
                                    <br />
                                    <h5 className={`text-dark`}>
                                        {ScoreData.game.name} - ({ScoreData.diwaniya.name})
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='text-center b-white w-25'>{t('Rounds')}</th>
                                                <>
                                                    {PlayerData.playerList.map((player_name) => (
                                                        <>
                                                            <th className='text-center b-white'>{player_name.name}</th>
                                                        </>
                                                    ))}
                                                </>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {

                                                    PlayerScore.map((score, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td className='text-center p-2 roundColor'>
                                                                    <input
                                                                        disabled
                                                                        name="bid"
                                                                        value={index + 1}
                                                                        type="text"
                                                                        placeholder="Round"
                                                                    />
                                                                </td>

                                                                {
                                                                    score.map((data, i) => (
                                                                        <td className={'text-center roundColor'}>
                                                                            <input
                                                                                disabled={editTable ? true : false}
                                                                                className={`w-100 p-2 score_fields`}
                                                                                name={'team_'}
                                                                                value={data.score}
                                                                                type="text"
                                                                                onChange={(e) => onChangeInput(e)}
                                                                                onKeyDown={(e) => (KeyDown(e))}
                                                                                onKeyUp={(e) => (keyUp(e))}
                                                                                placeholder="Score"
                                                                                datakey={data.key}
                                                                                dataround={index}
                                                                                maxlength="3"
                                                                            />
                                                                        </td>
                                                                    ))
                                                                }


                                                            </tr>
                                                        </>
                                                    ))}
                                            </>
                                        </tbody>
                                        <br />
                                        <tfoot>
                                            <tr>
                                                <th className='mt-2 bg-dark text-center b-white'>{t('total')} </th>
                                                {
                                                    TotalScore.map((total, index) => (
                                                        <>
                                                            <th className='mt-2 bg-dark text-center b-white'> {total} </th>
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div >

                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { setEditTable(true) }} data-bs-target="#staticBackdrop" className='btn btn-danger float-end ms-3'>{t('Cancel')}</button>
                            </div> : ''
                        }
                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : datapost(); }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>
                                    {spinnerIsActive ?
                                        <><i className="spinner-border custom-spinner me-2" role="status"></i>
                                        </>
                                        : ""}
                                    {editTable ? t('edit') : t('save')}
                                </button>
                            </div> : ''
                        }
                    </>
            }

        </>

    )
}

export default GameScore