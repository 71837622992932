import React from 'react'
import Skeleton from '@mui/material/Skeleton';

function Loader() {
    return (
        <>
            <Skeleton style={{ height: '90px' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
            <Skeleton animation="wave" style={{ height: '50px', margin: '0', padding: '0' }} />
        </>
    )
}

export default Loader