import React from 'react'
import DataTable from '../../Components/Datatable/WsDatatable'
import PageHeader from '../../Components/PageHeader'

const data = [];
for (let index = 0; index < 10; index++) {
    data.push({ Date: '1' + index + '-Sept-2022', Diwaniya_Name: 'Diwaniya' + index, Game_Type: 'Hand', Score: Math.floor(Math.random() * 990 + 10) });
}

function Player() {
    return (
        <div className='container bg-white pb-8 my-3'>
            <PageHeader title="Player1 Game History" text="" icon="table" />
            <DataTable data={data} columnSize={['', '', 'w-20']} />
        </div>
    )
}

export default Player