import React, { useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatable'
import { faker } from '@faker-js/faker';

// //////////////////////////////////////////////////////////////////////////////////////// temp
const createUser = () => ({
    id: faker.datatype.uuid(),
    username: faker.name.fullName(),
    email: faker.internet.email(),
    message: faker.lorem.paragraph(),
});

const createUsers = (numUsers = 5) => new Array(numUsers).fill(undefined).map(createUser);
const fakeUsers = createUsers(300);
fakeUsers.forEach((element, index) => {
    element.index = index + 1;
});
// //////////////////////////////////////////////////////////////////////////////////////// temp

function Index() {

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Acction',
            selector: row =>
                <>
                    <button className='btn btn-danger btn-sm mx-1'><i className='bi bi-trash'></i></button>
                    <button className='btn btn-dark btn-sm mx-1'><i className='bi bi-eye'></i></button>
                    <button className='btn btn-info btn-sm mx-1'><i className='bi bi-envelope'></i></button>
                </>
        },
    ];

    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i>Support</h3>
                </div>
                <Datatable columns={columns} data={fakeUsers} />
            </div>

            {/* <div className='container bg-white pb-8'>
                <PageHeader title="Support" text="" icon="gear" />
                <DataTable data={data} type="btn" button={['view', 'sendEmai', 'delete']} ButtonTitle={['View Query', 'Reply', 'Remove Query']} onView={viewModa} onEdit={emailReply} onDelete={() => deleteItem()} />
            </div>

            <button type="button" className="btn btn-primary">
                Launch static backdrop modal
            </button> */}
        </>
    )
}

export default Index