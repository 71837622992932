import React, { useEffect, useState } from 'react'
import Database from '../../Database/Index';
import get_permission from '../../Components/Permission';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import { Modal, Checkbox } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import './DataTableDemo.css';

function GameScore() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [spinnerIsActive, setspinnerIsActive] = useState(false);
    const [ScoreData, setScoreData] = useState([]);
    const [PlayerData, setPlayerData] = useState([]);
    // const [PlayerScore, setPlayerScore] = useState([]);
    // const [buttonValidation, setbuttonValidation] = useState([]);
    const [PlayerheaderListing, setPlayerheaderListing] = useState([]);
    const [TrixValues, setTrixValues] = useState([]);
    // const [RoundData, setRoundData] = useState([]);
    const [RoundScoreArr, setRoundScoreArr] = useState([]);
    const [TotalScore, setTotalScore] = useState({});
    const [ScoreKeys, setScoreKeys] = useState({});
    const [RoundTotalScore, setRoundTotalScore] = useState({});
    // const [CardCountData, setCardCountData] = useState({});
    const [editTable, setEditTable] = useState(true);
    const [Loading, setLoading] = useState(true);
    // const [AllCards, setAllCards] = useState(false);
    // const [KeySet, setKeySet] = useState(true);
    // const [SelectedRound, setSelectedRound] = useState(0);
    const [DeclairationListCount, setDeclairationListCount] = useState(0);
    const [SelectedRoundTotal, setSelectedRoundTotal] = useState(0);
    const [WinningType, setWinningType] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const handleClose = () => setShow(false);

    const id = window.location.href.split('trix/')[1];
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/score/' + id;

    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            extractData(response.data);
            setWinningType(response.data.game.winning_type);
            setLoading(false);
        }
    }

    const extractData = (data) => {
        var score_data = data.score_data;
        score_data = JSON.parse(score_data);
        var player_header_listing = score_data.playerList.length ? score_data.playerList : score_data.data[0].teamList;
        setPlayerheaderListing(player_header_listing);
        var trix_data = trix(score_data, player_header_listing);
        setTotalScore(trix_data.total_score_listing);
        setRoundTotalScore(trix_data.round_score_listing)
        setScoreData(data);
        setPlayerData(score_data);
    }

    function trix(data, player_list) {
        var dataArr = { score_listing: [], total_score_listing: [], round_score_listing: [] };
        var round_total_data = [];
        var TotalScore = [];

        for (let index = 0; index < data.data.length; index++) {
            const round = data.data[index];
            var roundTotal = [];
            for (let player_index = 0; player_index < player_list.length; player_index++) {
                var totalScore = 0;
                for (let round_index = 0; round_index < round.scores.length; round_index++) {
                    const user_score = round.scores[round_index][player_index];
                    totalScore += user_score;
                    TotalScore[player_index] = TotalScore[player_index] != undefined ? TotalScore[player_index] + user_score : user_score;

                }
                roundTotal.push(totalScore)
            }
            round_total_data.push(roundTotal);

        }
        dataArr.total_score_listing = TotalScore;
        dataArr.round_score_listing = round_total_data;
        return dataArr;
    }

    const datapost = async () => {
        if (SelectedRoundTotal != 100 && ScoreKeys.contractName == 'Queen') {
            enqueueSnackbar(t('total_count_should_be_100'), { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        if (SelectedRoundTotal == 0 && (ScoreKeys.contractName == 'Diamond' || ScoreKeys.contractName == 'Eats' || ScoreKeys.contractName == 'Trix' || ScoreKeys.contractName == 'K Of Heart')) {
            enqueueSnackbar(t('assign_score_1_player_or_team'), { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        if (ScoreKeys.contractName == 'K Of Heart' && SelectedRoundTotal != 75) {
            enqueueSnackbar(t('total_count_should_be_75'), { variant: 'error' });
            setspinnerIsActive(false);
            return;
        }

        if (ScoreData._id) {
            var winner_details = WinnerDetails();
            var score_data_stringfy = JSON.stringify(PlayerData);
            const response = await Database.update(apiRoute, { 'score_data': score_data_stringfy, winner_name: winner_details.winner_name, winner_score: winner_details.winner_score });
            if (response.status) {
                enqueueSnackbar(t('score_update_success'), { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        handleClose();
        setspinnerIsActive(false);
        setEditTable(true);
    }

    const WinnerDetails = () => {
        if (WinningType == 'low') {
            var max_score = Math.min(...TotalScore);
        } else {
            var max_score = Math.max(...TotalScore);
        }
        var winnerName = [];
        for (let index = 0; index < TotalScore.length; index++) {
            const score = TotalScore[index];
            if (max_score == score) {
                var winner_name = PlayerheaderListing[index].name || PlayerheaderListing[index];
                winnerName.push(winner_name);
            }
        }
        return { winner_name: winnerName.toString(), winner_score: max_score };
    }

    const setPermissoin = async () => {
        var page_permission = await get_permission('history/game');
        setPermission(page_permission);
    }

    const scoreClick = (e) => {
        var attributes = e.target.attributes;
        var contractImage = '/' + attributes.datacontractimage.value;
        var contractName = attributes.datacontract.value;
        var round = attributes.dataround.value;
        var scorekey = attributes.datascorekey.value;
        var scores = PlayerData.data[round].scores;
        if (contractName == 'Trix') {
            scores[scorekey].forEach((element, index) => {
                PlayerData.data[round].scores[scorekey][parseInt(index)] = 0;
            });
        }
        var round_score_total = getTotalOfArray(scores[scorekey]);
        var rscoreArr = scores[scorekey];
        setRoundScoreArr(rscoreArr);
        var left = getleft(contractName, round_score_total);
        setSelectedRoundTotal(round_score_total);
        var declaration_arr = [];
        var declaration_value_arr = [];
        var declairlist_value_arr = [];
        scores[scorekey].forEach((element, index) => {
            var list_value = 0;
            var declaration_value = 0;
            if (element < 0) {
                declaration_arr.push(index);
                declaration_value = element;
                list_value = Math.abs(element);
            }
            declaration_value_arr.push(declaration_value);
            declairlist_value_arr.push(list_value);
        });
        setScoreKeys({ ...ScoreKeys, contractImage: contractImage, contractName: contractName, round: round, scorekey: scorekey, declairation: declaration_arr, left: left, declairlist_value: declairlist_value_arr, declairUserValue: declaration_value_arr });
        setShow(true);
        var val = getTotalOfArray(declairlist_value_arr);
        setDeclairationListCount(val);
        setTrixValues([0, -50, -100, -150, -200]);
    }

    function getleft(contractName, round_score_total) {
        var left = 0;
        if (contractName == 'K Of Heart') {
            left = round_score_total == 75 ? 0 : 1;
        } else if (contractName == 'Queen') {
            left = (100 - (round_score_total < 0 ? 0 : round_score_total)) / 25;
        } else if (contractName == 'Eats') {
            left = (195 - round_score_total) / 15;
        } else if (contractName == 'Diamond') {
            left = (130 - round_score_total) / 10;
        }
        return left;
    }

    const AddScore = (e) => {
        var attributes = e.target.attributes;
        var dataRound = attributes.dataround.value;
        var datakey = attributes.datakey.value;
        var datatype = attributes.datatype.value;
        var buttontype = attributes.buttontype.value;
        var datascorekey = attributes.datascorekey.value;
        var scores = PlayerData.data[dataRound].scores[datascorekey];
        var score = PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)];
        var roundScoreTotal = getTotalOfArray(scores);

        // Calculation for King (K Of Heart)
        if (datatype == 'K Of Heart' && (buttontype == 'plus' || buttontype == "minus")) {
            if (ScoreKeys.declairation.includes(parseInt(datakey)) || (!ScoreKeys.declairation.length && SelectedRoundTotal <= 0) || (!ScoreKeys.declairation.includes(parseInt(datakey)) && SelectedRoundTotal < 0) || ((score == 150 || score == 75) && buttontype == "minus")) {
                if (buttontype == 'plus') {
                    var value = !ScoreKeys.declairation.includes(parseInt(datakey)) && SelectedRoundTotal != 0 ? 150 : (score == -75 ? 0 : 75);
                    if (ScoreKeys.declairation.includes(parseInt(datakey))) {
                        scores.forEach((element, index) => {
                            PlayerData.data[dataRound].scores[datascorekey][parseInt(index)] = 0;
                        });
                    }
                } else {
                    var value = !ScoreKeys.declairation.includes(parseInt(datakey)) ? 0 : (SelectedRoundTotal > 0 ? 0 : (score == 150 ? 0 : -75));
                }
                PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = value;
            }
        }

        // Declair List for Queen
        var declair_total_list_value = getTotalOfArray(ScoreKeys.declairlist_value);
        var current_player_previous_value = parseInt(ScoreKeys.declairlist_value[datakey]);
        var declair_length = ScoreKeys.declairation.length;
        var is_declair = ScoreKeys.declairation.includes(parseInt(datakey));

        if ((datatype == 'Queen' && buttontype == 'declairlist' && (is_declair || !declair_length)) && (declair_total_list_value != 100 || is_declair && declair_total_list_value <= 100)) {
            var declair_value = e.target.value;
            var current_player_value = PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)];

            if ((declair_value != 0 && ((declair_total_list_value - current_player_previous_value) + parseInt(declair_value) > 100 ? ((parseInt(ScoreKeys.declairlist_value[datakey])) < declair_value) : (declair_value + ScoreKeys.declairlist_value[datakey] == 100)))) {
                setState(ScoreKeys.declairlist_value, scores, datatype);
                return;
            }

            ScoreKeys.declairlist_value[datakey] = declair_value;
            ScoreKeys.declairUserValue[datakey] = 0;
            if (declair_value == 0) {

                PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = 0;

                var temp_arr = ScoreKeys.declairation;
                var new_temp_arr = [];
                temp_arr.forEach(element => {
                    if (!is_declair) {
                        new_temp_arr.push(element);
                    }
                });
                ScoreKeys.declairation = new_temp_arr;
            } else {
                if (current_player_value < 0) {
                    PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = (-declair_value) > current_player_value ? (-declair_value) : current_player_value;
                }
            }
        }

        var currentUserValue = PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)];
        // Calculation for Queen
        if (datatype == 'Queen' && (buttontype == 'plus' || buttontype == "minus")) {
            if (is_declair || (!declair_length && SelectedRoundTotal != 100 && buttontype == "plus") || (!declair_length && currentUserValue > 0 && buttontype == "minus") || (!is_declair && declair_length) || ((score == 150 || score == 75) && buttontype == "minus")) {
                var getdvalueTotal = getTotalOfArray(ScoreKeys.declairUserValue);
                var declair_user_value = Math.abs(getdvalueTotal);
                var netative_arr = getNegativeNumbers(ScoreKeys.declairUserValue);
                var max_val = getdvalueTotal >= 0 && !netative_arr.length ? 100 : (getdvalueTotal >= 0 ? 100 : (100 + parseInt(declair_user_value)));
                if (buttontype == 'plus') {
                    var value = roundScoreTotal != 100 ? currentUserValue + 25 : currentUserValue;

                } else {
                    var value = !is_declair ? (currentUserValue > 0 ? currentUserValue - 25 : 0) : (currentUserValue > -(ScoreKeys.declairlist_value[datakey]) ? currentUserValue - 25 : -(ScoreKeys.declairlist_value[datakey]));
                }

                ScoreKeys.declairUserValue[datakey] = is_declair ? value : ScoreKeys.declairUserValue[datakey];
                PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = value;
            }
        }

        // Calculation for Eats
        if (datatype == 'Eats' && (buttontype == 'plus' || buttontype == "minus")) {
            if (true) {
                var value = currentUserValue;
                if (buttontype == 'plus' && SelectedRoundTotal < 195) {
                    value = parseInt(currentUserValue) + 15;
                }
                if (buttontype == 'minus') {
                    value = currentUserValue > 0 ? parseInt(currentUserValue) - 15 : 0;
                }
                PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = value;
            }
        }

        // Calculation for Diamond
        if (datatype == 'Diamond' && (buttontype == 'plus' || buttontype == "minus")) {
            if (true) {
                var value = currentUserValue;
                if (buttontype == 'plus' && SelectedRoundTotal < 130) {
                    value = parseInt(currentUserValue) + 10;
                }
                if (buttontype == 'minus') {
                    value = currentUserValue > 0 ? parseInt(currentUserValue) - 10 : 0;
                }
                PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = value;
            }
        }

        // Calculation for Trix
        if (datatype == 'Trix' && buttontype == 'droplist') {
            var droplist_value = e.target.value;

            if (currentUserValue != 0 && !TrixValues.includes(currentUserValue)) {
                TrixValues.push(currentUserValue);
            }

            var index_of = TrixValues.indexOf(parseInt(droplist_value));
            if (index_of != -1) {
                TrixValues.splice(index_of, 1);
            }

            var temp_arr = [0];
            TrixValues.forEach(tvalue => {
                if (tvalue != 0) {
                    temp_arr.push(tvalue);
                }
            });

            temp_arr.sort(function (a, b) { return a - b });
            temp_arr.reverse();
            setTrixValues(temp_arr);
            PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = parseInt(droplist_value);
        }

        // Declairation for Queen
        var declair_total_list_value_declairation = getTotalOfArray(ScoreKeys.declairlist_value);
        if (((datatype == 'Queen') && buttontype == 'declairation' && ((declair_length < 4 || is_declair) || !declair_length)) && (declair_total_list_value_declairation != 100 || (is_declair)) && SelectedRoundTotal <= 100) {

            if (declair_total_list_value >= 100 && !is_declair) {
                setState(ScoreKeys.declairlist_value, scores, datatype);
                return;
            }
            PlayerData.data[dataRound].scores[datascorekey][parseInt(datakey)] = 0

            if (!is_declair) {
                ScoreKeys.declairation.push(parseInt(datakey));
                ScoreKeys.declairlist_value[datakey] = 25;
            } else {
                var temp_arr = ScoreKeys.declairation;
                var new_temp_arr = [];
                temp_arr.forEach(element => {
                    if (element != datakey) {
                        new_temp_arr.push(element);
                    }
                });
                ScoreKeys.declairation = new_temp_arr;

                var temp_arr = ScoreKeys.declairlist_value;
                var new_temp_arr = [];
                temp_arr.forEach((element, index) => {
                    if (index != datakey) {
                        new_temp_arr.push(element);
                    } else {
                        new_temp_arr.push(0);
                    }
                });
                ScoreKeys.declairlist_value = new_temp_arr;
            }
        }

        // Declairation for King
        if (datatype == 'K Of Heart' && buttontype == 'declairation' && (SelectedRoundTotal == 0 || (!is_declair || is_declair)) && (!declair_length || declair_length && is_declair)) {

            // if (declair_total_list_value >= 100 && !ScoreKeys.declairation.includes(parseInt(datakey))) {
            //     setState(ScoreKeys.declairlist_value, scores, datatype);
            //     return;
            // }
            PlayerheaderListing.forEach((name, index) => {
                PlayerData.data[dataRound].scores[datascorekey][parseInt(index)] = 0
            });

            if (!is_declair && !declair_length) {
                ScoreKeys.declairation.push(parseInt(datakey));
            } else {
                var temp_arr = ScoreKeys.declairation;
                var new_temp_arr = [];
                temp_arr.forEach(element => {
                    if (element != datakey) {
                        new_temp_arr.push(element);
                    }
                });
                ScoreKeys.declairation = new_temp_arr;
            }
        }
        setState(ScoreKeys.declairlist_value, scores, datatype);
    }

    function setState(ScoreKeysData, scores, datatype) {
        var val = getTotalOfArray(ScoreKeysData);
        setDeclairationListCount(val);
        var round_score_total = getTotalOfArray(scores);
        ScoreKeys.left = getleft(datatype, round_score_total);
        setScoreKeys({ ...ScoreKeys, ScoreKeys });
        setSelectedRoundTotal(round_score_total);
    }

    function getTotalOfArray(array) {
        var Total = 0;
        array.forEach(element => {
            Total += parseInt(element);
        });
        return Total;
    }

    function getNegativeNumbers(array) {

        var negatives = [];

        for (var i = 0; i < array.length; i++) {
            if (array[i] < 0) {
                negatives.push(array[i]);
            }
        }
        return negatives;

    }


    function accodian(e) {
        var row = e.target.attributes.datarow.value;
        var elems = document.querySelectorAll(".row_" + row);
        var rowHead = document.querySelectorAll(".roundHead_" + row);
        [].forEach.call(elems, function (el) {
            if (el.classList.contains('d-none')) {
                el.classList.remove("d-none");
                rowHead[0].classList.add("rotate");
            } else {
                el.classList.add("d-none");
                rowHead[0].classList.remove("rotate");
            }
        });
    }

    useEffect(() => {
        setPermissoin();
        callData();
    }, [])

    return (
        <>
            {
                Loading ? <Loader /> :
                    <>
                        <div className='container bg-white pb-8 my-3 p-3'>
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{ScoreData.game.name}</h3>
                                    <br />
                                    <h5 className={`text-dark`}>
                                        {ScoreData.game.name} - ({ScoreData.diwaniya.name})
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <tbody>

                                            {
                                                PlayerData.data.map((score, index) => (
                                                    <>
                                                        <tr>
                                                            <th className={'text-center b-white w-25'} datarow={index}>Round {index + 1}
                                                                <i class={"bi bi-chevron-down cursor-pointer mx-2 roundHead_" + index + (index == 0 ? ' rotate' : '')} datarow={index} onClick={(e) => (accodian(e))}></i>
                                                            </th>
                                                            <>
                                                                {PlayerheaderListing.map((player_name, pkey) => (
                                                                    <>
                                                                        <th className='text-center b-white'>{player_name.name ? player_name.name : player_name}
                                                                        {
                                                                            score.selected_king.indexOf(true) != -1 && score.selected_king.indexOf(true) == pkey ? <img className='w-15p mb-1 ms-2' src={'/assets/icons/king_w_icon.png'} /> : '' 
                                                                        }
                                                                        </th>
                                                                    </>
                                                                ))}
                                                            </>
                                                        </tr>

                                                        {
                                                            score.scores.map((score_data, score_index) => (
                                                                <>
                                                                    <tr className={'row_' + index + (index != 0 ? ' d-none' : '')}>
                                                                        <td className={'text-center p-2 bg_light_gray'}>
                                                                            <img className='w-20' src={'/' + score.contractInfo[score_index].image} alt={score.contractInfo[score_index].name} />
                                                                        </td>
                                                                        {
                                                                            PlayerheaderListing.map((player_name, player_key) => (
                                                                                <>
                                                                                    <td className={'text-center roundColor'}>
                                                                                        <input
                                                                                            disabled={editTable ? true : false}
                                                                                            className={`w-100 p-2 score_fields cursor-pointer`}
                                                                                            name={'team_' + player_key}
                                                                                            value={score_data[player_key]}
                                                                                            type="text"
                                                                                            onClick={(e) => (scoreClick(e))}
                                                                                            placeholder={t('Score')}
                                                                                            datakey={player_key}
                                                                                            dataround={index}
                                                                                            datacontract={score.contractInfo[score_index].name}
                                                                                            datacontractimage={score.contractInfo[score_index].image}
                                                                                            datascorekey={score_index}
                                                                                            readOnly
                                                                                            maxlength="3"
                                                                                        />
                                                                                    </td>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }

                                                        <tr>
                                                            <th className='mt-2 bg_dark_gray text-center b-white'> {t('round_total')} </th>
                                                            {
                                                                RoundTotalScore[index] != undefined ? RoundTotalScore[index].map((total, index) => (
                                                                    <>
                                                                        <th className='mt-2 bg_dark_gray text-center b-white' data={index}> {total} </th>
                                                                    </>
                                                                )) : ''
                                                            }
                                                        </tr>

                                                        <tr>
                                                            <th className='mt-2 bg-white b-white'></th>
                                                        </tr>

                                                    </>
                                                ))
                                            }


                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='mt-2 bg-dark text-center b-white'>{t('grand_total')}</th>
                                                {
                                                    TotalScore.map((total, index) => (
                                                        <>
                                                            <th className='mt-2 bg-dark text-center b-white'> {total} </th>
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div >
                        {
                            permission.edit ? <div className='px-4'>
                                <button data-bs-toggle="modal" onClick={() => { editTable ? setEditTable(false) : setEditTable(true); }} data-bs-target="#staticBackdrop" className={'btn float-end'+ (editTable ? " btn-dark" : " btn-danger")}>{editTable ? "Edit" : " Cancel"}</button>
                            </div> : ''
                        }
                    </>
            }

            <Modal show={show} fullscreen="lg-down">
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleClose(); callData(); }}>
                    <Modal.Title className=''>{t('Score')}
                    </Modal.Title>
                    {
                        ScoreKeys.contractName && ScoreKeys.contractName != 'Trix' ? <div className='w-100 position-absolute pe-6'>
                            <span className='float-end lh-2 p-1'>{ScoreKeys.left ? ScoreKeys.left : 0} {t('Left')}</span>
                            <img className='w-8 bg-white rounded-circle float-end p-1' src={ScoreKeys.contractImage} alt={ScoreKeys.contractName} />
                        </div> : ''
                    }
                </Modal.Header>
                <Modal.Body>
                    <div className="row custom">
                        <div className="col-12 m-auto">
                            <div className="">
                                <div className="score_select_box rounded-bottom">
                                    <div className="row p-2">

                                        <>
                                            {/* K Of Heart Modal */}
                                            {ScoreKeys.round && ScoreKeys.contractName == 'K Of Heart' ? PlayerheaderListing.map((player_name, playerkey) => (
                                                <>
                                                    <div className="col-6 ps-1 pe-1  pb-2">
                                                        <div className="bg-warning rounded-3 pb-2">
                                                            <div className="p-2 text-center">
                                                                <span className="ms-2 fw-6">{player_name.name ? player_name.name : player_name}</span>
                                                            </div>
                                                            <div className="d-flex w-55 m-auto">
                                                                <i className={"bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + ((ScoreKeys.declairation.length && SelectedRoundTotal == 0 && !ScoreKeys.declairation.includes(parseInt(playerkey))) || SelectedRoundTotal == 75 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className="m-auto text-dark fw-6" dataround={ScoreKeys.round} datakey={playerkey} datascorekey={ScoreKeys.scorekey}>{PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]}</span>
                                                                <i className={"bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (!ScoreKeys.declairation.includes(parseInt(playerkey)) && PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey] == 0 || PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey] == -75 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                            <div className="d-flex w-50 m-auto">
                                                                <i className={"bi bi-check  cursor-pointer text-white rounded-circle score_btn_spide m-auto mt-2" + ((ScoreKeys.declairation.length && !ScoreKeys.declairation.includes(parseInt(playerkey)) && (PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey] >= 0)) ? ' disabled' : '') + (ScoreKeys.declairation.includes(parseInt(playerkey)) ? ' bg-danger' : ' bg-dark')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="declairation" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : ''}

                                            {/* Queen Modal */}
                                            {ScoreKeys.round && ScoreKeys.contractName == 'Queen' ? PlayerheaderListing.map((player_name, playerkey) => (
                                                <>
                                                    <div className="col-6 ps-1 pe-1  pb-2">
                                                        <div className="bg-warning rounded-3 pb-2">
                                                            <div className="p-2 text-center">
                                                                <span className="ms-2 fw-6">{player_name.name ? player_name.name : player_name}</span>
                                                            </div>
                                                            <div className="d-flex w-55 m-auto">
                                                                <i className={"bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (SelectedRoundTotal == 100 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className="m-auto text-dark fw-6" dataround={ScoreKeys.round} datakey={playerkey} datascorekey={ScoreKeys.scorekey}>{PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]}</span>
                                                                <i className={"bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (ScoreKeys.declairation && ScoreKeys.declairation.length && (ScoreKeys.declairation.includes(parseInt(playerkey)) && PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][parseInt(playerkey)] == (-ScoreKeys.declairlist_value[playerkey]) && PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][parseInt(playerkey)] < 0 || !ScoreKeys.declairation.includes(parseInt(playerkey)) && PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][parseInt(playerkey)] == 0) || ScoreKeys.declairation && !ScoreKeys.declairation.length && PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][parseInt(playerkey)] == 0 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                            <div className="d-flex w-50 m-auto text-center">
                                                                <div className={'m-auto d-inline-flex' + (!ScoreKeys.declairation.includes(parseInt(playerkey)) ? ' mb-2' : '')}>
                                                                    <i className={"bi bi-check  cursor-pointer text-white rounded-circle score_btn_spide mt-2" + (ScoreKeys.declairation && (!ScoreKeys.declairation.includes(parseInt(playerkey)) && (((ScoreKeys.declairation.length == 4 || SelectedRoundTotal == 100)) || (DeclairationListCount == 100 || SelectedRoundTotal == 100))) ? ' disabled' : '') + (ScoreKeys.declairation.includes(parseInt(playerkey)) || PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][parseInt(playerkey)] < 0 ? ' bg-danger' : ' bg-dark')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="declairation" onClick={(e) => (AddScore(e))}></i>
                                                                    {
                                                                        ScoreKeys.declairation.includes(parseInt(playerkey)) ? <select class="form-control rounded-0 bg-transparent border-0 w-40 pe-0 ps-0 text-center" name="queencount" onChange={(e) => (AddScore(e))} value={ScoreKeys.declairlist_value[playerkey]} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="declairlist">
                                                                            <option value="0">0</option>
                                                                            <option value="25">1</option>
                                                                            <option value="50">2</option>
                                                                            <option value="75">3</option>
                                                                            <option value="100">4</option>
                                                                        </select> : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : ''}

                                            {/* Eats Modal */}
                                            {ScoreKeys.round && ScoreKeys.contractName == 'Eats' ? PlayerheaderListing.map((player_name, playerkey) => (
                                                <>
                                                    <div className="col-6 ps-1 pe-1  pb-2">
                                                        <div className="bg-warning rounded-3 pb-2">
                                                            <div className="p-2 text-center">
                                                                <span className="ms-2 fw-6">{player_name.name ? player_name.name : player_name}</span>
                                                            </div>
                                                            <div className="d-flex w-55 m-auto">
                                                                <i className={"bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (SelectedRoundTotal >= 195 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className="m-auto text-dark fw-6" dataround={ScoreKeys.round} datakey={playerkey} datascorekey={ScoreKeys.scorekey}>{PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]}</span>
                                                                <i className={"bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey] == 0 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : ''}

                                            {/* Trix Modal */}
                                            {ScoreKeys.round && ScoreKeys.contractName == 'Trix' ? PlayerheaderListing.map((player_name, playerkey) => (
                                                <>
                                                    <div className="col-6 ps-1 pe-1  pb-2">
                                                        <div className="bg-warning rounded-3 pb-2">
                                                            <div className="p-2 text-center">
                                                                <span className="ms-2 fw-6">{player_name.name ? player_name.name : player_name}</span>
                                                            </div>
                                                            <div className='position-relative w-55 m-auto'>

                                                                <div className="m-auto text-dark fw-6 text-center" dataround={ScoreKeys.round} datakey={playerkey} datascorekey={ScoreKeys.scorekey}>{PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]}</div>
                                                                <i className='bi bi-chevron-down position-absolute bottom-0 text-center start-0 end-0 mb-004'></i>
                                                                <select className={"form-control bg-transparent border-0 text-center m-auto text-dark fw-6 select_chevron_down position-relative pb-3 cursor-pointer h-0"} name="queencount" onChange={(e) => (AddScore(e))} value={PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="droplist" test={RoundScoreArr[playerkey]} disabled={(TrixValues.length && TrixValues.length > 1 ? false : true)}>
                                                                    {
                                                                        TrixValues.length ? TrixValues.map((trixvalue) => (
                                                                            <option value={trixvalue}>{trixvalue == 0 ? 'Select Score' : trixvalue}</option>
                                                                        )) : ''
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : ''}

                                            {/* Diamond Modal */}
                                            {ScoreKeys.round && ScoreKeys.contractName == 'Diamond' ? PlayerheaderListing.map((player_name, playerkey) => (
                                                <>
                                                    <div className="col-6 ps-1 pe-1  pb-2">
                                                        <div className="bg-warning rounded-3 pb-2">
                                                            <div className="p-2 text-center">
                                                                <span className="ms-2 fw-6">{player_name.name ? player_name.name : player_name}</span>
                                                            </div>
                                                            <div className="d-flex w-55 m-auto">
                                                                <i className={"bi bi-plus float-start cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (SelectedRoundTotal >= 130 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="plus" onClick={(e) => (AddScore(e))}></i>
                                                                <span className="m-auto text-dark fw-6" dataround={ScoreKeys.round} datakey={playerkey} datascorekey={ScoreKeys.scorekey}>{PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey]}</span>
                                                                <i className={"bi bi-dash float-end cursor-pointer bg-dark text-white rounded-circle score_btn_spide" + (PlayerData.data[ScoreKeys.round].scores[ScoreKeys.scorekey][playerkey] == 0 ? ' disabled' : '')} dataround={ScoreKeys.round} datakey={playerkey} datatype={ScoreKeys.contractName} datascorekey={ScoreKeys.scorekey} buttontype="minus" onClick={(e) => (AddScore(e))}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : ''}
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer border-top">
                        <button onClick={() => { handleClose(); callData(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                        <button type="button" onClick={() => { setspinnerIsActive(true); datapost(); }} className="btn btn-danger rounded-0 px-4">
                            {spinnerIsActive ?
                                <><i className="spinner-border custom-spinner" role="status"></i>
                                </>
                                : ""} {t('save')}</button>
                    </div>

                </Modal.Body>
            </Modal>

        </>

    )
}

export default GameScore