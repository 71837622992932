import React, { useEffect, useState } from 'react'
import DataTable from '../../../Components/Datatable/WsDatatable'
import get_permission from '../../../Components/Permission';
import { Link } from 'react-router-dom';
import Database from '../../../Database/Index'
import 'react-select-search/style.css'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import Loader from '../../../Components/Loader';

function Index() {
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(true);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [teamData, setTeamData] = useState([]);
    // const [diwaniyaData, setDiwaniyaData] = useState([]);
    const [team, setTeam] = useState({ name: '', diwaniya_id: '', id: '' });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation()
    const [permission, setPermission] = useState({ add: false, edit: false, delete: false });
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;

    // Database Columns
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true
        },

        {
            name: t('players'),
            selector: row => row.players.length,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    {
                        permission.delete ? <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteTeam(row._id)}><i className='bi bi-trash'></i></button> : ''
                    }

                    {
                        permission.edit ? <button className='btn btn-warning btn-sm mx-1' onClick={() => { setTeam({ name: row.name, id: row._id }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button> : ''
                    }

                    <Link to={`/admin/diwaniya/teams/players/${row._id}`} className='btn btn-primary btn-sm mx-1'><i className='bi bi-eye'></i></Link>

                    {
                        permission.add ? <Link to={`/admin/diwaniya/teams/add/${row._id}&-_${row.diwaniya_id}`} className='btn btn-dark btn-sm mx-1'><i className='bi bi-person'></i></Link> : ''
                    }
                </>
        },
    ];
    // Database Data

    const fetchTeamData = async () => {
        const id = window.location.href.split('/teams/')[1];
        const response = await Database.get(apiRoute + '/diwaniya/', id);
        if (response.status) {
            setTeamData(response.data.teams);
            setLoading(false);
        }
    }

    // const fetchDiwaniyaData = async () => {
    //     const response = await Database.get('/diwaniya');
    //     if (response.status) {
    //         setDiwaniyaData(response.data);
    //         var searchData = [];
    //         response.data.forEach((element, index) => {
    //             searchData.push({ label: element.name, id: element._id });
    //         })
    //         setSearchOptions(searchData);
    //     }
    // }

    const setPermissoin = async () => {
        var page_permission = await get_permission('diwaniya');
        setPermission(page_permission);
    }


    useEffect(() => {
        setPermissoin();
        fetchTeamData();
    }, []);


    const inputHandle = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setTeam({ ...team, [name]: value });
    }

    const datapost = async (e) => {
        e.preventDefault();
        const name = team.name;
        const diwaniya_id = window.location.href.split('/teams/')[1];
        if (name.length < 5) {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }
        if (team.id == '') {
            const data = { name: name, diwaniya_id: diwaniya_id };
            const response = await Database.add(apiRoute + '/team', data);
            if (response.status) {
                fetchTeamData();
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            }
        }
        else {
            const data = { name: name };
            const response = await Database.update(apiRoute + '/team/' + team.id, data);
            if (response.status) {
                fetchTeamData();
                enqueueSnackbar(t('update_success'), { variant: 'success' });
                handleClose();
            }
        }


    }

    // const dianiyaSelect = (e) => {
    //     setSearchDiwaniya(e);
    // }

    const deleteTeam = async (_id) => {
        const response = await Database.delete(apiRoute + '/team/', _id);
        if (response.status) {
            fetchTeamData();
            enqueueSnackbar(t('delete_success'), { variant: 'success' });
        }
    }
    return (
        <>
            <div className='container bg-white pb-8 my-3 p-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('teams')}</h3>
                    {/* {
                        permission.add ? <div className='px-4'>
                            <button data-bs-toggle="modal" onClick={() => { setTeam({ name: '', id: '' }); handleShow(); }} data-bs-target="#staticBackdrop" className='btn btn-dark float-end'>{t('add')}</button>
                        </div> : ''
                    } */}
                </div>
                {
                    Loading ? <Loader /> : <DataTable columns={columns} data={teamData} filterBy="name" />
                }

            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>{t('edit')} {t('team')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={datapost}>
                        <div className='form-group'>
                            <label>{t('team')} {t('name')}</label>
                            <input type="text" name="name" id='diwaniyaName' className='form-control mt-1 border-0 rounded-0 border-bottom' onChange={inputHandle} value={team.name} />
                            <span className='invalid-feedback'>Please Enter Team Name</span>
                        </div>
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
            {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add Team</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={datapost}>
                                <div className='form-group'>
                                    <label>Team Name</label>
                                    <input type="text" name="name" id='diwaniyaName' className='form-control mt-1 border-0 rounded-0 border-bottom' placeholder='Team Name' onChange={inputHandle} value={team.name} />
                                    <span className='invalid-feedback'>Please Enter Team Name</span>
                                </div> */}
            {/* <div className='form-group pt-3'>
                                    <label className='mb-2'>Select Diwaniya</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={searchOptions}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Diwaniya" />}
                                        onChange={(e, value) => dianiyaSelect(value)}
                                        value={searchDiwaniya}
                                    />
                                </div> */}
            {/* <div className="modal-footer mt-3 border-0">
                                    <button type='reset' className='btn btn-danger px-5 py-2 rounded-1 my-3 mx-1'>Reset</button>
                                    <button type='submit' className='btn btn-dark px-5 py-2 rounded-1 my-3 mx-1' data-bs-dismiss="modal">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default Index