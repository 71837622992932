import React, { useEffect, useState, useRef } from 'react'
import Datatable from '../../Components/Datatable/WsDatatableAjax';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';

function Index() {

    const [show, setShow] = useState(false);
    const [Check, setCheck] = useState(false);
    const [password, setPassword] = useState("");

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { t } = useTranslation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userData, setUserData] = useState([]);
    // const [apiRoute, setapiRoute] = useState('https://75vc10bpe4.execute-api.us-east-1.amazonaws.com/player');
    const [userValue, setUserValue] = useState({ name: '', password: '', phonenumber: '', email: '', _id: '' });

    const [viewPassword, setviewPassword] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [RoundLoading, setRoundLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setpage] = useState(1);
    const [search, setSearch] = useState('');
    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/subadmin';
    // get User data
    const fetchUserData = async (perPage, page, filtersearch) => {
        setRoundLoading(true);
        const response = await Database.get(apiRoute + '?page=' + page + '&per_page=' + perPage + '&search=' + filtersearch);
        if (response.status) {
            setRoundLoading(false);
            setUserData(response.data);
            setTotalRows(response.total_records);
            setLoading(false);
        }
    }

    // Datatable per page row change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setpage(page);
        fetchUserData(newPerPage, page, search);
    };

    // Datatable Page Change
    const handlePageChange = page => {
        setpage(page)
        fetchUserData(perPage, page, search);
    };

    // Datatable search
    const onSearch = (val) => {
        setpage(1);
        setSearch(val);
        fetchUserData(perPage, 1, val);
    }

    // delete
    const deleteUser = async (_id) => {
        console.log(_id);

        enqueueSnackbar(t('delete_comfirmation'), {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(_id); closeSnackbar(key); }}>
                        {t('delete')}
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        {t('dismiss')}
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (_id) => {
        const response = await Database.delete(apiRoute + '/', _id);
        if (response.status) {
            enqueueSnackbar(t('delete_success'), { variant: 'success' });
            fetchUserData(perPage, page, search);
        }
    }

    useEffect(() => {
        fetchUserData(perPage, page, search);
    }, [])

    const inputHandle = async (e) => {

        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }
    const PutPassword = useRef("");
    const datapost = async (e) => {
        e.preventDefault();
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userValue.name.length < 5 || userValue.name.trim() == "") {
            return enqueueSnackbar(t('name_must_be_five'), { variant: 'error' });
        }

        if (!userValue.email.match(validRegex)) {
            return enqueueSnackbar(t('invalid_email'), { variant: 'error' });
        }
        if (userValue.phonenumber.length < 8) {
            return enqueueSnackbar(t('invalid_phone'), { variant: 'error' });
        }
        // if (userValue.password.trim() == "" || userValue.password.length < 6) {
        //     return enqueueSnackbar(t('pass_must_be_five'), { variant: 'error' });
        // }
        if (userValue._id) {
            var data = { _id: userValue._id, name: userValue.name.replace(/\s+/g, ""), email: userValue.email, password: userValue.password };
            const response = await Database.update(apiRoute + '/' + userValue._id, data);
            if (response.status) {
                fetchUserData(perPage, page, search);
                enqueueSnackbar(t('update_success'), { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        else {
            var data = { _id: userValue._id, name: userValue.name.replace(/\s+/g, ""), email: userValue.email, password: userValue.password, phonenumber: userValue.phonenumber };
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                fetchUserData(perPage, page, search);
                enqueueSnackbar(t('add_success'), { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }

    }

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////

    // columns

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true
        },
        {
            name: t('email'),
            selector: row => row.email,
            sortable: true
        },
        {
            name: t('phone number'),
            selector: row => row.phonenumber,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteUser(row._id)}><i className='bi bi-trash'></i></button>
                    <button className='btn btn-warning btn-sm mx-1' onClick={() => { setUserValue({ name: row.name, email: row.email, phonenumber: row.phonenumber, _id: row._id }); handleShow(); setCheck(false); setPassword(""); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <i className='bi bi-pencil'></i>
                    </button>
                    <Link to={`/admin/subadmin/permission/${row._id}`} className='btn btn-dark btn-sm mx-1'><i className='bi bi-tools'></i></Link>

                </>
        },
    ];

    const generatePassword = () => {
        // Create a random password
        const randomPassword =
            Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);

        // Set the generated password as state
        setPassword(randomPassword);
        PutPassword.current.value = randomPassword;
        setUserValue({ ...userValue, password: randomPassword });

        // Copy the password to the clipboard
        navigator.clipboard.writeText(randomPassword);
    };
    // console.log(Check);

    // const handleChange = event => {
    //     if (event.target.value == "")
    //         setPassword(event.target.value);
    // };

    const MaxPlayerKeyDown = (event) => {
        var reg = new RegExp('^[0-9]*$');
        if (!reg.test(event.key) & event.keyCode != 8) {
            event.preventDefault();
        }
    }
    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people ms-2`}></i>{t('subadmin')}</h3>
                    <div className='px-4'>
                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setUserValue({ name: '', email: '', phonenumber: '', password: '' }); handleShow(); setCheck(true); }} className='btn btn-dark float-start'>{t('add')}</button>
                    </div>
                </div>

                {
                    Loading ? <Loader /> :
                        <>
                            {/* {RoundLoading ? <RoundLoader /> : ''} */}
                            <Datatable columns={columns} data={userData} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSearch={onSearch} RoundLoading={RoundLoading}/>
                        </>
                }

            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); setPassword(""); }}>
                    <Modal.Title>{userValue._id == null ? t('add') : t('edit')}  {t('subadmin')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>
                        <div className='form-group'>
                            <input type="text" name="name" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('name')} onChange={inputHandle} value={userValue.name} />
                            <span className='invalid-feedback'>Please Enter Name Name</span>
                        </div>
                        <div className='form-group'>
                            <input type="email" name="email" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('email')} onChange={inputHandle} value={userValue.email} />
                            <span className='invalid-feedback'>Please Enter Email </span>
                        </div>
                        <div className='form-group'>
                            <input type="text" size="8" minlength="8" maxlength="8" name="phonenumber" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('phone number')} onKeyDown={(e) => (MaxPlayerKeyDown(e))} onChange={inputHandle} value={userValue.phonenumber} />
                            <span className='invalid-feedback'>Please Enter Phone Number</span>
                        </div>
                        {userValue._id != null ? <>
                            <label className='mt-4'></label>
                            <div class="form-check">
                                <input class="form-check-input" onChange={(e) => { setCheck(e.target.checked); }} type="checkbox" name="type" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    {t('do_you_want_to_change_pass')}
                                </label>
                            </div></> : ""}

                        {Check ?
                            <>
                                <div className='form-group position-relative'>
                                    <input type={viewPassword ? "text" : "password"} name="password" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder={t('password')} ref={PutPassword} value={userValue.password} onChange={inputHandle} />
                                    <span onClick={(e) => { viewPassword ? setviewPassword(false) : setviewPassword(true); }} className='bi bi-eye position-absolute top-0 end-0'></span>
                                </div>
                                <button type='button' className="generate-password btn btn-dark rounded-0" onClick={generatePassword}>
                                    {t('generate_password')}
                                </button>
                            </>
                            : ""}

                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default Index
