import React, { useRef, useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import Datatable from '../../Components/Datatable/WsDatatable';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next'
import Loader from '../../Components/Loader';
const axios = require("axios");

function Index() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()
    // const [apiRoute, setapiRoute] = useState('https://75vc10bpe4.execute-api.us-east-1.amazonaws.com/game');
    const [diwaniya, setDiwaniya] = useState({ name: '', score: '', round: '', description: '', id: '' });
    const [Image, setImage] = useState([]);
    const [htmlContent, sethtmlContent] = useState("");
    const [Loading, setLoading] = useState(true);
    const [formdata, setFormData] = useState([]);
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/upload';

    // for get data
    const callData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            // console.log(response.data);
            setImage(response.data);
            console.log(response.data);
            setLoading(false);
        }
    }
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);



    // Upload Image
    const [uploadImageLoader, setUploadImageLoader] = useState(false);
    const uploadFile = (event) => {
        const formdata = new FormData();
        console.log(event.target.files[0]);
        setUploadImageLoader(true);
        formdata.append("image", event.target.files[0]);
        formdata.append("image_name", diwaniya.name);
        setFormData(formdata);
    };

    useEffect(() => {
        callData();
    }, [])

    const inputHandle = (e) => {
        // console.log(e);
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setDiwaniya({ ...diwaniya, [name]: value });
    }

    const datapost = async (e) => {
        e.preventDefault();
        // setUploadImageLoader(true);
        if (diwaniya.name.length < 5 || diwaniya.name.trim() == "") {
            return enqueueSnackbar("Image Name Must be more than 5 character", { variant: 'error' });
        }
        // const data = formdata;
        // if (diwaniya.id) {
        //     console.log("update Chalaa");
        //     const response = await Database.update(apiRoute + '/' + diwaniya.id, data);
        //     if (response.status) {
        //         callData();
        //         enqueueSnackbar('Image Updated Successfully.', { variant: 'success' });
        //         handleClose();
        //     } else {
        //         enqueueSnackbar(response.message, { variant: 'error' });
        //     }
        // }
        // else {
        //     console.log("Add Chalaa");

        //     const response = await Database.add(apiRoute + '/', data);
        //     if (response.status) {
        //         callData();
        //         enqueueSnackbar('Image Add Successfully.', { variant: 'success' });
        //         handleClose();
        //     }
        //     else {
        //         enqueueSnackbar(response.message, { variant: 'error' });
        //     }
        // }


        axios
            .post(apiRoute,
                formdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((res) => {
                if (res.data.length != 0) {
                    callData();
                    enqueueSnackbar('Image Add Successfully.', { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar("Image name Already Exist !", { variant: 'error' });
                }
            })

    }


    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

    /**
     * Copies the text passed as param to the system clipboard
     * Check if using HTTPS and navigator.clipboard is available
     * Then uses standard clipboard API, otherwise uses fallback
    */
    const copyToClipboard = (content) => {
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(content);
      } else {
        unsecuredCopyToClipboard(content);
      }
    };
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('title'),
            selector: row => row.name,
            sortable: true
        },
        {
            name: t('Image'),
            selector: row => <><img src={row.url} alt="" width={150} className='p-2' /></>,
            sortable: true
        },

        {
            name: t('action'),
            selector: row =>
                <>
                    <button onClick={() => { copyToClipboard(row.url); enqueueSnackbar("Image Url Copied !!", { variant: 'success' }); }} className='btn btn-danger btn-sm mx-1'><i className='bi bi-files'></i></button>

                    {/* <button className='btn btn-warning btn-sm mx-1' onClick={() => { setDiwaniya({ name: row.title, description: row.description, id: row._id }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button> */}
                </>
        },
    ];



    return (
        <>
            <div className='container bg-white p-3 mt-3'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <img src="/assets/images/Diwaniya.png" alt='' className='w-40 bg-warning p-1 mx-3' />{t('image')}</h3>
                    <div className='px-3'>
                        <button data-bs-toggle="modal" onClick={() => { setDiwaniya({ name: '', description: '' }); handleShow(); sethtmlContent(""); }} data-bs-target="#staticBackdrop" className='btn btn-dark'>{t('add')}</button>
                    </div>
                </div>
                {Loading ? <Loader /> : <Datatable filterBy="name" columns={columns} data={Image} />}
            </div>
            {/* modal */}
            <Modal show={show} >
                <Modal.Header className='bg-danger text-light' closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title className='ps-4'>Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form p-4' method='post' id='diwniyaForm' onSubmit={datapost} encType="multipart/form-data" >
                        <div className='form-group'>
                            <label className='mt-4'>Image Title</label>
                            <input type="text" name="name" id='diwaniyaName' className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder={t('name')} onChange={inputHandle} value={diwaniya.name} />
                            <span className='invalid-feedback'>Please Enter Image Title</span>
                        </div>


                        <div className='form-group mt-2'>
                            <label className='mt-4'>Image Content</label>
                            <input type="file" name="image" accept=".jpg,.jpeg,.png" className='form-control mt-3 border-0 rounded-0 border-bottom' onChange={(e) => uploadFile(e)} />

                        </div>




                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Index