import React, { useEffect, useState } from 'react'
import Datatable from '../../Components/Datatable/WsDatatable';
import Database from '../../Database/Index';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next'
import { Modal } from "react-bootstrap";
import Loader from '../../Components/Loader';
function Index() {

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const { t } = useTranslation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userData, setUserData] = useState([]);
    // const [apiRoute, setapiRoute] = useState('https://75vc10bpe4.execute-api.us-east-1.amazonaws.com/player');
    const [userValue, setUserValue] = useState({ username: '', address: '', phonenumber: '', email: '', id: '' });
    const [Loading, setLoading] = useState(true);
    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE + '/player';
    // get User data
    const fetchUserData = async () => {
        const response = await Database.get(apiRoute);
        if (response.status) {
            setUserData(response.data);
            setLoading(false);
        }

    }

    // delete
    const deleteUser = async (id) => {

        enqueueSnackbar('Are you sure to delete Player', {
            variant: 'warning',
            action: (key) => (
                <>
                    <button className='btn btn-sm btn-danger mx-1' onClick={() => { deleteIt(id); closeSnackbar(key); }}>
                        Delete
                    </button>
                    <button className='btn btn-sm btn-dark mx-1' onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </button>
                </>
            )
        });
    }

    const deleteIt = async (id) => {
        const response = await Database.delete(apiRoute + '/', id);
        if (response.status) {
            enqueueSnackbar('User Delete Successfully.', { variant: 'success' });
            fetchUserData();
        }
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    const inputHandle = async (e) => {

        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }

    const datapost = async (e) => {
        e.preventDefault();
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userValue.username.length < 5 || userValue.username.trim() == "") {
            return enqueueSnackbar("Username Must be more than 5 character", { variant: 'error' });
        }
        if (!userValue.email.match(validRegex)) {
            return enqueueSnackbar("Email is Invalid", { variant: 'error' });
        }
        if (userValue.phonenumber.length < 8) {
            return enqueueSnackbar("Invalid Mobile Number", { variant: 'error' });
        }
        if (userValue.id) {
            var data = { id: userValue.id, username: userValue.username.replace(/\s+/g, ""), email: userValue.email, address: userValue.address };
            const response = await Database.update(apiRoute + '/' + userValue.id, data);
            if (response.status) {
                fetchUserData();
                enqueueSnackbar('Player Updated Successfully.', { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        else {
            var data = { id: userValue.id, username: userValue.username.replace(/\s+/g, ""), email: userValue.email, address: userValue.address, phonenumber: userValue.phonenumber };
            const response = await Database.add(apiRoute + '/', data);
            if (response.status) {
                fetchUserData();
                enqueueSnackbar('Player Add Successfully.', { variant: 'success' });
                handleClose();
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }

    }

    //  ////////////////////////////  User Add, Update and Delete ///////////////////////////////////////////////

    // columns

    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true
        },
        {
            name: t('username'),
            selector: row => row.username,
            sortable: true
        },
        {
            name: t('email'),
            selector: row => row.email,
            sortable: true
        },
        {
            name: t('phone number'),
            selector: row => row.phonenumber,
            sortable: true
        },
        {
            name: t('action'),
            selector: row =>
                <>
                    <button className='btn btn-danger btn-sm mx-1' onClick={() => deleteUser(row.id)}><i className='bi bi-trash'></i></button>
                    <button className='btn btn-warning btn-sm mx-1' onClick={() => { setUserValue({ username: row.username, email: row.email, phonenumber: row.phonenumber, address: row.address, id: row.id }); handleShow(); }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <i className='bi bi-pencil'></i>
                    </button>
                    {/* <button className='btn btn-warFning btn-sm mx-1' onClick={() => setUserValue({ username: row.username, email: row.email, phonenumber: row.phonenumber, address: row.address })} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className='bi bi-pencil'></i></button> */}
                </>
        },
    ];

    return (
        <>
            <div className='container bg-white mt-3 p-4 position-relative'>
                <div className='header py-1 pb-2 position-relative d-flex'>
                    <h3 className={`text-dark`}> <i className={`pe-2 bi bi-people`}></i>Players</h3>
                    <div className='px-4'>
                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setUserValue({ username: '', email: '', phonenumber: '', address: '' }); handleShow(); }} className='btn btn-dark float-start'>Add </button>
                    </div>
                </div>

                {
                    Loading ? <Loader /> : <Datatable columns={columns} data={userData} />
                }

            </div>
            <Modal show={show}>
                <Modal.Header closeButton onClick={() => { handleClose(); }}>
                    <Modal.Title>Add Player</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className='form' method='post' onSubmit={datapost}>
                        <div className='form-group'>
                            <input type="text" name="username" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Username' onChange={inputHandle} value={userValue.username} />
                            <span className='invalid-feedback'>Please Enter Username Name</span>
                        </div>
                        <div className='form-group'>
                            <input type="email" name="email" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Email' onChange={inputHandle} value={userValue.email} />
                            <span className='invalid-feedback'>Please Enter Email </span>
                        </div>
                        <div className='form-group'>
                            <input type="text" size="10" minlength="8" maxlength="10" name="phonenumber" className='form-control mt-3 border-0 rounded-0 border-bottom' placeholder='Phone number' onChange={inputHandle} value={userValue.phonenumber} />
                            <span className='invalid-feedback'>Please Enter Phone Number</span>
                        </div>
                        <div className='form-group'>
                            <input type="text" name="address" className='form-control mb-3 mt-3 border-0 rounded-0 border-bottom' placeholder='Address' onChange={inputHandle} value={userValue.address} />
                            <span className='invalid-feedback'>Please Enter Your address</span>
                        </div>
                        <div className="modal-footer border-0">
                            <button onClick={() => { handleClose(); }} type="button" className="btn btn-secondary rounded-0 px-4" data-bs-dismiss="modal">{t('close')}</button>
                            <button type="submit" data-bs-dismiss="modal1" className="btn btn-danger rounded-0 px-4">{t('save')}</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default Index
