import React from 'react'
import DataTable from 'react-data-table-component';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Loader from '../../Components/Loader';
import RoundLoader from '../../Components/RoundLoader';
import { useTranslation } from 'react-i18next'

function WsDatatable({ data, columns, onSelect = () => { }, activePagination = true, isCheckbox = false, filterBy = 'username', paginationPerPage = 10 , paginationTotalRows = 0, onChangeRowsPerPage = () => { }, onChangePage = () => { }, onSearch = () => { }, DatatableClass = 'withoutselectDatatable', CustomFilter = '', RoundLoading = false, isfullheight = false, loadertop = '' }) {

    const sortIcon = <ArrowDownwardIcon />;

    // for filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const { t } = useTranslation()


    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                onSearch('');
            }
        };

        return (

            <FilterComponent onFilter={e => { onSearch(e.target.value); setFilterText(e.target.value); }} onClear={handleClear} filterText={filterText} customFilter={CustomFilter} placeholder={t('search')} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
        <div className='table-upper position-relative'>
        {RoundLoading ? <RoundLoader fullheight={isfullheight} t={loadertop} /> : ''}
            <DataTable
                className={DatatableClass}
                columns={columns}
                data={data}
                sortIcon={sortIcon}
                pagination={activePagination}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                paginationServer
                paginationTotalRows={paginationTotalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                subHeaderComponent={subHeaderComponentMemo}
                subHeader
                persistTableHead
                paginationPerPage={paginationPerPage}
                selectableRows={isCheckbox}
                onSelectedRowsChange={(e) => onSelect(e)}
            />
            </div>
        </>
    )
}

const FilterComponent = ({ filterText, onFilter, onClear, customFilter, placeholder }) => (
    <>
        { <> {customFilter} </>}
        <input
            id="search"
            type="text"
            placeholder={placeholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            className="form-control w-25 rounded-0"
        />
        <button className='btn btn-dark rounded-0' type="button" onClick={onClear}>
            X
        </button>
    </>
);

export default WsDatatable