import React from "react";

function Card({ className, term, value, badge, cardColor = "bg-success", back_image = '' }) {
  return (
    <div className={className}>
      <div className={`card ${cardColor} p-3 mb-2`} style={{ backgroundImage:`url(${back_image})` }}>
        <div className="d-flex justify-content-between">
          <div className="w-100">
            <div className="icon">
              <i className="bx bxl-mailchimp"></i>
            </div>
            <div className="ms-2 d-block w-100 lh-1">
              <h5 className="mb-0 fsp-15 text-white float-start p-2">{term}</h5>
              <span className="fsp-25 p-1 text-white float-end">{value}</span>
              {badge}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
